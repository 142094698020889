import React from "react";
import ContentLoader from "react-content-loader";

const DescriptionLoader = () => {
    return (
        <div className="single-section description">
            <div style={{marginTop: '20px'}}>
                <ContentLoader
                    speed={2}
                    width='100%'
                    height={59}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#cfcfcf">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="13"/>
                    <rect x="0" y="23" rx="5" ry="5" width="100%" height="13"/>
                    <rect x="0" y="46" rx="5" ry="5" width="100%" height="13"/>
                </ContentLoader>
            </div>
        </div>
    )
}
export default DescriptionLoader;
