import React, {useEffect, useState} from "react";
import Header from "../header/main";
import Search from "./search";
import Lasts from "./lasts";
import Footer from "../footer/main";
import {request} from "../../functions/global";
import {Helmet} from "react-helmet-async";
import {useLoadingContext} from "react-router-loading";
import Offers from "./offers";
import Translator from "./translator";
import Free from "./free";
import Teachers from "./teachers";
import Top from "./top";

const Home = () => {
    const loadingContext = useLoadingContext();
    let [data, setData] = useState({
        lasts: [],
        discounts: [],
        offers: [],
        articles: [],
        banners: [],
        tops: [],
        top_teachers: [],
        taxes: {},
    })
    useEffect(() => {
        request('post/home/', 'POST', {}, null).then(response => {
            setData({
                lasts: response.last_courses,
                discounts: response.discounts,
                offers: response.offer,
                articles: response.articles,
                taxes: response.taxes,
                tops: response.tops,
                top_teachers: response.top_teachers,
                banners: response.banners
            });
        })
    }, []);

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - بزرگترین مرجع ویدیوهای آموزشی</title>
                <meta name="description" content="آموزش تیوب مرجع برنامه نویسی دوره های روز دنیا با زیرنویس و دوبله فارسی"/>
                <meta name="keywords" content="آموزش طراحی سایت,آموزش برنامه نویسی,طراحی وب,ساخت وب سایت,آموزش git,آموزش لاراول,آموزش php,آموزش react,آموزش پی اچ پی,آموزش laravel,آموزش جاوا اسکریپت,آموزش ساخت وب سایت,آموزش بوت استرپ,آموزش mvc,آموزش React Native,آموزش تیوب"/>
                <link rel="canonical" href="https://amoozesh.tube"/>
                <meta property="og:title" content="آموزش برنامه نویسی - آموزش تیوب"/>
                <meta property="og:description" content="آموزش تیوب مرجع برنامه نویسی دوره های روز دنیا با زیرنویس و دوبله فارسی"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://amoozesh.tube"/>
                <meta property="og:site_name" content="وبسایت آموزشی آموزش تیوب"/>
                <meta property="og:brand" content="آموزش تیوب"/>
                <meta property="og:locale" content="fa"/>
                <meta property="og:image" content="https://amoozesh.tube/images/logo-.png"/>
            </Helmet>
            <div className='main-head'>
                <Header main={true}/>
                <Search data={data.taxes}/>
            </div>
            <div className="content">
                <Lasts data={data.lasts}/>
                <Offers data={data.offers}/>
                <div className='row-t'>
                    <Teachers data={data.top_teachers}/>
                    <Top data={data.tops}/>
                </div>
                <Free data={data.articles}/>
                <Footer/>
            </div>
        </>
    )
}
export default Home;
