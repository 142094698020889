import React, {useContext, useEffect, useState} from "react";
import {useLoadingContext} from "react-router-loading";
import {request} from "../../functions/global";
import {globalContext} from "../../App";
import {useParams} from 'react-router-dom'
import {Helmet} from "react-helmet";
import Header from "../header/main";
import Sidebar from "./sidebar";
import Footer from "../footer/main";

const Notification = () => {
    const loadingContext = useLoadingContext();
    const {global, setGlobal} = useContext(globalContext);
    const {id} = useParams();
    const [data, setData] = useState({});
    useEffect(() => {
        request('user/notification/get/', 'POST', {id}, global.jwt).then(response => {
            loadingContext.done();
            setData(response);
            setGlobal({...global, notifications: global.notifications ? global.notifications - response.count : global.notifications})
        })
    }, []);

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - اطلاعیه</title>
            </Helmet>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='notifications'/>
                    <div className="content-wrapper send-ticket">
                        {'title' in data ?
                            <>
                                <h2>{data.title}</h2>
                                <div className='profile-content-wrapper'>
                                    <p style={{margin: '0', fontSize: '13px'}}>{data.content}</p>
                                </div>
                            </>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Notification;
