import React from "react";
import ContentLoader from "react-content-loader";


const ShareLoader = () => {

    return (
        <>
            <div />
            <div>
                <ul>
                    <li>
                        <ContentLoader
                            speed={2}
                            width='70%'
                            height={25}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="25"/>
                        </ContentLoader>
                    </li>
                    <li className='like-share'>
                        <ContentLoader
                            speed={2}
                            width='70%'
                            height={25}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="25"/>
                        </ContentLoader>
                    </li>
                    <li>
                        <ContentLoader
                            speed={2}
                            width='70%'
                            height={25}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="25"/>
                        </ContentLoader>
                    </li>
                </ul>
            </div>
        </>

    )
}
export default ShareLoader;
