import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fa';
import {alert, permission, request} from "../../functions/global";
import {globalContext} from "../../App";
import {useNavigate} from 'react-router-dom'
import {useLoadingContext} from "react-router-loading";
import {Helmet} from "react-helmet";
import {debounce} from 'lodash';

const SendTickets = () => {
    const {global} = useContext(globalContext);
    const navigate = useNavigate();
    const loadingContext = useLoadingContext();
    const [errors, setErrors] = useState([]);
    const [courseLists, setCourseLists] = useState([]);
    const [course, setCourse] = useState('0');
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [usernames, setUsernames] = useState([]);
    const [username, setUsername] = useState({username: null, ID: null});
    const [usernameInput, setUsernameInput] = useState('');
    const [type, setType] = useState('0');
    const [cat, setCat] = useState('0');
    const support = permission(global, 'support_ticket');

    const handleSearch = debounce((txt) => {
        if (txt.length > 0 && support && cat === '0') {
            setUsername({username: null, ID: null});
            request('user/search/', 'POST', {txt}, global.jwt).then(response => {
                setUsernames(response);
            })
        } else {
            setUsernames([]);
        }
    }, 1000);

    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('ticket/courses/', 'POST', {}, global.jwt).then(response => {
                loadingContext.done();
                setCourseLists(response);
            })
        }
    }, []);
    const send = () => {
        setErrors([]);
        let _errors = [];
        if (title.length < 2) {
            _errors.push('عنوان تیکت باید حداقل از دو کلمه تشکیل شده باشد')
        }
        if (content.length < 10) {
            _errors.push('متن تیکت باید حداقل از 5 کلمه تشکیل شده باشد')
        }

        if (!_errors.length) {
            request('ticket/send/', 'POST', {title, content, type, course, ...username, cat}, global.jwt).then(response => {
                if (response && !support) {
                    navigate('/profile/ticket/' + response);
                } else if (support && response === 'ok') {
                    alert('تیکت با موفقیت ارسال شد', 'success');
                    setUsernames([]);
                    setUsername(({username: null, ID: null}));
                    setUsernameInput('');
                    setCat('0');
                    setTitle('');
                } else {
                    setErrors(['تیکت با موفقیت ارسال نشد'])
                }
            })
        } else {
            setErrors(_errors);
        }
    }
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - ارسال تیکت</title>
            </Helmet>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='tickets'/>
                    <div className="content-wrapper send-ticket">
                        <div className="title-section">
                            <i className="fal fa-envelope-open"/>
                            <div>
                                <h3>ارسال تیکت</h3>
                                <h4>در این قسمت سه دوره مشابه با دوره مورد نظر قرار داده شده است</h4>
                            </div>
                        </div>
                        <form className='form-send-ticket'>
                            {errors.length ?
                                <div className='errors'>
                                    {errors.map((error, key) => {
                                        return (
                                            <p key={key}>{error}</p>
                                        )
                                    })}
                                </div>
                                :
                                null
                            }
                            <label>
                                <span>عنوان تیکت</span>
                                <input onChange={event => setTitle(event.target.value)} value={title} type='text'/>
                            </label>
                            <label>
                                <span>بخش مربوطه</span>
                                <select onChange={event => setType(event.target.value)}>
                                    <option value='0'>مالی</option>
                                    <option value='1'>فنی</option>
                                    <option value='2'>مشاوره قبل خرید</option>
                                    <option value='3'>عمومی</option>
                                </select>
                            </label>
                            {
                                support ?
                                    <>
                                        <label style={{width: '45%', marginRight: '21px'}}>
                                            <span>نام کاربری</span>
                                            <input onChange={event => {
                                                if (cat === '0') {
                                                    setUsernameInput(event.target.value);
                                                    handleSearch(event.target.value)
                                                }
                                            }} value={usernameInput} type='text'/>
                                            {usernames.length ?
                                                <ul>
                                                    {usernames.map((u, k) => {
                                                        return (
                                                            <li key={k} onClick={() => {
                                                                setUsernameInput(u.username);
                                                                setUsername({username: u.username, ID: u.ID})
                                                                setUsernames([]);
                                                            }}>{u.username}</li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                null
                                            }
                                        </label>
                                        <label>
                                            <span>دسته</span>
                                            <select onChange={event => {
                                                setUsername({username: null, ID: null});
                                                setUsernameInput('');
                                                setUsernames([]);
                                                setCat(event.target.value)
                                            }}>
                                                <option value='0'>همه</option>
                                                <option value='1'>خریدارن</option>
                                                <option value='2'>بدون خرید</option>
                                            </select>
                                        </label>
                                    </>
                                    :
                                    <label className='course'>
                                        <span>انتخاب دوره</span>
                                        <select onChange={event => setCourse(event.target.value)}>
                                            <option value=''>انتخاب دوره مورد نظر</option>
                                            {courseLists.length ?
                                                courseLists.map((co, key) => {
                                                    return (
                                                        <option key={key} value={co.ID}>{co.title}</option>
                                                    )
                                                }) :
                                                null
                                            }
                                        </select>
                                    </label>
                            }
                            <div>
                                <CKEditor
                                    config={{
                                        language: 'fa',
                                        placeholder: "متن تیکت خود را تایپ کنید",
                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"],

                                    }}
                                    editor={ClassicEditor}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setContent(data)
                                    }}
                                />
                            </div>
                            <a onClick={send} className='comment__write-btn js-comment-post-btn js-save'>ارسال تیکت</a>
                            <br/>
                            <br/>
                        </form>

                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default SendTickets;
