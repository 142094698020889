import React, {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {globalContext} from "../../App";
import {numberToPersian, permission} from "../../functions/global";

const Sidebar = ({active}) => {
    const {global, setGlobal} = useContext(globalContext);
    const navigate = useNavigate();
    const [showSidebar, setShowSidebar] = useState(false);
    const logOut = function () {
        document.cookie = 'jwt=;expires=Thu, 01 Jan 2029 00:00:01 GMT;';
        setGlobal({
            ...global,
            jwt: null,
            jwtDecode: null,
            carts: []
        });
        navigate('/');
    };
    let admin = permission(global, 'manage_options');
    return (
        <>
            <div className={`loading ${showSidebar ? '' : 'hidden'}`} style={{opacity: '0.7'}} onClick={() => setShowSidebar(false)}/>
            <div className={`sidebar ${showSidebar ? 'active' : ''}`}>
                <div className='mobile-menu' onClick={() => setShowSidebar(!showSidebar)}>
                    <i className="fa-solid fa-bars"/>
                </div>
                <div className='dash-menu'>
                    <div className="avatar">
                        <div>
                            <img src={global.jwtDecode ? global.jwtDecode.data.avatar : "/images/defaultuser.png"}/>
                            <div>
                                <div>{global.jwtDecode ? global.jwtDecode.data.display_name : null}</div>
                                <div>{numberToPersian(global.jwtDecode ? global.jwtDecode.data.phone : null)}</div>
                            </div>
                        </div>
                        <Link to={'/profile/edit/'}><i className="far fa-user-edit"/></Link>
                    </div>
                    <ul>
                        <li>
                            <Link to={'/profile/dashboard/'}>
                                {active === 'dashboard' ?
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                         className="r-b-i" width="45px" height="45px"/>
                                    :
                                    null
                                }
                                <i className="fal fa-tachometer"/>
                                <span>پیشخوان</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/profile/edit/'}>
                                {active === 'edit' ?
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                         className="r-b-i" width="45px" height="45px"/>
                                    :
                                    null
                                }
                                <i className="fal fa-user"/>
                                <span>اطلاعات حقیقی</span>
                            </Link>
                        </li>
                        {
                            'enable_wallet' in global.options && global.options.enable_wallet ?
                                <li>
                                    <Link to={'/profile/wallet/'}>
                                        {active === 'wallet' ?
                                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                                 className="r-b-i" width="45px" height="45px"/>
                                            :
                                            null
                                        }
                                        <i className="fa-light fa-wallet"/>
                                        <span>کیف پول</span>
                                    </Link>
                                </li>
                                :
                                null
                        }
                        {
                            permission(global, 'wallet_request') ?
                                <li>
                                    <Link to={'/profile/request/'}>
                                        {active === 'request' ?
                                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                                 className="r-b-i" width="45px" height="45px"/>
                                            :
                                            null
                                        }
                                        <i className="fa-light fa-credit-card"/>
                                        <span>تسویه ها</span>
                                    </Link>
                                </li>
                                :
                                null
                        }
                        <li>
                            <Link to={'/profile/factors/'}>
                                {active === 'factors' ?
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                         className="r-b-i" width="45px" height="45px"/>
                                    :
                                    null
                                }
                                <i className="fa-light fa-ticket"/>
                                <span>فاکتورها</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/profile/bookmark/'}>
                                {active === 'bookmark' ?
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                         className="r-b-i" width="45px" height="45px"/>
                                    :
                                    null
                                }
                                <i className="fal fa-bookmark"/>
                                <span>نشانک</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/profile/courses/'}>
                                {active === 'courses' ?
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                         className="r-b-i" width="45px" height="45px"/>
                                    :
                                    null
                                }
                                <i className="fal fa-bags-shopping"/>
                                <span>دوره های شما</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/profile/tickets/'}>
                                {active === 'tickets' ?
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NSIgaGVpZ2h0PSI1My40MDkiIHZpZXdCb3g9IjAgMCA1NSA1My40MDkiPjxwYXRoIGlkPSJQYXRoXzM5NzUiIGRhdGEtbmFtZT0iUGF0aCAzOTc1IiBkPSJNNDIuNSw1My40MDloLTMwQTEyLjUsMTIuNSwwLDAsMSwwLDQwLjkwOVYyMS4xNTljMC02LjksNC43NS0xMC4yNSw4Ljc1LTEyLjI1bDI0LjUtOEM0Ny43NS0zLjA5MSw1NSw2LjgsNTUsMTcuMTU5djIzLjc1QTEyLjUsMTIuNSwwLDAsMSw0Mi41LDUzLjQwOVoiIGZpbGw9IiNmY2JmMTciLz48L3N2Zz4K"
                                         className="r-b-i" width="45px" height="45px"/>
                                    :
                                    null
                                }
                                <i className="fal fa-envelope-open"/>
                                <span>تیکت ها</span>
                            </Link>
                        </li>
                        <li>
                            <a onClick={logOut}>
                                <i className="fal fa-sign-in"/>
                                <span>خروج</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar;
