import React, {useContext, useState} from "react";
import {globalContext} from "../../App";
import {alert, request} from "../../functions/global";
import Modal from "../misc/modal";

const Share = ({data}) => {
    const {global} = useContext(globalContext);
    const [isBookmark, setIsBookmark] = useState(data.bookmark);
    const [isLike, setIsLike] = useState(data.like);
    const [showModal, setShowModal] = useState(false);
    const bookmark = () => {
        if (global.jwt) {
            setIsBookmark(!isBookmark);
            request('user/bookmark', 'POST', {postID: data.ID}, global.jwt).then(response => {
            })
        } else {
            alert('برای استفاده از این قابلیت ابتدا وارد اکانت کاربری خود شوید');
        }
    }

    const like = () => {
        if (global.jwt) {
            setIsLike(!isLike);
            request('user/like', 'POST', {postID: data.ID}, global.jwt).then(response => {
            })
        } else {
            alert('برای استفاده از این قابلیت ابتدا وارد اکانت کاربری خود شوید');
        }
    }
    const url = window.location.href;
    return (
        <>
            <Modal show={showModal} setShow={setShowModal}>
                <div className='rate-modal'>
                    <p style={{marginBottom: '1px'}}>برای اشتراک گذاری در شبکه های اجتماعی کلیک کنید</p>
                    <span>با اشتراک گذاری مطالب ما از ما حمایت کنید</span>
                    <div>
                        <ul style={{justifyContent: 'center', padding: '10px'}}>
                            <li>
                                <a href={`https://telegram.me/share/url?url=${url}&text=${data.title}`} target='_blank'><i className="fab fa-telegram-plane"/></a>
                            </li>
                            <li>
                                <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target='_blank'><i className="fab fa-facebook-square"/></a>
                            </li>
                            <li>
                                <a href={`https://twitter.com/intent/tweet?text=${url}`} target='_blank'><i className="fab fa-twitter"/></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>
            <div>
                <ul>
                    <li onClick={() => setShowModal(true)}><i className="fi fi-rs-cloud-share"/></li>
                    <li onClick={like}>
                        {isLike ? <i className="fi fi-sr-heart"/> : <i className="fi fi-rr-heart"/>}
                    </li>
                    <li onClick={bookmark}>
                        {isBookmark ? <i className="fi fi-sr-bookmark"/> : <i className="fi fi-rr-bookmark"/>}
                    </li>
                </ul>
            </div>
        </>
    )
}
export default Share;
