import React, {useState} from "react";

const TableCustom = ({heads, count, body, data, show = 10, showSearch = true, showHeader = true, classes = ''}) => {
    let [currentPage, setCurrentPage] = useState(1);
    let [search, setSearch] = useState('');
    let pages = Math.ceil(count / show);
    pages = new Array(pages).fill(0);
    let from = currentPage;
    let to = currentPage * show;

    return (
        <div className={`table-wrapper-custom ${classes}`}>
            {showSearch ?
                <input type='text' value={search} onChange={event => setSearch(event.target.value)} placeholder='جستجو ...'/>
                :
                null
            }
            <div className='table-responsive'>
                <table>
                    {showHeader ?
                        <thead>
                        <tr className='head'>
                            {heads.map((head, key) => {
                                return (
                                    <th key={key}>{head}</th>
                                )
                            })}
                        </tr>
                        </thead>
                        :
                        null
                    }
                    <tbody>
                    {body.map((b, key) => {
                        return (search.length && data[key].indexOf(search) !== -1) || (!search.length && from <= key + 1 && key + 1 <= to) ? <React.Fragment key={key}>{b}</React.Fragment> : null;
                    })}
                    </tbody>
                </table>
            </div>
            {pages.length > 1 ?
                <div className='table-pages'>
                    <button id="pagination-previous-page" type="button" onClick={() => currentPage > 1 ? setCurrentPage(currentPage - 1) : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </button>
                    <span className='detail'>صفحه {currentPage} از {pages.length}</span>
                    <button id="pagination-next-page" type="button" onClick={() => currentPage < pages.length ? setCurrentPage(currentPage + 1) : null}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </button>
                </div>
                :
                null
            }

        </div>
    )
}
export default TableCustom;
