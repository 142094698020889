import React from "react";
import {Link} from "react-router-dom";

const Teachers = ({data}) => {
    return (
        <div className='teachers'>
            <div />
            <div className='head'>
                <h1>
                    <span>مدرسین برتر آموزش تیوب</span>
                    <Link className='show-all' to={'/teachers/'}>همه مدرسین</Link>
                </h1>
                <h2>در این بخش تعدادی از مدرسین برتر آموزش تیوب را مشاهده می کنید</h2>
            </div>
            <div className='lists'>
                <ul>
                    {data.map((te,key) => {
                        return (
                            <li key={key}>
                                <div>
                                    <div className='back-img'/>
                                    <img src={te.cover} alt={te.name}/>
                                </div>
                                <div>
                                    <div>
                                        <h3><Link to={`/teacher/${te.slug}`}>{te.name}</Link></h3>
                                        <h4>{te.activity}</h4>
                                    </div>
                                    <p>{te.description}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default Teachers;
