import React from "react";
import {numberToPersian} from "../../functions/global";

const Pagination = ({count, callback, page, setPage}) => {
    let full_page = Array.from({length: count}, (_, i) => i + 1);
    let prev_sep = false;
    let next_sep = false;
    let sep = full_page.length > 7;
    return (
        <div className='box comment-pagination'>
            {full_page.length > 1 ?
                <>
                    <span style={{fontWeight: '300', color: '#686868', fontFamily: 'IRANYekanX', marginLeft: '11px', fontSize: '13px'}}>صفحات </span>
                    {full_page.map((i, k) => {
                        let s = false;
                        let show_page = sep && k < 3 || k === full_page.length - 1 || k === page - 1 || page - 2 === k || page === k;
                        if (sep && !(k < 3 || k === full_page.length - 1 || k === page - 1 || page - 2 === k || page === k)) {
                            if ((k < page && !prev_sep) || (k > page && !next_sep)) {
                                s = true;
                                if (k < page) {
                                    prev_sep = true
                                } else {
                                    next_sep = true
                                }
                            }
                        }

                        return (
                            <React.Fragment key={k}>
                                {!sep ?
                                    <a className={`${page === k + 1 ? 'current page-numbers' : 'page-numbers'}`} onClick={() => callback(k + 1)}>{numberToPersian(k + 1)}</a>
                                    :
                                    (
                                        show_page ?
                                            <a className={`${page === k + 1 ? 'current page-numbers' : 'page-numbers'}`} onClick={() => callback(k + 1)}>{numberToPersian(k + 1)}</a>
                                            :
                                            (
                                                s ?
                                                    <span>...</span>
                                                    :
                                                    null
                                            )
                                    )
                                }
                            </React.Fragment>
                        )
                    })}
                </>
                :
                null
            }
            {sep && page !== full_page.length ?
                <a onClick={() => callback(page + 1)} className="next page-numbers">بعدی »</a> : null}
        </div>
    )
}
export default Pagination;
