import React from "react";
import videojs from 'video.js';
import 'videojs-seek-buttons'
import '../../public/css/video-js.css'
import video from '@videojs/http-streaming';

const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {options, onReady} = props;

    React.useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;
            videojs.Vhs.xhr.beforeRequest = function (option) {
                option['headers'] = {
                    'Authorization': options.token,
                };

                return option;
            };
            const player = playerRef.current = videojs(videoElement, options, () => {
                onReady && onReady(player);
            });

            player.seekButtons({
                forward: 30,
                back: 10
            });
        } else {
        }
    }, [options, videoRef]);

    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {

                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);
    return (
        <>
            <video ref={videoRef} className='video-js vjs-big-play-centered vjs-theme-sea'/>
        </>
    )
}

export default VideoJS;
