import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {globalContext} from "../../App";
import {Link, useNavigate} from 'react-router-dom';
import {numberToPersian, request} from "../../functions/global";
import {Helmet} from "react-helmet";
import DashboardLoader from "../loading/profile/dashboard";

const Dashboard = () => {
    const {global} = useContext(globalContext);
    const navigate = useNavigate();
    const [data, setData] = useState({});
    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('user/profile/', 'POST', {}, global.jwt).then(response => {
                setData(response);
            })
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - داشبورد کاربری</title>
            </Helmet>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='dashboard'/>
                    {'course_count' in data ?
                        <div className="content-wrapper">
                            <ul className="top-boxes">
                                <li>
                                    <div>
                                        <h4>
                                            <i className="fa-solid fa-films"/>
                                            دوره های شما
                                        </h4>
                                        <p>
                                            در این قسمت میتوانید تعداد دوره ها یا مقالاتی که به آن را به نشانک اضافه نموده اید را مشاهده کنید
                                        </p>
                                        <span>{numberToPersian(data.course_count.length)} عدد</span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h4>
                                            <i className="fa-duotone fa-wallet"/>
                                            کیف پول
                                        </h4>
                                        <p>
                                            در اینجا شما میتوانید تعداد دوره هایی که تا کنون خریداری کرده اید را مشاهده کنید
                                        </p>
                                        <span>{numberToPersian(data.wallet.toLocaleString())} تومان</span>

                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <h4>
                                            <i className="fa-duotone fa-message-captions"/>
                                            تیکت های باز
                                        </h4>
                                        <p>
                                            در این بخش میتوانید به راحتی تعداد تیکت های باز را مشاهده کنید و نسبت به آن اقدام نمایید
                                        </p>
                                        <span>{numberToPersian(data.tickets.length)} تیکت</span>
                                    </div>
                                </li>
                            </ul>
                            <div className="dashboard-content">
                                <section className="last-notification last-ticket">
                                    <div className="title-section">
                                        <i className="fal fa-chart-pie"/>
                                        <div>
                                            <h3>آخرین تیکت ها</h3>
                                            <h4>در این بخش تیکت های باز شما نمایش داده می شود</h4>
                                        </div>

                                    </div>
                                    {data.tickets.length ?
                                        <ul>
                                            {data.tickets.map((ticket, key) => {
                                                return (
                                                    <li key={key}>
                                                        <Link to={`/profile/ticket/${ticket.id}`}>{ticket.title}</Link>
                                                        <span>{ticket.date_updated}</span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <p className='no-item-b'>تیکت باز یافت نشد</p>
                                    }
                                </section>
                            </div>
                        </div>
                        :
                        <DashboardLoader/>
                    }
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Dashboard;
