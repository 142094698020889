import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {request} from "../../functions/global";
import Header from "../header/main";
import Footer from "../footer/main";
import Description from "./description";
import Comment from "./comment";
import {globalContext} from "../../App";
import {Helmet} from "react-helmet-async";
import VideoArticle from "./video";
import Head from "./head";
import HeadLoader from "../loading/article/head";
import CommentLoader from "../loading/article/comment";
import DescriptionLoader from "../loading/article/description";

const Article = () => {
    let google = '';
    const {slug} = useParams();
    const [data, setData] = useState(null);
    const [star, setStar] = useState(0);
    const {global} = useContext(globalContext);
    useEffect(() => {
        request('post/article/', 'POST', {slug}, global.jwt).then(response => {
            setData(response);
            setStar(response.rate);
        })
    }, []);

    if (data) {
        google = JSON.stringify({
            "@context": "http://schema.org",
            "@graph": [
                {
                    "@type": "Organization",
                    "@id": "https://amoozesh.tube/#organization",
                    "name": "وبسایت آموزشی آموزش تیوب",
                    "url": "https://amoozesh.tube/",
                    "sameAs": [
                        "https://t.me/amoozesh_tube",
                        "https://www.instagram.com/amoozesh.tube/"
                    ],
                    "logo": {
                        "@type": "ImageObject",
                        "@id": "https://amoozesh.tube/#logo",
                        "url": "https://amoozesh.tube/images/logo-.png",
                        "width": 861,
                        "height": 269,
                        "caption": "آموزش تیوب"
                    },
                    "image": {
                        "@id": "https://amoozesh.tube/#logo"
                    }
                },
                {
                    "@type": "WebSite",
                    "@id": "https://amoozesh.tube#website",
                    "url": "https://amoozesh.tube",
                    "name": "وبسایت آموزشی آموزش تیوب",
                    "description": "آموزش تیوب وبسایت آموزش برنامه نویسی وب و موبایل ، جاوااسکریپت ، لاراول ، آموزش پیشرفته react native و reactjs ، آموزش node js ، آموزش پیشرفته node و انواع زبان های دیگر که همواره سعی کرده است بروزترین باشد",
                    "publisher": {
                        "@id": "https://amoozesh.tube/#organization"
                    },
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://amoozesh.tube/search/?txt={search_term_string}",
                        "query-input": "required name=search_term_string"
                    }
                }
                , {
                    "@type": "ImageObject",
                    "@id": "https://amoozesh.tube/article/" + data.slug + "#primaryimage",
                    "url": data.thumbnail,
                    "width": 480,
                    "height": 270
                }
                , {
                    "@type": "WebPage",
                    "@id": "https://amoozesh.tube/article/" + data.slug + "#webpage",
                    "url": "https://amoozesh.tube/article/" + data.slug,
                    "inLanguage": "fa-IR",
                    "name": data.title,
                    "isPartOf": {
                        "@id": "https://amoozesh.tube#website"
                    },
                    "primaryImageOfPage": {
                        "@id": "https://amoozesh.tube/article/" + data.slug + "#primaryimage"
                    },
                    "datePublished": 'post_date_en' in data ? data.post_date_en : data.post_date,
                    "dateModified": 'post_date_en' in data ? data.post_date_en : data.post_date,
                    "description": 'excerpt' in data ? data.excerpt : data.description,
                    "breadcrumb": {
                        "@id": "https://amoozesh.tube/article/" + data.slug + "#breadcrumb"
                    }
                }
                , {
                    "@type": "BreadcrumbList",
                    "@id": "https://amoozesh.tube/article/" + data.slug + "#breadcrumb",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": 1,
                            "item": {
                                "@type": "WebPage",
                                "@id": "https://amoozesh.tube",
                                "url": "https://amoozesh.tube",
                                "name": "صفحه‌  اصلی"
                            }
                        }, {
                            "@type": "ListItem",
                            "position": 2,
                            "item": {
                                "@type": "WebPage",
                                "@id": data && data.cat.length ? "https://amoozesh.tube/category/" + data.cat[0].slug : "https://amoozesh.tube/search/",
                                "url": data && data.cat.length ? "https://amoozesh.tube/category/" + data.cat[0].slug : "https://amoozesh.tube/search/",
                                "name": data && data.cat.length ? data.cat[0].name : "آموزش برنامه نویسی"
                            }
                        }, {
                            "@type": "ListItem",
                            "position": 3,
                            "item": {
                                "@type": "WebPage",
                                "@id": "https://amoozesh.tube/article/" + data.slug,
                                "url": "https://amoozesh.tube/article/" + data.slug,
                                "name": data.title
                            }
                        }]
                }
            ]
        })
    }

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - {data ? data.title : 'مقاله'}</title>
                <meta name="description" content={data && 'excerpt' in data ? data.excerpt : (data ? data.description : '')}/>
                <link rel="canonical" href={data ? "https://amoozesh.tube/article/" + data.slug : ''}/>
                <meta property="og:title" content={data ? data.title : 'آموزش تیوب'}/>
                <meta property="og:description" content={data && 'excerpt' in data ? data.excerpt : (data ? data.description : '')}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={data ? "https://amoozesh.tube/article/" + data.slug : ''}/>
                <meta property="og:site_name" content="وبسایت آموزشی آموزش تیوب"/>
                <meta property="og:brand" content="آموزش تیوب"/>
                <meta property="og:type" content="article"/>
                <meta property="og:locale" content="fa"/>
                <meta property="og:image" content={data ? data.thumbnail : ''}/>
                <script type="application/ld+json">{google}</script>
            </Helmet>
            <Header/>
            <div className='single'>
                <div className='article-wrapper' style={{width: '100%'}}>
                    <section className="body">
                        <div className="inner">
                            {data ?
                                <div>
                                    <article className='article-box'>
                                        <div className='back-article-mob' />
                                        <Head data={data} star={star} setStar={setStar}/>
                                        <Description data={data}/>
                                        {
                                            'type' in data && data.type.length && data.type[0].slug === 'text' ?
                                                null
                                                :
                                                global.jwt ?
                                                    data.video && data.video.length ?
                                                        <VideoArticle data={data}/>
                                                        :
                                                        null
                                                    :
                                                    <Link className='login-video' to={'/login/'}>برای مشاهده ویدیو این مقاله ابتدا وارد اکانت خود شوید</Link>
                                        }
                                    </article>
                                    <Comment data={data}/>
                                </div>
                                :
                                <div>
                                    <div className='article-box'>
                                        <HeadLoader/>
                                        <DescriptionLoader/>
                                    </div>
                                    <CommentLoader/>
                                </div>
                            }
                        </div>
                    </section>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Article;
