import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ScrollToTop() {
    const {pathname} = useLocation();
    let chat = document.querySelector('#raychat_main');
    if (pathname !== '/') {
        if (typeof (chat) !== 'undefined' && chat !== null) {
            chat.style.display = 'none'
        }
    } else {
        if (typeof (chat) !== 'undefined' && chat !== null) {
            chat.style.display = 'block'
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
