import React, {useContext, useEffect, useState} from "react";
import {globalContext} from "../../App";
import {mobile_validate, numberToEnglish, request, validateEmail} from "../../functions/global";
import {setCookie} from "nookies";
import jwt_decode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import Counter from "../misc/counter";
import ReCAPTCHA from "react-google-recaptcha";
import {Link} from "react-router-dom";

const Register = ({tab, errors, setErrors, setShowLoading, options}) => {
    const navigate = useNavigate();
    const {global, setGlobal} = useContext(globalContext);
    const [showPass, setShowPass] = useState(null);
    const [extraFiled, setExtraFiled] = useState(false);
    const [siteRegisterField, setSiteRegisterField] = useState(true);
    const [jwt, setJwt] = useState('');
    const [phoneVerify, setPhoneVerify] = useState(false);
    const [code, setCode] = useState('');
    const [phone, setPhone] = useState('');
    const [googleCaptcha, setGoogleCaptcha] = useState('');
    let [registerData, setRegisterData] = useState({
        token: null,
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        rePassword: ''
    });

    useEffect(() => {
        if (global.jwt) {
            navigate('/');
        }
    }, []);

    const handleSocialRegister = (user) => {
        setShowLoading(true);
        request('user/register/google/', 'POST', {token: user._token.idToken}, null).then(response => {
            setShowLoading(false);
            if (response.status) {
                setExtraFiled(true);
                setRegisterData({
                    ...registerData,
                    token: response.data.token,
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                })
            } else {
                setErrors([response.message]);
            }
        })
    };

    const handleSocialRegisterComplete = (e) => {
        e.preventDefault();
        setShowLoading(true);
        setErrors([]);
        let errors_text = [];
        if (phone.length !== 11 || !mobile_validate(numberToEnglish(phone))) {
            setErrors([...errors, 'شماره موبایل وارد شده صحیح نمی باشد']);
            errors_text.push('شماره موبایل وارد شده صحیح نمی باشد');
        }
        if (registerData.first_name.length < 3 || registerData.last_name.length < 3) {
            setErrors([...errors, 'فیلد های نام و نام خانوادگی به صورت صحیح پر کنید']);
            errors_text.push('فیلد های نام و نام خانوادگی به صورت صحیح پر کنید');
        }
        if (registerData.password.length < 6) {
            setErrors([...errors, 'پسورد حداقل ۶ کارکتر می باشد']);
            errors_text.push('پسورد حداقل ۶ کارکتر می باشد');
        }
        if (registerData.password !== registerData.rePassword) {
            setErrors([...errors, 'پسورد با تکرار پسورد باید یکی باشند']);
            errors_text.push('پسورد با تکرار پسورد باید یکی باشند');
        }
        if (errors_text.length === 0) {
            request('user/register/google/complete/', 'POST', {...registerData, phone: numberToEnglish(phone)}, null).then(response => {
                setShowLoading(false);
                if (response.status) {
                    setExtraFiled(false);
                    setPhoneVerify(true);
                } else {
                    setErrors([response.message]);
                }
            })
        } else {
            setShowLoading(false);
        }

    }

    const handleSocialVerifyCode = (e) => {
        e.preventDefault();
        setShowLoading(true);
        if (code.length === 5) {
            request('user/phone/verify/', 'POST', {code: numberToEnglish(code), token: registerData.token, phone, googleCaptcha}, jwt).then(response => {
                if (response.status) {
                    setShowLoading(false);
                    if (typeof response.data == 'object' && "jwt" in response.data) {
                        setCookie(null, 'jwt', response.data.jwt, {
                            path: '/',
                            maxAge: 30 * 24 * 60 * 60
                        });
                        let carts = Array.isArray(response.data.carts) ? response.data.carts : Object.values(response.data.carts)
                        setGlobal({
                            ...global,
                            jwt: response.data.jwt,
                            jwtDecode: jwt_decode(response.data.jwt),
                            carts
                        });
                        navigate('/');
                    } else {
                        setErrors(['تداخلی در برنامه بوجود آمده است دوباره تلاش کنید'])
                    }
                } else {
                    setShowLoading(false);
                    setErrors([response.message]);
                }
            })
        } else {
            setShowLoading(false);
            setErrors(['کد وارد شده اشتباه می باشد']);
        }
    }

    const handleAmoozeshRegister = (e) => {
        e.preventDefault();
        setShowLoading(true);
        setErrors([]);
        let errors_text = [];
        if (phone.length !== 11 || !mobile_validate(numberToEnglish(phone))) {
            setErrors([...errors_text, 'شماره موبایل وارد شده صحیح نمی باشد']);
            errors_text.push('شماره موبایل وارد شده صحیح نمی باشد');
        }
        if (registerData.first_name.length < 3 || registerData.last_name.length < 3) {
            setErrors([...errors_text, 'فیلد های نام و نام خانوادگی به صورت صحیح پر کنید']);
            errors_text.push('فیلد های نام و نام خانوادگی به صورت صحیح پر کنید');
        }
        if (!validateEmail(registerData.email)) {
            setErrors([...errors_text, 'فیلد ایمیل را به صورت صحیح پر کنید']);
            errors_text.push('فیلد ایمیل به صورت صحیح پر کنید');
        }
        if (registerData.password.length < 6) {
            setErrors([...errors, 'پسورد حداقل ۶ کارکتر می باشد']);
            errors_text.push('پسورد حداقل ۶ کارکتر می باشد');
        }
        if (registerData.password !== registerData.rePassword) {
            setErrors([...errors, 'پسورد با تکرار پسورد باید یکی باشند']);
            errors_text.push('پسورد با تکرار پسورد باید یکی باشند');
        }
        if (errors_text.length === 0) {
            request('user/register/complete/', 'POST', {...registerData, phone: numberToEnglish(phone)}, null).then(response => {
                setShowLoading(false);
                if (response.status) {
                    setJwt(response.jwt);
                    setSiteRegisterField(false);
                    setPhoneVerify(true);
                } else {
                    setErrors(response.message);
                }
            })
        } else {
            setShowLoading(false);
        }
    }

    const handleSocialLoginFailure = (err) => {
    };

    const onChange = (value) => {
        setGoogleCaptcha(value);
    }

    return (
        <>
            <div className={`register-block-wrapper ${tab === 'register' ? 'active' : ''}`}>
                {'enable_register' in options
                    ?
                    options['enable_register'] ?
                        <>
                            <div className='fields'>
                                <label>
                                    {errors.map((error, key) => {
                                        return (
                                            <p className='error' key={key}>{error}</p>
                                        )
                                    })}
                                </label>
                                {siteRegisterField ?
                                    <div>
                                        <p className='register-title-info'> لطفا اطلاعات زیر به صورت کامل و دقیق پر کنید در صورت مغایرت امکان ویرایش بعضی از اطلاعات وجود ندارد</p>
                                        <label>
                                            نام
                                            <input type='text' value={registerData.first_name} onChange={event => setRegisterData({
                                                ...registerData,
                                                first_name: event.target.value
                                            })} name='first_name' placeholder='نام خود را وارد کنید'/>
                                        </label>
                                        <label>
                                            نام خانوادگی
                                            <input type='text' value={registerData.last_name} onChange={event => setRegisterData({
                                                ...registerData,
                                                last_name: event.target.value
                                            })} name='last_name' placeholder='نام خانوادگی وارد کنید'/>
                                        </label>
                                        <label>
                                            ایمیل
                                            <input type='text' value={registerData.email} onChange={event => setRegisterData({
                                                ...registerData,
                                                email: event.target.value
                                            })} name='email' placeholder='ایمیل خود را وارد کنید'/>
                                        </label>
                                        <label>
                                            شماره موبایل
                                            <input type='text' value={phone} onChange={event => setPhone(event.target.value)} placeholder='شماره موبایل ۱۱ رقمی را وارد کنید'/>
                                        </label>
                                        <label>
                                            رمز عبور
                                            <input type={showPass === 1 ? 'text' : 'password'} onChange={event => setRegisterData({...registerData, password: numberToEnglish(event.target.value)})} value={registerData.password} placeholder='پسورد حداقل ۶ رقمی'/>
                                            <i onClick={() => {
                                                if (showPass === 1) {
                                                    setShowPass(null)
                                                } else {
                                                    setShowPass(1)
                                                }
                                            }} className={`fa-solid  ${showPass === 1 ? 'fa-sharp fa-eye-slash' : 'fa-eye'} `}/>
                                        </label>
                                        <label>
                                            تکرار رمز عبود
                                            <input type={showPass === 2 ? 'text' : 'password'} onChange={event => setRegisterData({...registerData, rePassword: numberToEnglish(event.target.value)})} value={registerData.rePassword} placeholder='پسورد حداقل ۶ رقمی'/>
                                            <i onClick={() => {
                                                if (showPass === 2) {
                                                    setShowPass(null)
                                                } else {
                                                    setShowPass(2)
                                                }
                                            }} className={`fa-solid  ${showPass === 2 ? 'fa-sharp fa-eye-slash' : 'fa-eye'} `}/>
                                        </label>
                                    </div>
                                    :
                                    null
                                }
                                {phoneVerify ?
                                    <>
                                        <label>
                                            کد تایید
                                            <input type='text' value={code} onChange={event => setCode(event.target.value)} placeholder='کد ۵ رقمی پیامکی را وارد کنید'/>
                                            <Counter classes='login-timer' callback={() => {

                                            }} sec={120}/>
                                        </label>
                                        <div className='google-captcha'>
                                            <ReCAPTCHA
                                                sitekey="6Ldjbt4ZAAAAAHNB2Yg_SzDcKJBbIXOhRnoGry-Y"
                                                onChange={onChange}
                                                asyncScriptOnLoad={() => setShowLoading(false)}
                                            />
                                        </div>
                                    </>
                                    :
                                    null
                                }
                                <label>
                                    <p>
                                        با عضویت در سایت، تمام <Link to='/law/' style={{fontWeight: 'bold', color: '#000'}}>قوانین و شرایط</Link> استفاده از خدمات
                                        آموزش تیوب را پذیرفته اید.
                                    </p>
                                </label>
                                {extraFiled ?
                                    <a className='submit' onClick={event => handleSocialRegisterComplete(event)}>ثبت نام</a> : null}
                                {phoneVerify ?
                                    <a className='submit' onClick={event => handleSocialVerifyCode(event)}>تایید کد</a> : null}
                                {siteRegisterField ?
                                    <a className='submit' onClick={event => handleAmoozeshRegister(event)}>ثبت نام</a> : null}
                            </div>
                        </>
                        :
                        <p className='warning'>ثبت نام در سایت توسط مدیریت غیرفعال شده است</p>
                    :
                    null
                }
            </div>
        </>
    )
}

export default Register;
