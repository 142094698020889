import React from "react";
import {Helmet} from "react-helmet";
import Header from "../header/main";
import Footer from "../footer/main";
import {numberToPersian} from "../../functions/global";

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - ارتباط با ما</title>
            </Helmet>
            <Header/>
            <div className='page-container contact'>
                <div className='header'>
                    <h2>ارتباط با آموزش تیوب</h2>
                </div>
                <div className='content'>
                    <div className='header' style={{marginTop: '35px', padding: '0 40px'}}>
                        <span>برای ارتباط ما میتوانید از طریق شبکه های اجتماعی یا تماس تلفنی اقدام کنید توصیه ما استفاده از تیکت در بخش پروفایل یا ارتباط از طریق تلگرام می باشد</span>
                    </div>
                    <ul>
                        <li>
                            <span>
                                <i className="fa-solid fa-phone"/>
                                شماره تماس
                            </span>
                            <span>{numberToPersian('011-42207991')}</span>
                        </li>
                        <li>
                            <span>
                                <i className="fa-brands fa-telegram"/>
                                آیدی تلگرام
                            </span>
                            <span>@amoozesh_support</span>
                        </li>
                        <li>
                            <span>
                                <i className="fa-sharp fa-solid fa-map-location-dot"/>
                                آدرس شرکت
                            </span>
                            <span>مازندران - قائمشهر - خیابان تهران - مبین ۳</span>
                        </li>
                    </ul>
                    <img src='/images/map.png'/>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Contact;
