import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../header/main";
import Loading from "../misc/loading";
import {request} from "../../functions/global";
import {globalContext} from "../../App";
import Footer from "../footer/main";
import {Link} from "react-router-dom";

const Payment = () => {
    const [load, setLoad] = useState(true);
    const {global, setGlobal} = useContext(globalContext);
    const [message, setMessage] = useState('');
    const [state, setState] = useState(null);
    const [type, setType] = useState(null);
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const trackId = urlParams.get('trackId')
        const wallet = urlParams.get('wallet');
        if (wallet) {
            setLoad(false);
            setMessage('خرید دوره با موفقیت انجام شد و مبلغ دوره از کیف پول کسر گردید');
            setState('1');
            setType('1');
        } else {
            request('user/payment/', 'POST', {trackId}, global.jwt).then(response => {
                setLoad(false);
                setMessage(response.message);
                setState(response.state);
                setType(response.type);
                let cartsData = Array.isArray(response.carts) ? response.carts : Object.values(response.carts);
                let cartsID = Array.isArray(response.carts) ? response.carts : Object.keys(response.carts);
                setGlobal({...global, carts: cartsID, cartsData, options: response.options});
            })
        }
    }, []);
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - پرداخت</title>
            </Helmet>
            <Header/>
            <Loading show={load}/>
            <div className="payment-page">
                {
                    state !== null ?
                        <>
                            <img style={{width: '31%', height: 'auto'}} src={state ? '/images/valid.png' : '/images/security.png'}/>
                            <p style={{background: 'transparent', padding: '0', fontSize: '16px', lineHeight: 2}} className={state ? 'success' : 'error'}>{message}</p>
                            <p style={{fontWeight: '200', fontSize: '14px', color: '#565656', lineHeight: 2}}>در صورت بروز هر مشکل میتوانید از طریق تیکت مشکل خود را ارسال کنید تا کارشناسان ما در اسرع وقت مشکل شما را بررسی کنند</p>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                {
                                    state ?
                                        type == 2 ?
                                            <Link to='/profile/wallet' className='submit'>مشاهده کیف پول</Link>
                                            :
                                            <Link to='/profile/courses' className='submit'>مشاهده خرید ها</Link>
                                        :
                                        <Link to='/profile/factors' className='submit'>مشاهده فاکتور ها</Link>
                                }
                                <Link to={'/profile/ticket/send/'} className='submit' style={{background: 'rgb(159 159 159)', width: '38%'}}>ارسال تیکت</Link>
                            </div>
                        </>
                        :
                        null
                }
            </div>
            <Footer/>
        </>

    )
}

export default Payment;
