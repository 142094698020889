import React from "react";
import ContentLoader from "react-content-loader";

const DashboardLoader = () => {
    return (
        <div className="content-wrapper">
            <ul className="top-boxes">
                <li>
                    <div>
                        <h4>
                            <i className="fa-solid fa-films"/>
                            دوره های شما
                        </h4>
                        <p>
                            در این قسمت میتوانید تعداد دوره ها یا مقالاتی که به آن را به نشانک اضافه نموده اید را مشاهده کنید
                        </p>
                        <span style={{opacity: '.6'}}>
                            <ContentLoader
                                speed={2}
                                width='30%'
                                height={30}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="30"/>
                            </ContentLoader>
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <h4>
                            <i className="fa-duotone fa-wallet"/>
                            کیف پول
                        </h4>
                        <p>
                            در اینجا شما میتوانید تعداد دوره هایی که تا کنون خریداری کرده اید را مشاهده کنید
                        </p>
                        <span style={{opacity: '.6'}}>
                            <ContentLoader
                                speed={2}
                                width='30%'
                                height={30}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="30"/>
                            </ContentLoader>
                        </span>
                    </div>
                </li>
                <li>
                    <div>
                        <h4>
                            <i className="fa-duotone fa-message-captions"/>
                            تیکت های باز
                        </h4>
                        <p>
                            در این بخش میتوانید به راحتی تعداد تیکت های باز را مشاهده کنید و نسبت به آن اقدام نمایید
                        </p>
                        <span style={{opacity: '.6'}}>
                            <ContentLoader
                                speed={2}
                                width='30%'
                                height={30}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="30"/>
                            </ContentLoader>
                        </span>
                    </div>
                </li>
            </ul>
            <div className="dashboard-content">
                <section className="last-notification last-ticket">
                    <div className="title-section">
                        <i className="fal fa-chart-pie"/>
                        <div>
                            <h3>آخرین تیکت ها</h3>
                            <h4>در این بخش تیکت های باز شما نمایش داده می شود</h4>
                        </div>
                    </div>
                    <ul>
                        <li style={{background: 'transparent'}}>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={20}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                            </ContentLoader>
                        </li>
                        <li style={{background: 'transparent'}}>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={20}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                            </ContentLoader>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
    )
}
export default DashboardLoader;
