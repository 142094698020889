import React from "react";

const Message = ({show, message, setShow, classes}) => {
    return (
        <div className={`modal-backdrop ${classes}`} style={show ? null : {display: 'none'}}>
            <div className="popup-unlock">
                <div className="unlock__header">
                    <a className="img-btn medium close-btn js-close-btn">
                        <i onClick={() => setShow(false)} className="fas fa-times-circle"/>
                    </a>
                </div>
                <div className="unlock__body">
                    <p style={{textAlign: 'center', fontsize: '12px', margin: '12px 0', fontSize: '12px'}}>
                        {message}
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Message;
