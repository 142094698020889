import React, {useContext, useState} from "react";
import Share from "./share";
import {alert, numberToPersian, permission, request} from "../../functions/global";
import {globalContext} from "../../App";
import {useNavigate} from 'react-router-dom'
import Modal from "../misc/modal";

const Head = ({data, star, setStar}) => {
    const navigate = useNavigate();
    const [source, setSource] = useState('')
    const [hash, setHash] = useState('')
    const [showModal, setShowModal] = useState(false)
    const {global} = useContext(globalContext);
    const add_rate = (star) => {
        if (global.jwt) {
            request('post/rate/add/', 'POST', {ID: data.ID, rating: star}, global.jwt).then(response => {
                alert(response.message, response.type);
                setStar(response.rate);
            });
        } else {
            alert('برای ثبت امتیاز ابتدا وارد اکانت کاربری خود شوید', 'warning');
        }
    };

    const sendBot = (e) => {
        e.preventDefault();
        setShowModal(false);
        setHash('');
        setSource('');
        request('post/bot/', 'POST', {ID: data.ID, type: 'article', source, hash}, global.jwt).then(response => {
            console.log(response)
        })
    }
    return (
        <div>
            <Modal show={showModal} setShow={setShowModal}>
                <div className='bot'>
                    <label>
                        <span>منبع</span>
                        <input type='text' value={source} onChange={event => setSource(event.target.value)}/>
                    </label>
                    <label>
                        <span>هشتگ</span>
                        <input type='text' value={hash} onChange={event => setHash(event.target.value)}/>
                    </label>
                    <a onClick={event => sendBot(event)}>ارسال</a>
                </div>
            </Modal>
            <div>
                <div className='cover'>
                    <img className='post-thumbnail' src={data.thumbnail} alt={data.title}/>
                </div>
            </div>
            <div>
                <h1>
                    {data.title}
                    {permission(global, 'manage_options') ?
                        <a className='bot-btn' onClick={() => setShowModal(true)}>تلگرام</a>
                        :
                        null
                    }
                </h1>
                <div className='author'>
                    <div>
                        <img src={data.avatar} alt='avatar'/>
                        <div>
                            <a>{data.author}</a>
                            <span>{data.author_description}</span>
                        </div>
                    </div>
                    <Share data={data}/>
                </div>
                <div className='bt'>
                    {data.cat.length ?
                        <div className='cat'>
                            <span>دسته بندی :</span>
                            <a onClick={() => navigate('/search/', {state: {txt: '', term: data.cat[0].term_id, type: 'article', title: data.cat[0].name}})}>{data.cat[0].name}</a>
                        </div>
                        :
                        null
                    }
                    <div className='star-article'>
                        <span className='star-wrapper'>
                            <i onClick={() => add_rate(1)} className={`fas fa-star ${star >= 1 ? 'active' : ''}`}/>
                            <i onClick={() => add_rate(2)} className={`fas fa-star ${star >= 2 ? 'active' : ''}`}/>
                            <i onClick={() => add_rate(3)} className={`fas fa-star ${star >= 3 ? 'active' : ''}`}/>
                            <i onClick={() => add_rate(4)} className={`fas fa-star ${star >= 4 ? 'active' : ''}`}/>
                            <i onClick={() => add_rate(5)} className={`fas fa-star ${star >= 5 ? 'active' : ''}`}/>
                        </span>
                        <strong className='article-star'>{numberToPersian(star)}/
                            <span>۵</span>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Head;
