import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {globalContext} from "../../App";
import {Link, useNavigate} from 'react-router-dom';
import {numberToPersian, request} from "../../functions/global";
import {Helmet} from "react-helmet";
import Loading from "../misc/loading";
import TableCustom from "../misc/table";
import Pagination from "../misc/pagination";
import TableCustomLoader from "../loading/table";

const Courses = () => {
    const {global} = useContext(globalContext);
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [show, setShow] = useState(false);
    const [page, setPage] = useState(1);
    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('user/buys/', 'POST', {page}, global.jwt).then(response => {
                setData(response)
            })
        }
    }, []);

    let bookmark_table = {
        head: ['عنوان', 'مدرس', 'تعداد', 'امتیاز', 'مدت زمان']
    }

    if (data !== null) {
        bookmark_table = {
            head: ['عنوان', 'مدرس', 'تعداد', 'امتیاز', 'مدت زمان'],
            body: data.map((res, key) => {
                return (
                    <tr key={key}>
                        <th className='eng'>
                            <Link to={`/${res.post_type}/${res.slug}`}>{res.title}</Link>
                        </th>
                        <th className='eng'>
                            <span>{res.teacher && 'term_id' in res.teacher ? res.teacher.name : '-'}</span>
                        </th>
                        <th className='eng'>
                            <span>{res.post_type === 'course' ? numberToPersian(res.course_count) : '۱'} قسمت</span>
                        </th>
                        <th className='eng'>
                            <span>{numberToPersian(res.rate.score)}</span>
                        </th>
                        <th className='eng'>
                            {res.post_type === 'course' ? numberToPersian(res.course_time_str) : numberToPersian(res.time) + ' دقیقه'}
                        </th>
                    </tr>
                )
            }),
            str: data.map(res => `${res.title}`)
        }
    }

    const page_render = (p) => {
        setPage(p);
        setShow(true);
        request('user/buys/', 'POST', {page: p}, global.jwt).then(response => {
            setShow(false);
            setData(response)
        })
    }

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - دوره های شما</title>
            </Helmet>
            <Header/>
            <Loading show={show}/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='courses'/>
                    <div className="content-wrapper">
                        <div className="title-section">
                            <i className="fa-regular fa-films"/>
                            <div>
                                <h3>دوره های شما</h3>
                                <h4>در این بخش دوره های خریداری شده شما نمایش داده می شود</h4>
                            </div>
                        </div>
                        <div className='table-box'>
                            {
                                data !== null ?
                                    data.length ?
                                        <>
                                            <br/>
                                            <TableCustom body={bookmark_table.body} heads={bookmark_table.head} count={data.length} show={24} data={bookmark_table.str} classes='bookmark-table'/>
                                            <Pagination page={page} setPage={setPage} count={1} callback={(p) => {
                                                page_render(p)
                                            }}/>
                                        </>
                                        :
                                        <p className='no-item-b'>
                                            <i className="fa-solid fa-video-slash"/>
                                            دوره ای در لیست شما یافت نشد
                                        </p>
                                    :
                                    <>
                                        <br/>
                                        <TableCustomLoader heads={bookmark_table.head}/>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Courses;
