import Swal from "sweetalert2";

export const request = async (url, method = 'GET', data = {}, jwt = null, callback = null) => {
    let request, response;
    let main_url = "https://amoozesh.tube/api/amoozesh/";
    try {
        if (method === 'GET') {
            request = await fetch(main_url + url);
        } else {
            let headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            };
            if (jwt) {
                headers['Authorization'] = `Bearer ${jwt}`;
            }
            request = await fetch(main_url + url, {
                method,
                headers,
                body: JSON.stringify(data),
            });
        }
        response = await request.json();
        return response;
    } catch (error) {
        if (callback) {
            callback();
        }
    }
};

export const permission = (global, cap) => {
    if (global.jwt) {
        return cap in global.jwtDecode.data.caps ? true : false;
    }
    return false;
}

export const alert = (title, state = 'warning', callback = () => {
}, timer = 3000) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: state,
        title
    }).then(response => {
        callback();
    })
}

export const logOut = function (setGlobal, callback) {
    document.cookie = 'jwt=;expires=Thu, 01 Jan 2020 00:00:01 GMT;';
    setGlobal({
        ...global,
        jwt: null,
        jwtDecode: null,
        carts: []
    });
    callback();
};


export const numberToPersian = (number) => {
    let persianNumber = ""
    if (number || number === 0) {
        const persian = {
            0: "۰", 1: "۱", 2: "۲", 3: "۳", 4: "۴", 5: "۵", 6: "۶", 7: "۷",
            8: "۸", 9: "۹"
        };
        number = number.toString().split("");
        for (let i = 0; i < number.length; i++) {
            number[i] = number[i] in persian ? persian[number[i]] : number[i];
        }
        for (let i = 0; i < number.length; i++) {
            persianNumber += number[i];
        }
    }

    return persianNumber;
}

export const numberToEnglish = (number) => {
    const faDigits = ['۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹', '۰', ','];
    const enDigits = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ''];

    let ind = -1;
    let englishNumber = ""
    number = number.toString().split("");
    for (let i = 0; i < number.length; i++) {
        if ((ind = faDigits.indexOf(number[i])) !== -1) {
            number[i] = enDigits[ind];
        }
    }
    for (let i = 0; i < number.length; i++) {
        englishNumber += number[i];
    }
    return englishNumber;
}

export const loginAuth = async (user, pass) => {
    let login = request(`user/auth/`, 'POST', {
        user,
        pass
    });
    return await login;
}

export const loginAuthToken = async (token, jwt) => {
    let login = request(`user/auth/token/`, 'POST', {
        token
    }, jwt);
    return await login;
}

export const validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const replaceStr = (str, find, replace) => {
    for (let i = 0; i < find.length; i++) {
        str = str.replace(new RegExp(find[i], 'gi'), replace[i]);
    }
    return str;
}

export const mobile_validate = (mobile) => {
    let find = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
    let replace = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    mobile = replaceStr(mobile, find, replace);
    let mobile_regex = /^(0|\+98|98)9\d{9}$/;
    return mobile_regex.test(mobile);
}

export const getCookie = (key) => {
    let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

export const removeTags = (str) => {
    if ((str === null) || (str === ""))
        return false;
    else
        str = str.toString();

    return str.replace(/(<([^>]+)>)/ig, "");
};
