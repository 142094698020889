import React, {useContext, useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../header/main";
import {request} from "../../functions/global";
import {globalContext} from "../../App";
import Loading from "../misc/loading";
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'

const Test = () => {
    const [step, setStep] = useState(1);
    const [load, setLoad] = useState(false);
    const [name, setName] = useState('');
    const [userInfo, setUserInfo] = useState({
        'age': '', 'level': 'مبتدی', 'education': 'متوسطه', skill: [], telegram: ''
    });
    const [file, setFile] = useState(null);
    const [error, setError] = useState('');
    const [data, setData] = useState(null);
    const {global} = useContext(globalContext);
    const skill = [
        {value: 'Php', label: 'Php'},
        {value: 'Python', label: 'Python'},
        {value: 'Java', label: 'Java'},
        {value: 'Java script', label: 'Java script'},
        {value: 'C', label: 'C'},
        {value: 'C++', label: 'C++'},
        {value: 'Golang', label: 'Golang'},
        {value: 'Ruby', label: 'Ruby'},
    ];
    const navigate = useNavigate();

    const sub = (e) => {
        setError('');
        setName('');
        setFile(null);
        let type = e.target.files[0].type;
        if (['application/zip'].indexOf(type) !== -1) {
            setName(e.target.files[0].name);
            setFile(e.target.files[0]);
        } else {
            setError('لطفا فایل مورد را zip کرده سپس انتخاب کنید');
        }

    }

    const send = () => {
        setError('');
        if (!file) {
            setError('لطفا فایل ترجمه شده را انتخاب سپس برای روی دکمه ارسال کلیک کنید');
        } else if (!userInfo.age.length) {
            setError('سن را وارد کنید');
        } else if (!userInfo.telegram.length) {
            setError('آیدی تلگرام را وارد کنید');
        } else {
            setLoad(true);
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                request('user/test/send/', 'POST', {
                    base64: reader.result,
                    youtube: data.youtube,
                    sub: data.sub,
                    userInfo
                }, global.jwt).then(response => {
                    setLoad(false);
                    if (response.status) {
                        setStep(5);
                    } else {
                        setError(response.error)
                    }
                })
            };
        }

    }

    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        }
        request('user/test/get/', 'POST', {}, null).then(response => {
            setData(response)
        })
    }, []);


    return (
        <>
            <Helmet>
                <title>آموزش تیوب - تست مترجمین</title>
            </Helmet>
            <Header/>
            <Loading show={load}/>
            <div className='test-wrapper'>
                <h3>تست مترجمین</h3>
                <div className='main'>
                    <div className="stepsWrapper">
                        <ul className="steps">
                            <li className={step === 1 ? 'active' : ''}>
                                <div className="heading-tour">
                                    <div className="title-tour">
                                        <div className="title-block">۱. توضیحات مقدماتی</div>
                                        <div className="desc-block left">اولین قدم</div>
                                    </div>
                                </div>
                                <div className="content-tour">
                                    <div className="text-tour">
                                        تیم آموزش تیوب با هدف تکمیل تیم ترجمه اقدام به جذب مترجم زبان انگلیسی کرده است .
                                        <br />
                                        شرایط همکاری  :
                                        <br/>
                                        -  آشنایی کامل مترجم به اصطلاحات برنامه نویسی ، web ، اپلیکیشن های موبایل و  حوزه تخصصی کامپیوتر
                                        <br />
                                        - آزاد بودن وقت مترجم جهت انجام ترجمه
                                        <br/>
                                        - داشتن روحیه تعامل و همکاری
                                        <br/>
                                        توضیحات مالی :
                                        <br/>
                                        - هزینه به صورت دقیقه ای برای هر ویدئو محاسبه و بعد از ترجمه و تحویل فایل زیرنویس مبلغ تقدیم مترجم میگردد ( پروژه محور)
                                    </div>
                                </div>
                                <div className="footer-tour">
                                    <div className="row align-items-center">
                                        <div className="col-md-5"/>
                                        <div className="col-md-7 left d-flex">
                                            <span onClick={() => setStep(2)} className="tour-func next" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">شروع</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={step === 2 ? 'active' : ''}>

                                <div className="heading-tour">
                                    <div className="title-tour">
                                        <div className="title-block">۲. دریافت تست</div>
                                        <div className="desc-block left">دومین قدم</div>
                                    </div>
                                </div>
                                {data ?
                                    <>
                                        <div className="content-tour">
                                            <div className="text-tour">
                                                <p>{data.content}</p>
                                                <a className='test-link' target='_blank' href={data.youtube}>
                                                    <i className="fa-brands fa-youtube"/>
                                                    ویدیو یوتیوب
                                                </a>
                                                <a className='test-link' target='_blank' href={data.sub}>
                                                    <i className="fa-solid fa-closed-captioning"/>
                                                    دانلود زیرنویس
                                                </a>
                                            </div>
                                        </div>
                                        <div className="footer-tour">
                                            <div className="row align-items-center">
                                                <div className="col-md-5">
                                                    <span onClick={() => setStep(1)} className="tour-func back" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">مرحله قبل</span>
                                                </div>
                                                <div className="col-md-7 left d-flex">
                                                    <span onClick={() => setStep(3)} className="tour-func next" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">مرحله بعدی</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <p className='test-closed'>
                                        <i className="fa-duotone fa-lock"/>
                                        بخش تست فعلا بسته می باشد
                                    </p>
                                }
                            </li>
                            <li className={step === 3 ? 'active' : ''}>
                                <div className="heading-tour">
                                    <div className="title-tour">
                                        <div className="title-block">۳. ارسال تست</div>
                                        <div className="desc-block left">سومین قدم</div>
                                    </div>
                                </div>
                                <div className="content-tour">
                                    <div className="text-tour">
                                        {error.length ? <p className='error'>{error}</p> : null}
                                        <p>فایل زیرنویسی که در مرحله قبل دریافت کردید را ترجمه سپس فایل را zip کرده و روی باکس پایین کلیک کنید تا فایل ترجمه را برای ما ارسال کنید توجه کنید بعد از ارسال صبور باشید تا ما ترجمه را بررسی کرده و نتیجه را اعلام کنیم</p>
                                        <label className='sub-file'>
                                            <span>{name ? name : 'ارسال زیرنویس'}</span>
                                            <input type='file' id='sub' onChange={event => sub(event)}/>
                                        </label>
                                    </div>
                                </div>
                                <div className="footer-tour">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <span onClick={() => {
                                                setError('');
                                                setStep(2);
                                            }} className="tour-func back" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">مرحله قبل
                                            </span>
                                        </div>
                                        <div className="col-md-7 left d-flex">
                                            <span onClick={() => {
                                                if (!file) {
                                                    setError('لطفا فایل ترجمه شده را انتخاب سپس برای روی دکمه ارسال کلیک کنید');
                                                } else {
                                                    setError('');
                                                    setStep(4)
                                                }
                                            }} className="tour-func next" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">مرحله بعدی
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={step === 4 ? 'active' : ''}>
                                <div className="heading-tour">
                                    <div className="title-tour">
                                        <div className="title-block">۴. اطلاعات جانبی</div>
                                        <div className="desc-block left">چهارمین قدم</div>
                                    </div>
                                </div>
                                <div className="content-tour">
                                    <div className="text-tour">
                                        {error.length ? <p className='error'>{error}</p> : null}
                                        <p>لطفا اطلاعات زیر را با دقت پر کنید این اطلاعات برای ما بسیار مهم می باشد</p>
                                        <div className='user-info'>
                                            <label>
                                                <span>سن</span>
                                                <input onChange={event => setUserInfo({...userInfo, age: event.target.value})} type='number' placeholder='سن خود را به عدد وارد کنید' name='age'/>
                                            </label>
                                        </div>
                                        <div className='user-info'>
                                            <label>
                                                <span>مقطع تحصیلی</span>
                                                <select onChange={event => setUserInfo({...userInfo, education: event.target.value})}>
                                                    <option>متوسطه</option>
                                                    <option>دیپلم</option>
                                                    <option>کاردانی</option>
                                                    <option>کارشناسی</option>
                                                    <option>کارشناسی ارشد</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='user-info'>
                                            <label>
                                                <span>سطح ترجمه</span>
                                                <select onChange={event => setUserInfo({...userInfo, level: event.target.value})}>
                                                    <option>مبتدی</option>
                                                    <option>متوسط</option>
                                                    <option>حرفه ای</option>
                                                </select>
                                            </label>
                                        </div>
                                        <div className='user-info'>
                                            <label>
                                                <span>با چه زبان های برنامه نویسی کار کردید ؟</span>
                                                <Select
                                                    isMulti
                                                    onChange={newValue => setUserInfo({...userInfo, skill: newValue})}
                                                    name="colors"
                                                    options={skill}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    isRtl={true}
                                                    placeholder='انتخاب کنید'
                                                />
                                            </label>
                                        </div>
                                        <div className='user-info'>
                                            <label>
                                                <span>آیدی تلگرام</span>
                                                <input onChange={event => setUserInfo({...userInfo, telegram: event.target.value})} style={{direction: 'ltr'}} placeholder='مثال : @support_amoozesh' type='text'/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer-tour">
                                    <div className="row align-items-center">
                                        <div className="col-md-5">
                                            <span onClick={() => {
                                                setError('');
                                                setStep(3);
                                            }} className="tour-func back" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">مرحله قبل
                                            </span>
                                        </div>
                                        <div className="col-md-7 left d-flex">
                                            <span onClick={() => send()} className="tour-func next" tabIndex="0" role="button" aria-label="Next slide" aria-disabled="false">ارسال ترجمه</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className={step === 5 ? 'active' : ''}>
                                <div className="heading-tour">
                                    <div className="title-tour">
                                        <div className="title-block">۵. ثبت درخواست</div>
                                        <div className="desc-block left"/>
                                    </div>
                                </div>
                                <div className="content-tour">
                                    <div className="text-tour">
                                        <p className='success-message'>ترجمه شما با موفقیت ارسال شد لطفا تا بررسی ترجمه و اعلام نتیجه صبور باشید جواب تست از ۱ الی ۳ روز به طول می انجامد در صورت قبولی از طریق تلگرام با شما در ارتباط خواهیم بود</p>
                                    </div>
                                </div>
                                <div className="footer-tour">
                                    <div className="row align-items-center">
                                        <div className="col-md-5"/>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Test;
