import React from "react";

const Description = ({data}) => {
    return (
        <div className="single-section description">
            <div dangerouslySetInnerHTML={{ __html: data.description_tag }} />
        </div>
    )
}
export default Description;
