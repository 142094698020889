import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {globalContext} from "../../App";
import {useNavigate} from 'react-router-dom';
import {alert, request} from "../../functions/global";
import Modal from "../misc/modal";
import Loading from "../misc/loading";
import ContentLoader from "react-content-loader";

const Wallet = () => {
    const {global} = useContext(globalContext);
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [price, setPrice] = useState(200000);
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('user/wallet/', 'POST', {}, global.jwt).then(response => {
                if (response.options.enable_wallet) {
                    setData(response.wallet);
                } else {
                    navigate('/');
                }
            })
        }
    }, []);

    const add_wallet = () => {
        setError('')
        if (price >= 50000) {
            setShow(false);
            setLoad(true);
            request('user/wallet/add/', 'POST', {price}, global.jwt).then(response => {
                setLoad(false);
                if (response.status) {
                    window.location.assign(response.url);
                } else {
                    alert(response.message, 'warning')
                }
            })
        } else {
            setError('حداقل مبلغ انتخابی باید ۵۰,۰۰۰ تومان باشد');
        }
    }

    return (
        <>
            <Loading show={load}/>
            <Modal show={show} setShow={setShow} classes='price-wrapper'>
                <p>برای شارژ اکانت یکی از مبالغ را انتخاب یا مبلغ اختیاری خود را وارد کنید</p>
                {error.length ? <p className='error'>{error}</p> : null}
                <div>
                    <div className='your-price'>
                        <p>مبلغ انتخابی شما</p>
                        <p className='p'>{Number(price).toLocaleString()} <span>تومان</span></p>
                    </div>
                </div>
                <ul className='price-choice'>
                    <li className={price === 50000 ? 'active' : ''} onClick={() => setPrice(50000)}>۵۰,۰۰۰ تومان</li>
                    <li className={price === 100000 ? 'active' : ''} onClick={() => setPrice(100000)}>۱۰۰,۰۰۰ تومان</li>
                    <li className={price === 200000 ? 'active' : ''} onClick={() => setPrice(200000)}>۲۰۰,۰۰۰ تومان</li>
                    <li className={[100000, 50000, 200000].indexOf(price) === -1 ? 'active' : ''} onClick={() => setPrice('')}>اختیاری</li>
                </ul>
                <div className={`price-input ${[100000, 50000, 200000].indexOf(price) === -1 ? 'active' : ''}`}>
                    <p>مبلغ انتخابی خود را وارد کنید</p>
                    <input type='number' value={price} onChange={event => setPrice(event.target.value)}/>
                </div>
                <a className='wallet-btn' onClick={add_wallet}>
                    شارژ
                </a>
                <div className='clearfix'/>
            </Modal>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='wallet'/>
                    <div className="content-wrapper">
                        <div className="title-section">
                            <i className="far fa-wallet"/>
                            <div>
                                <h3>کیف پول شما</h3>
                                <h4>در این بخش اطلاعات کیف پول شما نمایش داده می شود</h4>
                            </div>
                        </div>
                        <div className='wallet-wrapper'>
                            <div>
                                <div className='box-wallet'>
                                    <p>موجودی کیف پول</p>
                                    <p>
                                        {data !== null ?
                                            data.toLocaleString()
                                            :
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={30}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="30%" y="0" rx="5" ry="5" width="40%" height="30"/>
                                            </ContentLoader>
                                        }
                                    </p>
                                </div>
                            </div>
                            <ul className='wallet-info'>
                                <li>
                                    مبلغ کیف پول به تومان می باشد
                                </li>
                                <li>
                                    مبالغ موجود در کیف پول جهت خرید قابل استفاده می باشد
                                </li>
                            </ul>
                            <a className='wallet-btn' onClick={() => setShow(true)}>
                                شارژ کیف پول
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Wallet;
