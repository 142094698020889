import React from "react";

const Description = ({data}) => {
    return (
        <div className="single-section description">
            <div className="title-section">
                <i className="fal fa-headphones-alt"/>
                <div>
                    <h3>تـوضیحات دوره</h3>
                    <h4>در این بخش توضیحات دوره نمایش داده می شود</h4>
                </div>
            </div>
            <div dangerouslySetInnerHTML={{__html:data.description_tag}} />
        </div>
    )
}
export default Description;
