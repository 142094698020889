import React, {useEffect, useState} from "react";
import './public/css/uicons-regular-rounded.css';
import './public/css/uicons-bold-straight.css';
import './public/css/uicons-bold-rounded.css';
import './public/css/uicons-solid-rounded.css';
import './public/css/uicons-regular-straight.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './public/css/style.css';
import './public/css/all.min.css';
import './public/css/all6.css';
import 'animate.css';
import './public/css/dark.css';
import './functions/message';
import {Route, Routes} from "react-router-loading";
import Home from "./component/home/main";
import {Helmet} from "react-helmet";
import Course from "./component/course/main";
import Search from "./component/search/search";
import {getCookie, numberToPersian, request} from "./functions/global";
import jwt_decode from "jwt-decode";
import Cart from "./component/cart/cart";
import Dashboard from "./component/profile/dashboard";
import Tickets from "./component/profile/tickets";
import SendTickets from "./component/profile/send-ticket";
import Ticket from "./component/profile/ticket";
import Wallet from "./component/profile/wallet";
import Courses from "./component/profile/courses";
import Edit from "./component/profile/edit";
import Bookmark from "./component/profile/bookmark";
import Video from "./component/video/video";
import Article from "./component/article/main";
import Archive from "./component/archive/archive";
import Notifications from "./component/profile/notifications";
import Notification from "./component/profile/notification";
import MainLogin from "./component/sign/main";
import Test from "./component/translator/test";
import Factor from "./component/profile/factor";
import Contact from "./component/page/contact";
import Payment from "./component/cart/payment";
import Category from "./component/archive/category";
import ArticleArchive from "./component/archive/article";
import Request from "./component/profile/request";
import NotFound from "./component/page/not-found";
import Teachers from "./component/archive/teachers";
import Tops from "./component/archive/tops";
import Discounts from "./component/archive/discount";
import Law from "./component/page/law";
import Modal from "./component/misc/modal";
import {useNavigate} from "react-router-dom";

export const globalContext = React.createContext(null);

function App() {
    const navigate = useNavigate();
    let cookie = getCookie('jwt');
    let theme = window.localStorage.getItem('theme');
    const [modal, setModal] = useState(false);
    const [global, setGlobal] = useState({
        jwt: cookie,
        jwtDecode: cookie ? jwt_decode(cookie) : null,
        notifications: 0,
        taxes: [],
        options: {},
        tickets: 0,
        cartModal: false,
        carts: [],
        cartsData: [],
        theme: theme ? theme : ''
    });
    useEffect(() => {
        request('post/main/', 'POST', {}, cookie).then(response => {
            let cartsID = Array.isArray(response.carts) ? response.carts : Object.keys(response.carts)
            let cartsData = Array.isArray(response.carts) ? response.carts : Object.values(response.carts);
            if (!response.userID) {
                document.cookie = 'jwt=;expires=Thu, 01 Jan 2020 00:00:01 GMT;path=/;';
                setGlobal({...global, jwt: null, jwtDecode: null, carts: cartsID, taxes: response.taxes, cartsData, notifications: response.unread_notifications, tickets: response.unread_ticket, options: response.options});
            } else {
                if (Number(response.unread_ticket)) {
                    setModal(true);
                }
                setGlobal({...global, carts: cartsID, taxes: response.taxes, cartsData, notifications: response.unread_notifications, tickets: Number(response.unread_ticket), options: response.options});
            }
        });
    }, []);

    return (
        <div className={`App ${global.theme}`}>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
            </Helmet>
            <Modal show={modal} setShow={setModal}>
                <p style={{fontSize: '14px', color: '#575757', margin: '28px 0', fontFamily: 'IRANYEKANX'}}>شما {numberToPersian(global.tickets)} تیکت خوانده نشده دارید</p>
                <a style={{color: '#fff', fontWeight: '900', margin: '0 auto 17px', display: 'block', width: 'max-content'}} className='all-course' onClick={() => {
                    setModal(false)
                    navigate('/profile/tickets')
                }}>مشاهده تیکت ها</a>
            </Modal>
            <globalContext.Provider value={{global, setGlobal}}>
                <Routes>
                    <Route path='*' element={<NotFound/>}/>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/login/" element={<MainLogin/>}/>
                    <Route path="/test/" element={<Test/>}/>
                    <Route path="/teachers/" element={<Teachers/>}/>
                    <Route path="/course/:slug" element={<Course/>}/>
                    <Route path="/article/:slug" element={<Article/>}/>
                    <Route path="/archive/:type/" element={<Archive/>}/>
                    <Route path="/category/:category/" element={<Category/>}/>
                    <Route path="/archive/:type/:slug" element={<Archive/>}/>
                    <Route path="/search/" element={<Search/>}/>
                    <Route path="/tops/" element={<Tops/>}/>
                    <Route path="/law/" element={<Law/>}/>
                    <Route path="/discounts/" element={<Discounts/>}/>
                    <Route path="/teacher/:teacher_slug" element={<Search/>}/>
                    <Route path="/search/article/" element={<ArticleArchive/>}/>
                    <Route path="/cart/" element={<Cart/>}/>
                    <Route path="/payment/" element={<Payment/>}/>
                    <Route path="/contact/" element={<Contact/>}/>
                    <Route path="/profile/dashboard" element={<Dashboard/>}/>
                    <Route path="/profile/wallet" element={<Wallet/>}/>
                    <Route path="/profile/request" element={<Request/>}/>
                    <Route path="/profile/edit" element={<Edit/>}/>
                    <Route path="/profile/notifications" element={<Notifications/>}/>
                    <Route path="/profile/courses" element={<Courses/>}/>
                    <Route path="/profile/bookmark" element={<Bookmark/>}/>
                    <Route path="/profile/tickets" element={<Tickets/>}/>
                    <Route path="/profile/factors" element={<Factor/>}/>
                    <Route path="/profile/ticket/send/" element={<SendTickets/>} loading/>
                    <Route path="/profile/ticket/:ticketID" element={<Ticket/>}/>
                    <Route path="/profile/notification/:id" element={<Notification/>} loading/>
                    <Route path="/course/:slug/video/:id" element={<Video/>}/>
                </Routes>
            </globalContext.Provider>
        </div>
    );
}

export default App;
