import React, {useContext, useEffect, useState} from "react";
import {useLoadingContext} from "react-router-loading";
import {permission, request} from "../../functions/global";
import {globalContext} from "../../App";
import {Link, useNavigate} from 'react-router-dom'
import {Helmet} from "react-helmet";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Footer from "../footer/main";
import TableCustom from "../misc/table";
import Pagination from "../misc/pagination";
import TableCustomLoader from "../loading/table";

let e = null;
const Notifications = () => {
    const {global} = useContext(globalContext);
    const navigate = useNavigate();
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [page, setPage] = useState(1);
    const [notifications, setNotifications] = useState([]);
    const admin = permission(global, 'manage_options');
    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('user/notifications/all', 'POST', {page}, global.jwt).then(response => {
                setNotifications(response);
            })
        }

    }, []);

    const page_render = (p) => {
        setPage(p);
        request('user/notifications/all', 'POST', {p}, global.jwt).then(response => {
            setNotifications(response);
        })
    }
    let messages_table = {
        head: admin ? ['عنوان', 'تاریخ', 'عملیات'] : ['عنوان', 'تاریخ'],
        body: notifications.map((me, key) => {
            return (
                admin ?
                    <tr key={key}>
                        <th className='eng'>
                            <Link to={`/profile/notification/${me.id}`}>
                                {me.title}
                            </Link>
                        </th>
                        <th className='eng'>
                            <span>{me.date}</span>
                        </th>
                        <th className='eng'>
                            <a onClick={() => remove(me.id)}>حذف</a>
                        </th>
                    </tr>
                    :
                    <tr key={key}>
                        <th className='eng'>
                            <Link to={`/profile/notification/${me.id}`}>
                                {me.title}
                            </Link>
                        </th>
                        <th className='eng'>
                            <span>{me.date}</span>
                        </th>
                    </tr>
            )
        }),
        str: notifications.map(me => `${me.title} - ${me.date}`)
    }

    const send = () => {
        if (content.length && title.length) {
            request('user/notification/send/', 'POST', {title, content}, global.jwt).then(response => {
                setTitle('');
                e.setData('');
                setNotifications(response)
            })
        }
    }

    const remove = (id) => {
        request('user/notification/remove/', 'POST', {id}, global.jwt).then(response => {
            setNotifications(response)
        })
    }
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - اطلاعیه ها</title>
            </Helmet>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='notifications'/>
                    <div className="content-wrapper send-ticket">
                        {admin ?
                            <>
                                <div className="title-section">
                                    <i className="fal fa-envelope-open"/>
                                    <div>
                                        <h3>ارسال نوتیفیکشن</h3>
                                        <h4>در این قسمت سه دوره مشابه با دوره مورد نظر قرار داده شده است</h4>
                                    </div>
                                </div>
                                <form className='form-send-ticket'>
                                    <label>
                                        <span>عنوان نوتیفیکشن</span>
                                        <input onChange={event => setTitle(event.target.value)} value={title} type='text'/>
                                    </label>
                                    <div>
                                        <CKEditor
                                            config={{
                                                language: 'fa',
                                                placeholder: "متن نوتیفیکشن خود را تایپ کنید"
                                            }}
                                            onReady={editor => {
                                                e = editor;
                                            }}
                                            editor={ClassicEditor}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setContent(data)
                                            }}
                                        />
                                    </div>
                                    <a onClick={send} className='comment__write-btn js-comment-post-btn js-save'>ارسال نوتیفیکشن</a>
                                    <br/>
                                    <br/>
                                </form>
                                <br/>
                            </>
                            :
                            null

                        }
                        <div className="title-section">
                            <i className="fal fa-envelope-open"/>
                            <div>
                                <h3>لیست نوتیفیکشن</h3>
                                <h4>در این قسمت سه دوره مشابه با دوره مورد نظر قرار داده شده است</h4>
                            </div>
                        </div>
                        <form>
                            <br/>
                            <br/>
                            {
                                notifications.length ?
                                    <>
                                        <TableCustom body={messages_table.body} heads={messages_table.head} count={notifications.length} show={24} data={messages_table.str}/>
                                        <Pagination page={page} setPage={setPage} count={notifications.length ? notifications[0].page : 0} callback={(p) => {
                                            page_render(p)
                                        }}/>
                                    </>
                                    :
                                    <TableCustomLoader heads={messages_table.head}/>
                            }
                        </form>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Notifications;
