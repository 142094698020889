import React, {useState} from "react";
import {alert, request} from "../../functions/global";
import {Link} from "react-router-dom";

const Footer = () => {
    const [email, setEmail] = useState('');
    const add_email = () => {
        request('user/email/add/', 'POST', {email}).then(response => {
            alert(response.message, response.status);
        })
    }
    return (
        <>
            <svg width="0" height="0" className="position-absolute">
                <defs>
                    <clipPath id="most-popular-card-img-bg" clipPathUnits="objectBoundingBox">
                        <path d="M.06,0H.851C.933,0,1,.093,1,.208V.792C1,.907.933,1,.851,1H0Z"/>
                    </clipPath>
                    <clipPath id="entity-type-wrapper-mask-path" clipPathUnits="objectBoundingBox">
                        <path d="M.8,0H.234A.234.234,0,0,0,0,.234V.6A.252.252,0,0,0,.164.833l.459.15a.3.3,0,0,0,.407-.3V.234A.234.234,0,0,0,.8,0Z"/>
                    </clipPath>
                    <clipPath id="landing-sokan-plus-section-mask-path" clipPathUnits="objectBoundingBox">
                        <path d="M-2796-900.113V-901h1v.9c0,.031-.01.056-.023.056l-.931.047C-2795.979-900-2796-900.051-2796-900.113Z" transform="translate(-2795 -900.001) rotate(180)"/>
                    </clipPath>
                    <clipPath id="popular-categories-card-bg" clipPathUnits="objectBoundingBox">
                        <path d="M.071,0H.929A.07.07,0,0,1,1,.069V.931A.07.07,0,0,1,.929,1L.071.972A.07.07,0,0,1,0,.9V.069A.07.07,0,0,1,.071,0Z"/>
                    </clipPath>
                    <clipPath id="card-info-path-ltr" clipPathUnits="objectBoundingBox">
                        <path d="M.1,0H.9A.092.092,0,0,1,1,.086V.759A.183.183,0,0,1,.808.931L.1,1A.092.092,0,0,1,0,.914V.086A.092.092,0,0,1,.1,0Z"/>
                    </clipPath>
                    <clipPath id="card-info-path-rtl" clipPathUnits="objectBoundingBox">
                        <path d="M.9,0H.1A.092.092,0,0,0,0,.086V.759A.183.183,0,0,0,.192.931L.9,1A.092.092,0,0,0,1,.914V.086A.092.092,0,0,0,.9,0Z"/>
                    </clipPath>
                    <clipPath id="card-info-path-center" clipPathUnits="objectBoundingBox">
                        <path d="M.047,0H.953C.979,0,1,.039,1,.086V.844C1,.891.979.93.953.93L.5,1,.047.93C.021.93,0,.891,0,.844V.086C0,.039.021,0,.047,0Z"/>
                    </clipPath>
                </defs>
            </svg>
            <footer className="footer-main">
                <img className="footer-line" src="/images/footer.png" alt='footer top'/>
                <div className="footer-top">
                    <div className="r-1" style={{display: 'flex', alignItems: 'center'}}>
                        <div>
                            <img style={{width: '170px', filter: 'grayscale(1)'}} className="logo" src="/images/logo-amoozesh.png" alt='آموزش تیوب'/>
                            <p>
                                آموزش تیوب مجموعه متشکل از کارشناسان مجرب در حوزه های تخصصی برنامه نویسی و ترجمه ، همواره در تلاش است تا با ارائه بهترین و بروز ترین دوره های آموزشی رضایت شما کاربران عزیز را فراهم کنید
                            </p>
                            <p>شماره تماس : 01142207991</p>
                        </div>
                        <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=318539&amp;Code=lHvzanyrvdzBtVMerSiR">
                            <img src='/images/samandehi.png' alt='اینماد'/>
                        </a>
                    </div>
                    <div className="r-2">
                        <h3>
                            <i className="far fa-link"/>
                            دسترسی سریع
                        </h3>
                        <ul>
                            <li>
                                <Link to={'/'}>خانه</Link>
                            </li>
                            <li>
                                <Link to={'/teachers/'}>مدرسین</Link>
                            </li>
                            <li>
                                <Link to={'/search/'}>همه دوره ها</Link>
                            </li>
                            <li>
                                <Link to={'/search/article/'}>مقالات</Link>
                            </li>
                            <li>
                                <Link to={'/contact/'}>ارتباط با ما</Link>
                            </li>
                            <li>
                                <a>پرداخت</a>
                            </li>
                        </ul>
                    </div>
                    <div className="r-3">
                        <h3>
                            <i className="fal fa-envelope-open-text"/>
                            مطالب را در ایمیل خود داشته باشید!
                        </h3>
                        <p>خبرنامه آموزش تیوب با توجه به علاقه شما مقالات را برای شما را ارسال میکند</p>
                        <form>
                            <i className="far fa-envelope-open"/>
                            <input type="text" value={email} onChange={event => setEmail(event.target.value)} placeholder="ایمیل خود را وارد نمایید ..."/>
                            {email.length ?
                                <a onClick={add_email}>ثبت</a>
                                :
                                null
                            }
                        </form>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div>
                        <p>اين وبسايت متعلق به آموزش تیوب ميباشد و تمامی حقوق آن محفوظ ميباشد .</p>
                    </div>
                    <div>
                        <ul>
                            <li>
                                <a href="https://t.me/amoozesh_tube" className="d-flex align-items-center flex-row-reverse" target="_blank" rel="nofollow">
                                    <i className="icon-telegram"/>
                                </a>
                            </li>
                            <li>
                                <a href="" className="d-flex align-items-center flex-row-reverse" target="_blank" rel="nofollow">
                                    <i className="icon-whatsapp"/>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/amoozesh.tube/" className="d-flex align-items-center flex-row-reverse" target="_blank" rel="nofollow">
                                    <i className="icon-instagram"/>
                                </a>
                            </li>
                            <li>
                                <a href="" className="d-flex align-items-center flex-row-reverse active" target="_blank"
                                   rel="nofollow">
                                    <i className="icon-facebook"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;
