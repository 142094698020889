import React, {useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {globalContext} from "../../App";
import {numberToPersian, request} from "../../functions/global";
import Loading from "../misc/loading";

const Header = ({main = false}) => {
    const navigate = useNavigate();
    const {global, setGlobal} = useContext(globalContext);
    const [menu, setMenu] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [load, setLoad] = useState(false);

    const logOut = function () {
        document.cookie = 'jwt=;expires=Thu, 01 Jan 2020 00:00:01 GMT;path=/;';
        setGlobal({
            ...global,
            jwt: null,
            jwtDecode: null,
            carts: []
        });
        navigate('/');
    };
    const remove_cart = (postID) => {
        setLoad(true);
        request('user/cart/remove/', 'POST', {postID}, global.jwt).then(response => {
            setLoad(false);
            let cartsData = Array.isArray(response.carts) ? response.carts : Object.values(response.carts);
            let cartsID = Array.isArray(response.carts) ? response.carts : Object.keys(response.carts);
            if (!cartsID.length) {
                setGlobal({...global, carts: cartsID, cartsData, cartModal: false})
            } else {
                setGlobal({...global, carts: cartsID, cartsData})
            }
        })
    }

    let user_box = document.querySelector('.user-box');

    document.addEventListener('click', function (event) {
        if (typeof (user_box) != 'undefined' && user_box != null) {
            if (!user_box.contains(event.target) && menu) {
                setMenu(false)
            }
        }
    });

    return (
        <>
            {
                main ?
                    null
                    :
                    null
            }
            <Loading show={load}/>
            <div className={`modal-backdrop backdrop__low js-modal ${global.cartModal ? 'open' : ''}`} onClick={() => setGlobal({...global, cartModal: false})}/>
            <div className={`modal-backdrop backdrop__low js-modal ${menuOpen ? 'open' : ''}`} onClick={() => setMenuOpen(false)}/>
            <div className='cart-modal-wrapper'>
                <a className={`cart-fix-button ${global.cartModal ? 'open' : ''} ${!global.carts.length ? 'hide' : ''}`} onClick={() => setGlobal({...global, cartModal: !global.cartModal})}>
                    <span className="alert">
                        {global.carts.length ?
                            global.carts.length
                            :
                            null
                        }
                    </span>
                    <i className="fa-regular fa-bag-shopping animate__swing"/>
                </a>
                <div className={`cart-lists-fixed ${global.cartModal ? 'open' : ''} ${!global.carts.length ? 'hide' : ''}`}>
                    <div>
                        <h2>سبد خرید شما</h2>
                        <ul>
                            {global.cartsData.map((c, k) => {
                                return (
                                    <li key={k}>
                                        <div className='r-item-cart'>
                                            <img src={c.thumbnail_medium} alt={c.title}/>
                                            <div>
                                                <h3 onClick={() => setGlobal({...global, cartModal: false})}>
                                                    <Link to={`/course/${c.slug}`}>{c.title}</Link></h3>
                                                <span>{c.sale_price.toLocaleString()} تومان</span>
                                            </div>
                                        </div>
                                        <div className='l-item-cart'>
                                            <a onClick={() => remove_cart(c.ID)}>حذف</a>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div onClick={() => setGlobal({...global, cartModal: false})}>
                        <Link className='cart-btn-modal' to='/cart/'>سبد خرید</Link>
                        <Link className='continue-btn-modal' to={'/search/'}>ادامه خرید</Link>
                    </div>
                </div>
            </div>
            <header className={`top-header ${main ? '' : 'o'}`}>
                <div>
                    <Link to={`/`}>
                        <img className="logo" src={`/images/logo-${global.theme}.png`} alt='آموزش تیوب'/>
                    </Link>
                    <a className='nav-btn-mob' onClick={() => setMenuOpen(true)}>
                        <i className="fa-thin fa-bars"/>
                    </a>
                    <ul className={`nav-lists ${menuOpen ? 'active' : ''}`}>
                        <li>
                            <Link to={'/'}>خانه</Link>
                        </li>
                        <li className='has-sub-menu'>
                            <a>دوره ها</a>
                            <i style={{verticalAlign: 'middle'}} className="fal fa-angle-down"/>
                            <div className='sub-menu'>
                                <ul>
                                    {global.taxes.map((tax, key) => {
                                        return (
                                            <li key={key}>
                                                <Link to={`/category/${tax.slug}`}>{tax.name}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link to={`/search/`}>
                                <svg style={{height: '14px', verticalAlign: 'middle'}} data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-magnifying-glass">
                                    <path fill="currentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" className=""/>
                                </svg>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/search/article/'} state={{txt: '', type: 'article'}}>مقالات</Link>
                        </li>
                        <li>
                            <Link to={'/contact/'}>تماس با ما</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    {global.jwt ?
                        <>
                            <div className='user-box'>
                                <div onClick={() => setMenu(!menu)}>
                                    <img src={global.jwtDecode.data.avatar} alt='avatar'/>
                                    <i className="fal fa-angle-down"/>
                                </div>
                                {menu ?
                                    <ul className='menu-lists'>
                                        <div className="avatar">
                                            <div>
                                                <img src={global.jwtDecode ? global.jwtDecode.data.avatar : "/images/defaultuser.png"} alt='avatar'/>
                                                <div>
                                                    <div>{global.jwtDecode ? global.jwtDecode.data.display_name : null}</div>
                                                    <div>{numberToPersian(global.jwtDecode ? global.jwtDecode.data.phone : null)}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <li>
                                            <Link to={'/profile/dashboard/'}>
                                                <i className="fal fa-tachometer"/>
                                                <span>داشبورد</span>
                                            </Link>
                                        </li>
                                        <li className='flex-menu'>
                                            <Link to={'/cart/'}>
                                                <div>
                                                    <i className="fa-light fa-bag-shopping"/>
                                                    <span>سبد خرید</span>
                                                </div>
                                                {global.carts.length ?
                                                    <div className='cart-count'>
                                                        {global.carts.length}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/profile/courses/'}>
                                                <i className="fal fa-bags-shopping"/>
                                                <span>دوره های شما</span>
                                            </Link>
                                        </li>
                                        <li className='flex-menu'>
                                            <Link to={'/profile/tickets/'}>
                                                <div>
                                                    <i className="fal fa-envelope-open"/>
                                                    <span>تیکت ها</span>
                                                </div>
                                                {global.tickets ?
                                                    <div className='cart-count'>
                                                        {global.tickets}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </Link>
                                        </li>
                                        <li>
                                            <a onClick={logOut}>
                                                <i className="fal fa-sign-in"/>
                                                <span>خروج</span>
                                            </a>
                                        </li>
                                    </ul>
                                    :
                                    null
                                }
                            </div>
                        </>
                        :
                        <Link className="login" to='/login/'>
                            <div>
                                <i className="far fa-user-graduate"/>
                                <span className="txt" style={{marginRight: '3px', marginLeft: '5px'}}>
                                    ورود |
                                </span>
                                <span className="txt">
                                    ثــبت نام
                                </span>
                            </div>
                            <span className="effect"/>
                        </Link>
                    }

                </div>
            </header>
            <div/>
        </>

)
}
export default Header;
