import React from "react";
import {Helmet} from "react-helmet";
import Header from "../header/main";
import Footer from "../footer/main";

const Law = () => {
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - قوانین سایت</title>
            </Helmet>
            <Header/>
            <div className='page-container contact'>
                <div className='header'>
                    <h2>قوانین آموزش تیوب</h2>
                </div>
                <div className='content' style={{padding: '10px 32px', width: '70%'}}>
                    <h3>احراز هویت و قوانین استفاده از محصولات</h3>
                    <p>- ثبت‌نام در سایت آموزش تیوب و تکمیل فرم ثبت نام و عضویت که در آن اطلاعاتی نظیر نام و نام خانوادگی، شماره تماس و ایمیل دریافت می‌شود، به ‌منزله آگاهی و پذیرش کامل قوانین و مقررات مجموعه و سایت آموزش تیوب است و استفاده هر شخص از سایت آموزش تیوب به هر اندازه و با هر هدفی به این معنی است که او از قوانین و مقررات سایت آگاهی دارد و آن‌ها را به‌صورت کامل پذیرفته است.</p>
                    <p>- اشخاص برای عضویت در سایت باید از اطلاعات و مشخصات هویتی واقعی خود استفاده نمایند و سایت آموزش تیوب هیچ‌گونه مسئولیتی در ازای تخلف از این ماده ندارد.</p>
                    <p>- درصورتی‌که مشخص شود شخصی با نام و حساب کاربری جعلی اقدام به خرید محصولات و یا استفاده از خدمات مجموعه نموده، آموزش تیوب می‌تواند نسبت به حذف آن حساب کاربری اقدام نماید. در این حالت وجه پرداخت شده به کاربر برگشت داده نخواهد شد.</p>
                    <p>- اشخاص نباید حساب کاربری خود را، با هر هدفی در اختیار شخص یا اشخاص دیگری قرار دهند. درصورتی‌ که بر اثر استفاده هم‌زمان از حساب کاربری یا انتقال آن به دیگری، مشکلی ایجاد شود تمام مسئولیت‌ها بر عهده کاربر است و آموزش تیوب هیچ‌گونه مسئولیتی ندارد. همچنین درصورتی‌که معلوم شود چند نفر به‌صورت هم‌زمان از یک حساب کاربری و محصولات آموزشی استفاده می‌نمایند آموزش تیوب می‌تواند بنا به صلاح‌دید اقدامات قانونی لازم را نسبت به فرد و حساب کاربری مربوطه انجام دهد.</p>
                    <h3>پرداخت ها و تراکنش ها</h3>
                    <p>- درصورتی‌که هنگام استفاده از درگاه آنلاین پرداخت، تراکنش کاربر ناموفق باشد و وجهی از حساب او کسر گردد، مبلغ کسر شده به‌صورت خودکار از طرف بانک مربوطه طی مدت ۷۲ساعت بازگشت داده خواهد شد.</p>
                    <p>- درصورتی‌که کاربر در پرداخت اشتباهی انجام دهد مانند اینکه یک محصول آموزشی را بیش از یک‌بار به سبد خرید اضافه نماید یا محصول اشتباهی را خریداری کند، باید درخواست خود را مبنی بر بازگشت وجه اضافی و یا تعویض محصول حداکثر تا ۷۲ساعت آینده به آموزش تیوب اعلام کند. از زمان اعلام کاربر، فرایند مربوطه حداقل از ۷۲ ساعت بعد و فقط از طریق همان حساب کاربری که خرید با آن صورت گرفته انجام خواهد شد.</p>
                    <p>- اگر بر اثر اشتباه، قیمت محصولی کمتر یا بیشتر اعلام شود آموزش تیوب می‌تواند خریدهای قبلی را باطل کرده و درصورت موافقت خریدار ثبت سفارش را با قیمت اصلی انجام دهد.</p>
                    <h3>نظرات و محتوای مجرمانه</h3>
                    <p>- آموزش تیوب می‌تواند هر گونه دیدگاه و یا مطلبی که توسط کاربرانش منتشر شده و شامل محتوای مجرمانه یا بی احترامی است را حذف و یا ویرایش نماید. منظور از محتوای مجرمانه درج کامنت‌های توهین‌آمیز و نامناسب و… است که طبق قوانین جمهوری اسلامی ایران غیر قانونی محسوب می‌شود.</p>
                    <p>- آموزش تیوب می‌تواند در موارد تخلف کاربر مانند درج اطلاعات تماس شخصی، درج محتوای توهین آمیز و خلاف قانون و انتشار لینک نسبت به حذف یا تغییر دادن آن نظر اقدام کند.</p>
                    <p>- آموزش تیوب هیچ‌گونه مسئولیتی نسبت به محتوای منتشر و تبلیغ‌شده از سمت کاربران در بخش نظرات و یا سایر بخش‌ها ندارد و عواقب و مسئولیت‌ آن به‌طور کامل بر عهده کاربر خواهد بود.</p>
                    <h3>حق مالکیت فکری و محصولات</h3>
                    <p>- تمام محتوای منتشر شده در سایت و اکانت شبکه‌های اجتماعی آموزش تیوب مانند محتوای مقالات، محصولات آموزشی، لوگو، تصاویر، صوت‌ها، ویدیوها و غیره متعلق به آموزش تیوب است و بدون موافقت و اجازه آموزش تیوب، هیچ‌کس اجازه استفاده، کپی، بازآفرینی و اقداماتی مشابه آن را ندارد. پخش ویدئو ها و محصولات منتشر شده در آموزش تیوب در سایر شبکه های اجتماعی و سایت های دیگر از سوی هر فرد غیرمجازی تخلف و سرقت محسوب شده و آموزش تیوب حق پیگیری‌های قضایی و قانونی در این زمینه را دارد.</p>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Law;
