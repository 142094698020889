import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {globalContext} from "../../App";
import {useNavigate} from 'react-router-dom';
import {alert, request} from "../../functions/global";
import {setCookie} from "nookies";
import jwt_decode from "jwt-decode";
import Loading from "../misc/loading";
import {Helmet} from "react-helmet";
import Swal from "sweetalert2";

const Edit = () => {
    const {global, setGlobal} = useContext(globalContext);
    const navigate = useNavigate();
    let [show, setShow] = useState(false);
    const [showPass, setShowPass] = useState(null);
    let [data, setData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        description: '',
    });
    let [password, setPassword] = useState({
        old_password: '',
        new_password: '',
        re_new_password: '',
    });
    let [financial, setFinancial] = useState({
        shaba: '',
        cart: '',
        name: '',
        disable: false
    });
    let [avatar, setAvatar] = useState(global.jwtDecode ? global.jwtDecode.data.avatar : '');
    const imageSelect = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            setAvatar(reader.result);
            request('user/avatar/change/', 'POST', {
                base64: reader.result
            }, global.jwt).then(response => {
                if (response.jwt) {
                    setCookie(null, 'jwt', response.jwt.jwt, {
                        path: '/',
                        maxAge: 30 * 24 * 60 * 60

                    });
                    setGlobal({
                        ...global,
                        jwt: response.jwt.jwt,
                        jwtDecode: response.jwt.jwt ? jwt_decode(response.jwt.jwt) : {},
                    })
                }
            })
        };
    }

    const update = (e) => {
        e.preventDefault();
        setShow(true);
        request('user/edit/update/', 'post', {...data}, global.jwt).then(response => {
            setShow(false);
            alert(response.message, response.status ? 'success' : 'error')
        })
    }

    const updateFinancial = (e) => {
        e.preventDefault();
        if (financial.shaba.length === 24 && financial.cart.length === 16 && financial.name.length > 2) {
            Swal.fire({
                text: 'آیا از صحت اطلاعات ارسالی اطمینان دارید ؟',
                title: 'اطلاعات مالی',
                icon: 'info',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'بله ثبت شود',
                denyButtonText: `خیر`,
            }).then((result) => {
                if (result.isConfirmed) {
                    setShow(true);
                    request('user/edit/update/financial/', 'post', {...financial}, global.jwt).then(response => {
                        setShow(false);
                        if (response.status) {
                            setFinancial({...financial, disable: true});
                        }
                        alert(response.message, response.status ? 'success' : 'error');
                    })
                }
            })

        } else {
            alert('لطفا همه فیلد های بخش مالی را به صورت کامل تکمیل کنید', 'error');
        }
    }

    const changePass = (e) => {
        e.preventDefault();
        if (password.old_password.length > 5 && password.new_password === password.re_new_password && password.new_password.length > 5) {
            setShow(true);
            request('user/edit/password/', 'POST', {...password}, global.jwt).then(response => {
                setShow(false);
                if (response.status) {
                    alert(response.message, 'success')
                } else {
                    alert(response.message, 'error')
                }
            })
        } else {
            alert('لطفا تمام فیلد ها را پر کنید توجه کنید حداقل تعداد کارکتر پسورد ۶ حرف می باشد')
        }
    }

    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('user/edit/', 'POST', {}, global.jwt).then(response => {
                setData(response.user);
                setFinancial({...response.financial, disable: response.financial.cart.length === 16});
            })
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - ویرایش کاربری</title>
            </Helmet>
            <Loading show={show}/>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='edit'/>
                    <div className="content-wrapper">
                        <div className="title-section">
                            <i className="fal fa-user"/>
                            <div>
                                <h3>ویرایش پروفایل</h3>
                                <h4>در این بخش اطلاعات شخصی شما برای ویرایش قرار داده شده است</h4>
                            </div>
                        </div>
                        <div className='dashboard-page'>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h3>اطلاعات شخصی</h3>
                                <button onClick={event => update(event)} className='p-btn normal static slate coin-btn pull-left btn-open blue-btn'>ذخیره</button>
                            </div>
                            <form className='edit-form'>
                                <label className='avatar-wrapper'>
                                    <input type='file' name='avatar' id='avatar' onChange={event => imageSelect(event)}/>
                                    <span>ویرایش</span>
                                    <img src={avatar}/>
                                </label>
                                <div style={{width: 'calc(100% - 270px)'}}>
                                    <label>
                                        <span>نام</span>
                                        <div>
                                            <input type='text' defaultValue={data.first_name} name='first_name' onChange={event => setData({...data, first_name: event.target.value})}
                                                   placeholder='نام'/>
                                        </div>
                                    </label>
                                    <label>
                                        <span>نام خانوادگی</span>
                                        <div>
                                            <input type='text' defaultValue={data.last_name} name='last_name' onChange={event => setData({...data, last_name: event.target.value})}
                                                   placeholder='نام خانوادگی'/>
                                        </div>
                                    </label>
                                    <label>
                                        <span>شماره موبایل</span>
                                        <span style={{fontSize: '11px', color: '#1a1a1a96'}}>( قابل تعویض نمی باشد )</span>
                                        <div className='disable'>
                                            <input type='text' disabled={true} defaultValue={data.phone} name='phone'/>
                                        </div>
                                    </label>
                                    <label>
                                        <span>ایمیل</span>
                                        <span style={{fontSize: '11px', color: '#1a1a1a96'}}>( قابل تعویض نمی باشد )</span>
                                        <div className='disable'>
                                            <input type='text' disabled={true} defaultValue={data.email} name='email'/>
                                        </div>
                                    </label>
                                    <label>
                                        <span>درباه من</span>
                                        <div>
                                            <textarea name='about' defaultValue={data.description} onChange={event => setData({...data, description: event.target.value})} placeholder='درباره خودتان چند خط بنویسید'/>
                                        </div>
                                    </label>
                                </div>
                            </form>
                        </div>
                        <div className='dashboard-page'>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h3>رمز عبور</h3>
                                <button onClick={event => changePass(event)} className='p-btn normal static slate coin-btn pull-left btn-open blue-btn'>تغییر</button>
                            </div>
                            <form className='edit-financial change-pass'>
                                <label>
                                    <span>رمز عبور فعلی</span>
                                    <div>
                                        <input type={showPass === 1 ? 'text' : 'password'} placeholder='پسورد قبلی خود را وارد کنید' value={password.old_password} onChange={event => setPassword({...password, old_password: event.target.value})}/>
                                        <i onClick={() => {
                                            if (showPass === 1) {
                                                setShowPass(null)
                                            } else {
                                                setShowPass(1)
                                            }
                                        }} className={`fa-solid  ${showPass === 1 ? 'fa-sharp fa-eye-slash' : 'fa-eye'} `}/>
                                    </div>
                                </label>
                                <label>
                                    <span>رمز عبور جدید</span>
                                    <div>
                                        <input type={showPass === 2 ? 'text' : 'password'} placeholder='پسورد جدید خود را وارد کنید' value={password.new_password} onChange={event => setPassword({...password, new_password: event.target.value})}/>
                                        <i onClick={() => {
                                            if (showPass === 2) {
                                                setShowPass(null)
                                            } else {
                                                setShowPass(2)
                                            }
                                        }} className={`fa-solid  ${showPass === 2 ? 'fa-sharp fa-eye-slash' : 'fa-eye'} `}/>
                                    </div>
                                </label>
                                <label>
                                    <span>تکرار رمز عبور جدید</span>
                                    <div>
                                        <input type={showPass === 3 ? 'text' : 'password'} placeholder='پسورد جدید خود را تکرار کنید' value={password.re_new_password} onChange={event => setPassword({...password, re_new_password: event.target.value})}/>
                                        <i onClick={() => {
                                            if (showPass === 3) {
                                                setShowPass(null)
                                            } else {
                                                setShowPass(3)
                                            }
                                        }} className={`fa-solid  ${showPass === 3 ? 'fa-sharp fa-eye-slash' : 'fa-eye'} `}/>
                                    </div>
                                </label>
                            </form>
                        </div>
                        <div className='dashboard-page'>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <h3>اطلاعات مالی</h3>
                                <button onClick={event => updateFinancial(event)} className='p-btn normal static slate coin-btn pull-left btn-open blue-btn'>ذخیره</button>
                            </div>
                            <form className='edit-financial'>
                                <label>
                                    <span>نام صاحب کارت</span>
                                    <div className={financial.disable ? 'disable' : ''}>
                                        <input disabled={financial.disable} type='text' placeholder='نام صاحب کارت به صورت کامل وارد کنید' value={financial.name} onChange={event => setFinancial({...financial, name: event.target.value})}/>
                                    </div>
                                </label>
                                <label>
                                    <span>شماره کارت</span>
                                    <div className={financial.disable ? 'disable' : ''}>
                                        <input disabled={financial.disable} type='text' value={financial.cart} onChange={event => setFinancial({...financial, cart: event.target.value})}
                                               placeholder='شماره ۱۶ رقمی کارت'/>
                                    </div>
                                </label>
                                <label>
                                    <span>شماره شبا</span>
                                    <span style={{fontSize: '11px', color: '#1a1a1a96'}}>( بدون IR وارد کنید )</span>
                                    <div className={financial.disable ? 'disable' : ''}>
                                        <input disabled={financial.disable} type='text' value={financial.shaba} onChange={event => setFinancial({...financial, shaba: event.target.value.replace('ir', '')})}
                                               placeholder='شماره شبا بدون IR وارد کنید'/>
                                    </div>
                                </label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


            <Footer/>
        </>
    )
}

export default Edit;
