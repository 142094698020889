import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/fa';
import {numberToPersian, permission, request} from "../../functions/global";
import {globalContext} from "../../App";
import {useNavigate, useParams} from 'react-router-dom'
import Loading from "../misc/loading";
import {Helmet} from "react-helmet";
import TicketLoader from "../loading/profile/ticket";

let e = null;
const Ticket = () => {
    const {ticketID} = useParams();
    const navigate = useNavigate();
    const {global} = useContext(globalContext);
    const [content, setContent] = useState('');
    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState([]);
    const support = permission(global, 'support_ticket');

    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        }
        request('ticket/get/', 'POST', {ticketID}, global.jwt).then(response => {
            setData(response);
        })
    }, [])
    const send = () => {
        setErrors([]);
        e.setData('');
        let _errors = [];
        if (content.length < 10) {
            _errors.push('متن تیکت باید حداقل از 5 کلمه تشکیل شده باشد')
        }
        if (!_errors.length) {
            request('ticket/reply', 'POST', {ticketID, content}, global.jwt).then(response => {
                setData(response);
            });
        } else {
            setErrors(_errors);
        }
    }
    const closeTicket = () => {
        setShow(true);
        request('ticket/close', 'POST', {ticketID}, global.jwt).then(response => {
            setShow(false);
            setData(response);
        })
    }
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - تیکت</title>
            </Helmet>
            <Header/>
            <Loading show={show}/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='tickets'/>
                    {data ?
                        <div className="content-wrapper ticket-wrapper">
                            <div className='ticket-head'>
                                <div>
                                    <div>
                                        <img src='/images/defaultuser.png'/>
                                        <ul>
                                            <li>
                                                <i className="fal fa-user"/>
                                                <span>{data.name}</span>
                                            </li>
                                            <li>
                                                <i className="fal fa-eye"/>
                                                <span className='line'>شماره تیکت : {numberToPersian(ticketID)}</span>
                                                <span>{data.date_updated}</span>
                                            </li>
                                            {
                                                data.course ?
                                                    <li className='course'>
                                                        <span>دوره :</span>
                                                        <span>{data.course}</span>
                                                    </li>
                                                    :
                                                    null
                                            }
                                            <li>
                                                <h3>{data.title}</h3>
                                                <span className={`status${data.status}`}>{data.status_str}</span>
                                            </li>

                                        </ul>
                                    </div>
                                    {data.status != 2 ?
                                        <div>
                                            <a className='return-ticket' onClick={closeTicket}>
                                                بستن تیکت
                                            </a>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div className='ticket-body'>
                                {data.messages.map((message, key) => {
                                    return (
                                        <div key={key} className={`ticket ${message.support != '1' ? 'sender' : 'getter'}`}>
                                            <img src='/images/defaultuser.png'/>
                                            <div>
                                                <div className='p' dangerouslySetInnerHTML={{__html: message.content}}/>
                                                <div className="message__info">
                                                    <span>{message.name} {message.support == '1' ? '( پشتیبان آموزش تیوب )':''}</span>
                                                    <b className="fa-num">{message.date_created}</b>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <form className='ticket-form-reply'>
                                <h3>پاسخ تیکت</h3>
                                {errors.length ?
                                    <div className='errors'>
                                        {errors.map((error, key) => {
                                            return (
                                                <p key={key}>{error}</p>
                                            )
                                        })}
                                    </div>
                                    :
                                    null
                                }
                                <CKEditor
                                    config={{
                                        language: 'fa',
                                        placeholder: "متن تیکت خود را تایپ کنید",
                                        removePlugins: ["EasyImage", "ImageUpload", "MediaEmbed", "Table"]
                                    }}
                                    editor={ClassicEditor}
                                    onReady={editor => {
                                        e = editor;
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setContent(data)
                                    }}
                                />
                                <a className='comment__write-btn js-comment-post-btn js-save' onClick={send}>ارسال</a>
                                <a className='return-ticket mob' onClick={closeTicket} style={{float: 'left', marginTop: '13px', marginLeft: '12px'}}>
                                    بستن تیکت
                                </a>
                                <br/>
                                <br/>
                            </form>
                        </div>
                        :
                        <TicketLoader/>
                    }
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Ticket;
