import React, {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import useEmblaCarousel from "embla-carousel-react";
import {NextArrow, PrevArrow} from "../misc/arrow";
import {removeTags} from "../../functions/global";
import FreeLoader from "../loading/free";

const Free = ({data}) => {
    const [active, setActive] = useState([1, 2, 3, 4, 5]);
    const [viewportRef, embla] = useEmblaCarousel({
        slidesToScroll: 1,
        inViewThreshold: 0.5,
        skipSnaps: false,
        containScroll: "trimSnaps",
        direction: 'rtl',
        speed: 10,
        align: 'start'
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);
    const onScroll = useCallback(() => {
        if (!embla) return;
        let ar = embla.slidesInView();
        if (ar.length) {
            setActive(ar)
        }
    }, [embla]);
    useEffect(() => {
        if (!embla) return;
        onScroll();
        embla.on("select", onSelect);
        embla.on("scroll", onScroll);
        onSelect();
    }, [embla, onSelect, onScroll]);
    return (
        <section className="amt-article free">
            <div className="amt-article-wrapper">
                <div className="amt-article_textArea">
                    <div>
                        <header>
                            <div style={{marginTop: '15px'}}>
                                <span/>
                                <span>مقالات ویدیویی</span>
                            </div>
                            <div>
                                <h2 className="home-elements_items_block_component__title">
                                    مقالات آموزش تیوب
                                    <span className="home-elements_items_block_component__leftButton">
                                        <Link to={'/search/article/'} state={{txt: '', type: 'article'}} className="shared-cta_button_component__root shared-cta_button_component__fixedWidth shared-cta_button_component__large">
                                            همه مقالات
                                        </Link>
                                    </span>
                                </h2>
                                <p className="home-elements_items_block_component__subtitle">
                                    علم کامپیوتر و نرم افزار همواره با سرعت زیادی در حال پیشرفت و تغییر بوده ، از همین رو همیشه سعی کرده ایم با مطالعه منظم و یادگیری موارد جدید منتشر شده خود را بروز نگه داریم . امیدواریم بتوانیم جهت ارتقا خودمان و شما مقالات خوب و بروز ی را در این بخش منتشر نماییم.
                                </p>
                            </div>
                        </header>
                    </div>
                    <div className='arrows'>
                        <NextArrow onClick={scrollPrev} enabled={nextBtnEnabled}/>
                        <PrevArrow onClick={scrollNext} enabled={prevBtnEnabled}/>
                    </div>
                </div>
                <div className="home-elements_items_block_component__items">
                    {data.length ?
                        <ul className='content-lists'>
                            <div className="embla" ref={viewportRef}>
                                <div className="content-lists embla__container">
                                    {data.map((art, key) => {
                                        return (
                                            <div className={`embla__slide ${active.indexOf(key) !== -1 ? '' : 'in-visible'}`} key={key}>
                                                <div className="home-elements_items_block_component__item">
                                                    <div className="home-elements_style_item_card_component__root">
                                                        <div className="home-elements_style_item_card_component__preview">
                                                            <Link to={`/article/${art.slug}`} className="shared-item_preview_component__root">
                                                                <img src={art.thumbnail_small} alt={art.title}
                                                                     className="shared-item_preview_component__image"/>
                                                            </Link>
                                                            {art.cat.length ?
                                                                <span className="home-elements_style_item_card_component__itemType">{art.cat[0].name}</span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="home-elements_style_item_card_component__details">
                                                            <div className="home-elements_style_item_card_component__content">
                                                                <Link to={`/article/${art.slug}`} className="home-elements_style_item_card_component__name">
                                                                    {art.title}
                                                                </Link>
                                                                <p>
                                                                    {removeTags(art.content)}
                                                                </p>
                                                                <footer className="post__article-footer d-flex align-items-center">
                                                                    <div className="post__five-read d-flex align-items-center">
                                                                        <span className="post__five">{'type' in art && art.type.length && art.type[0].slug === 'text' ? art.min : art.time}</span>
                                                                        <div className="post__five-read-secnd d-flex flex-column">
                                                                            <span>دقیـقه {'type' in art && art.type.length && art.type[0].slug === 'text' ? 'مطالعه' : 'ویدیو'}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="post__fav d-flex align-items-center">
                                                                        <div className='footer-box' style={{flexDirection: 'row-reverse', padding: '0'}}>
                                                                            <div style={{alignItems: 'flex-end'}}>
                                                                                <span>{'type' in art && art.type.length && art.type[0].slug === 'text' ? 'مقاله' : 'ویدیو'}</span>
                                                                                <span>{art.date}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </footer>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </ul>
                        :
                        <FreeLoader/>
                    }
                </div>
            </div>
        </section>
    )
}
export default Free;
