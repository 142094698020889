import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {permission, request} from "../../functions/global";
import {globalContext} from "../../App";
import {Link, useNavigate} from "react-router-dom";
import TableCustom from "../misc/table";
import Loading from "../misc/loading";
import {Helmet} from "react-helmet";
import TableCustomLoader from "../loading/table";
import Pagination from "../misc/pagination";

const Tickets = () => {
    const {global} = useContext(globalContext)
    const [messages, setMessages] = useState(null);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [type, setType] = useState('all');
    const [page, setPage] = useState(1);
    const support = permission(global, 'support_ticket');
    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('ticket/lists', 'POST', {type, page}, global.jwt).then(response => {
                setMessages(response);
            })
        }
    }, [])

    let messages_table = {
        head: support ? ['عنوان', 'دپارتمان', 'کاربر', 'وضعیت', 'تاریخ', 'آرشیو'] : ['عنوان', 'دپارتمان', 'وضعیت', 'تاریخ']
    }

    if (messages !== null) {
        messages_table = {
            head: support ? ['عنوان', 'دپارتمان', 'کاربر', 'وضعیت', 'تاریخ', 'آرشیو'] : ['عنوان', 'دپارتمان', 'وضعیت', 'تاریخ'],
            body: messages.map((me, key) => {
                return (
                    <tr key={key} className={me.unread === '1' ? 'unread' : ''}>
                        <th className='eng'>
                            <Link className={me.unread === '1' ? 'unread' : ''} to={`/profile/ticket/${me.id}`}>
                                {me.title}
                            </Link>
                        </th>
                        <th className='eng'>
                            <span>{me.type}</span>
                        </th>
                        {
                            support ?
                                <th className='eng'>
                                    <span>{me.user}</span>
                                </th>
                                :
                                null
                        }
                        <th className='eng'>
                            <span className={`status${me.status}`}>{me.status_str}</span>
                        </th>
                        <th className='eng'>
                            {me.date_updated}
                        </th>
                        {
                            support ?
                                <th className='eng'>
                                    <a onClick={() => archive(me.id)}>آرشیو</a>
                                </th>
                                :
                                null
                        }
                    </tr>
                )
            }),
            str: messages.map(me => `${me.title} - ${me.status_str} - ${me.date_updated}`)
        }
    }

    const tickets_type = (type) => {
        setType(type)
        setShow(true)
        setPage(1);
        request('ticket/lists', 'POST', {type, page}, global.jwt).then(response => {
            setShow(false)
            setMessages(response);
        })
    }

    const archive = (ticketID) => {
        setShow(true);
        request('ticket/archive', 'POST', {type, page, ticketID}, global.jwt).then(response => {
            setShow(false)
            setMessages(response);
        })
    }

    const allArchive = () => {
        let con = window.confirm('آیا از آرشیو همگانی اطمینان دارید ؟');
        if (con) {
            setShow(true);
            request('ticket/archive/all/', 'POST', {type, page}, global.jwt).then(response => {
                setShow(false)
                setMessages(response);
            })
        }
    }

    const page_render = (p) => {
        setPage(p);
        setShow(true);
        request('ticket/lists', 'POST', {type, page: p}, global.jwt).then(response => {
            setShow(false)
            setMessages(response);
        })
    }
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - تیکت ها</title>
            </Helmet>
            <Loading show={show}/>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='tickets'/>
                    <div className="content-wrapper">
                        <div className="title-section">
                            <i className="fal fa-envelope-open"/>
                            <div>
                                <h3>تیکت های شما</h3>
                                <h4>در این بخش تیکت های ارسالی شما نمایش داده می شود</h4>
                            </div>
                        </div>
                        <div className='table-box'>
                            {support ?
                                <>
                                    <ul className='top-ticket-type'>
                                        <li className={type === 'all' ? 'active' : ''} onClick={() => tickets_type('all')}>
                                            همه تیکت ها
                                        </li>
                                        <li className={type == 1 ? 'active' : ''} onClick={() => tickets_type(1)}>
                                            پاسخ داده
                                        </li>
                                        <li className={type == 0 ? 'active' : ''} onClick={() => tickets_type(0)}>
                                            در حال بررسی
                                        </li>
                                        <li className={type == 2 ? 'active' : ''} onClick={() => tickets_type(2)}>
                                            بسته شده
                                        </li>
                                    </ul>
                                </>
                                :
                                null
                            }
                            <Link className='send-ticket' to={`/profile/ticket/send`}>
                                <i className="fa-regular fa-notes-medical"/>
                                ارسال تیکت
                            </Link>
                            {support ?
                                <a className='send-ticket' style={{marginRight: '10px'}} onClick={allArchive}>
                                    <i className="fa-regular fa-notes-medical"/>
                                    آرشیو همگانی
                                </a>
                                :
                                null
                            }

                            {
                                messages !== null ?
                                    messages.length ?
                                        <>
                                            <TableCustom body={messages_table.body} heads={messages_table.head} count={messages.length} show={24} data={messages_table.str}/>
                                            <Pagination page={page} setPage={setPage} count={messages.length ? messages[0].page : 1} callback={(p) => {
                                                page_render(p)
                                            }}/>
                                        </>
                                        :
                                        <p className='no-item-b'>
                                            <i className="fa-solid fa-comment-slash"/>
                                            تیکتی جهت نمایش یافت نشد
                                        </p>
                                    :
                                    <TableCustomLoader heads={messages_table.head}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Tickets;
