import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {numberToPersian, request} from "../../functions/global";
import {globalContext} from "../../App";
import {Link, useNavigate} from "react-router-dom";
import Loading from "../misc/loading";
import {Helmet} from "react-helmet";
import TableCustom from "../misc/table";
import TableCustomLoader from "../loading/table";
import Pagination from "../misc/pagination";
import Modal from "../misc/modal";

const Factor = () => {
    let messages_table = {
        head: ['شماره فاکتور', 'پرداختی', 'کسر از کیف پول', 'نوع', 'تاریخ', 'جزئیات']
    };
    const {global} = useContext(globalContext)
    const [data, setData] = useState(null);
    const [show, setShow] = useState(false);
    const [allPage, setAllPage] = useState(1);
    const [detail, setDetail] = useState('');
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('user/factors', 'POST', {page}, global.jwt).then(response => {
                setData(response.data);
                setAllPage(response.page);
            });
        }
    }, []);

    if (data !== null) {
        messages_table = {
            head: ['شماره فاکتور', 'پرداختی', 'کسر از کیف پول', 'نوع', 'تاریخ', 'جزئیات'],
            body: data.map((me, key) => {
                return (
                    <tr key={key}>
                        <th className='eng'>
                            <span>
                                {me.factor_id ? me.factor_id : 'وارد نشده است'}
                            </span>
                        </th>
                        <th className='eng'>
                            <span>{numberToPersian(Number(me.price).toLocaleString())}</span>
                        </th>
                        <th className='eng'>
                            <span>{numberToPersian(Number(me.wallet_price).toLocaleString())}</span>
                        </th>
                        <th className='eng'>
                            <span>{me.description ? me.description : 'خرید دوره'}</span>
                        </th>
                        <th className='eng'>
                            {me.date_updated}
                        </th>
                        <th className='eng'>
                            <a onClick={() => setDetail(me.factor_id)}>مشاهده</a>
                            <Modal show={detail === me.factor_id} setShow={setDetail}>
                                {me.pay_type != 2 ?
                                    <div className='cart-page factor-lists' style={{width: '100%', margin: '0', minWidth: '500px'}}>
                                        <div className='carts' style={{width: '100%'}}>
                                            <ul style={{boxShadow: 'none', padding: '13px 0', width: '100%'}}>
                                                {me.data.carts.map((cart, key) => {
                                                    return (
                                                        <li key={key}>
                                                            <div>
                                                                <div>
                                                                    <div className='cover'>
                                                                        <img src={cart.thumbnail}/>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div>
                                                                        <Link to={`/course/${cart.slug}`}>
                                                                            <h4>{cart.title}</h4>
                                                                        </Link>
                                                                        <div className='sub-header'>
                                                                            {numberToPersian(cart.price.toLocaleString())} تومان | <span>{numberToPersian(cart.course_count)} قسمت</span>
                                                                        </div>
                                                                    </div>
                                                                    <ul>
                                                                        <li>
                                                                            <div>
                                                                                دسته : {cart.cat[0].name}
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                <span className='price'>
                                                                    <span>{numberToPersian(cart.sale_price.toLocaleString())}</span>
                                                                    تومان
                                                                </span>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <p style={{fontWeight: 'bold', color: '#535353', fontSize: '14px'}}>جزئیاتی برای نمایش وجود ندارد</p>
                                    </div>
                                }
                            </Modal>
                        </th>
                    </tr>
                )
            }),
            str: data.map(me => `${me.factor_id} - ${me.price} - ${me.pay_type}`)
        }
    }

    const page_render = (p) => {
        setShow(true);
        setPage(p);
        request('user/factors', 'POST', {page: p}, global.jwt).then(response => {
            setShow(false);
            setData(response.data);
            setAllPage(response.page);
        })
    }

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - فاکتور ها</title>
            </Helmet>
            <Loading show={show}/>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='factors'/>
                    <div className="content-wrapper">
                        <div className="title-section">
                            <i className="fa-light fa-ticket"/>
                            <div>
                                <h3>فاکتور های شما</h3>
                                <h4>در این بخش فاکتورهای شما نمایش داده می شود</h4>
                            </div>
                        </div>
                        <div className='table-box'>
                            {
                                data !== null ?
                                    data.length ?
                                        <>
                                            <br/>

                                            <TableCustom body={messages_table.body} heads={messages_table.head} count={data.length} show={20} data={messages_table.str}/>
                                            <Pagination page={page} setPage={setPage} count={allPage} callback={(p) => {
                                                page_render(p)
                                            }}/>
                                        </>
                                        :
                                        <p className='no-item-b'>
                                            <i className="fa-solid fa-file-slash"/>
                                            فاکتوری جهت نمایش وجود ندارد
                                        </p>
                                    :
                                    <>
                                        <br/>
                                        <TableCustomLoader heads={messages_table.head}/>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Factor;
