import React, {useContext, useEffect, useState} from "react";
import {alert, numberToPersian} from '../../functions/global'
import {Link} from "react-router-dom";
import {globalContext} from "../../App";

const Curriculum = ({data}) => {
    const {global} = useContext(globalContext);
    const [slideTab, setSlideTab] = useState([0]);
    useEffect(() => {
    }, []);

    return (
        <>
            <div className="single-section course-curriculum">
                <div className="title-section">
                    <i className="fal fa-chart-pie"/>
                    <div>
                        <h3>
                            سـرفصل های دوره
                            {Array.isArray(data.condition) && data.condition.length ?
                                data.condition[0].slug === 'subtitle' ?
                                    <span className='subtitle'>دوره دارای زیرنویس فارسی اختصاصی می باشد</span>
                                    :
                                    <span className='double'>دوره دارای دوبله فارسی اختصاصی می باشد</span>
                                :
                                null
                            }
                        </h3>
                        <h4>در این بخش قسمت های دوره جهت مشاهده نمایش داده می شود</h4>
                    </div>
                </div>
                <span>این دوره شامل {numberToPersian(data.course_count)} قسمت - {numberToPersian(data.course_time_str)} می باشد</span>
                <ul className="course-curriculum-lists">
                    {data.course.length ?
                        data.course.map((d, k) => {
                            return (
                                <React.Fragment key={k}>
                                    {
                                        'count' in d ?
                                            <li className={slideTab.indexOf(k) != -1 ? 'active' : ''}>
                                                <div className="head" onClick={(event) => {
                                                    if (slideTab.indexOf(k) != -1) {
                                                        delete slideTab[slideTab.indexOf(k)];
                                                        setSlideTab([...slideTab]);
                                                    } else {
                                                        setSlideTab([...slideTab, k]);
                                                    }
                                                }}>
                                                    <div>
                                                        <i className="fal fa-angle-down"/>
                                                        <h4>{d.title}</h4>
                                                    </div>
                                                    <span>
                                                        {
                                                            Array.isArray(d.info) && d.info[0].file === '1' ?
                                                                <span>
                                                            {numberToPersian(d.count)} فایل
                                                            <i className="fi fi-rr-list-check"/>
                                                            </span>
                                                                :
                                                                <span>
                                                            {numberToPersian(d.count)} ویدیو
                                                            <i className="fi fi-rr-list-check"/>
                                                             </span>
                                                        }
                                                        {
                                                            Array.isArray(d.info) && d.info[0].file === '1' ?
                                                                <span>
                                                                </span>
                                                                :
                                                                <span>
                                                                    {numberToPersian(d.time)}
                                                                    <i className="fi fi-rs-time-check"/>
                                                                </span>
                                                        }

                                                    </span>
                                                </div>
                                                <ul>
                                                    {d.info.map((da, ke) => {
                                                        return (
                                                            <li key={ke}>
                                                                <div>
                                                                    <div>
                                                                        <span className='course-counter'>{ke + 1}</span>
                                                                        {global.jwt && (da.free || data.is_buy) ?
                                                                            <i className="fi fi-rr-play-alt"/>
                                                                            :
                                                                            <i className="fi fi-rr-lock"/>
                                                                        }
                                                                        <span>{da.title}</span>
                                                                        {da.free ?
                                                                            <span className='free'>رایگان</span> : null}
                                                                    </div>
                                                                    <span>
                                                                        {numberToPersian(da.duration)}
                                                                        <i className="fi fi-rs-time-check"/>
                                                                    </span>
                                                                </div>
                                                                <div className={global.jwt && (da.free || data.is_buy) ? 'free' : 'buy'}>
                                                                    {global.jwt && (da.free || data.is_buy) ?
                                                                        da.file === '1' ?
                                                                            <a href={da.path}>
                                                                                فایل
                                                                            </a>
                                                                            :
                                                                            <Link to={`/course/${data.slug}/video/${da.id}`}>
                                                                                مشاهده
                                                                            </Link>
                                                                        :
                                                                        <span onClick={() => global.jwt ? alert('برای مشاهده ابتدا دوره مورد نظر را خریداری کنید') : alert('برای مشاهده ابتدا وارد اکانت خود شوید')}>
                                                                            {da.file === '1' ? 'فایل' : 'مشاهده'}
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </li>
                                            :
                                            null
                                    }
                                </React.Fragment>
                            )
                        })
                        :
                        <li><p className='no-video'>
                            <i className="fas fa-video-slash"/>
                            ویدیویی جهت نمایش وجود ندارد
                        </p></li>
                    }

                </ul>
            </div>
        </>
    )
}
export default Curriculum;
