import React, {useContext, useEffect, useState} from "react";
import Header from "../header/main";
import Footer from "../footer/main";
import VideoJS from "../misc/videojs";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {alert, numberToPersian, request} from "../../functions/global";
import {globalContext} from "../../App";
import VideoLoader from "../loading/video";

const Video = () => {
    const navigate = useNavigate();
    const playerRef = React.useRef(null);
    const [slideTab, setSlideTab] = useState(null);
    const {slug, id} = useParams();
    const {global, setGlobal} = useContext(globalContext);
    const [data, setData] = useState({});
    const [videoJsOptions, setVideoJsOptions] = useState({
        html5: {
            hls: {
                enableLowInitialPlaylist: true,
                smoothQualityChange: true,
                overrideNative: true,
            },
        },
        autoplay: false,
        controls: true,
        responsive: false,
        fluid: true,
        liveui: true,
        playbackRates: [0.5, 1, 1.5, 2],
    });
    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('waiting', () => {
        });
        player.on('dispose', () => {
        });
    };

    useEffect(() => {
        if (!global.jwt) {
            navigate('/login/');
        } else {
            request('post/video/', 'POST', {slug, id}, global.jwt).then(response => {
                if (!('post' in response)) {
                    navigate(`/course/${slug}`);
                } else {
                    setData(response);
                    setVideoJsOptions({
                        ...videoJsOptions,
                        poster: response.post.thumbnail,
                        token: response.course.token,
                        sources: [{
                            src: response.course.hls,
                            type: "application/x-mpegURL",
                            withCredentials: true
                        }]
                    })
                }
            })
        }
    }, []);


    return (
        <>
            <Header/>
            {'course' in data ?
                <div className='course-video'>
                    <div>
                        <div className='video-header'>
                            <div>
                                <Link to={`/course/${data.post.slug}/`}>
                                    <i className="fal fa-long-arrow-right"/>
                                    برگشت به دوره
                                </Link>
                            </div>
                            <div>
                                {
                                    data.post.disable_download ?
                                        <p>دانلود ویدیو غیر فعال می باشد</p>
                                        :
                                        <a className='download-video' download={true} target='_blank' href={data.course.download_video}>
                                            <i className="fa-solid fa-download"/>
                                            دانلود ویدیو
                                        </a>
                                }
                            </div>
                        </div>
                        <div>
                            <VideoJS options={videoJsOptions} onReady={handlePlayerReady}/>
                        </div>
                    </div>
                    <div className='single'>
                        <div className="sidebar c-l">
                            <ul className='course-lists'>
                                {data.post.course.map((c, k) => {
                                    if (c.id == data.course.title_id && slideTab === null) {
                                        setSlideTab(k);
                                    }
                                    return (
                                        <li key={k} onClick={() => setSlideTab(k)} className={slideTab == k ? 'active' : ''}>
                                            <div>
                                                <span>{c.title}</span>
                                                <i className="fal fa-angle-down"/>
                                            </div>
                                            {slideTab == k ?
                                                <ul>
                                                    {c.info.map((da, ke) => {
                                                        return (
                                                            <li key={ke} className={da.id == data.course.id ? 'active' : ''}>
                                                                {data.post.is_buy || da.free ?
                                                                    da.file === '1' ?
                                                                        <a href={da.path}>
                                                                            <div><i className="fi fi-rr-play-alt"/>
                                                                            </div>
                                                                            <div>
                                                                                {da.title}
                                                                                <div style={{marginRight: 0}}>
                                                                                    فایل آموزشی {numberToPersian(da.duration)}
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        :
                                                                        <Link to={`/course/${data.post.slug}/video/${da.id}`}>
                                                                            <div><i className="fi fi-rr-play-alt"/>
                                                                            </div>
                                                                            <div>
                                                                                {da.title}
                                                                                <div style={{marginRight: 0}}>
                                                                                    ویدیوی آموزشی {numberToPersian(da.duration)}
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    :
                                                                    <a onClick={() => {
                                                                        alert('برای مشاهده ویدیو ابتدا باید دوره را خریداری کنید', 'warning')
                                                                    }}>
                                                                        <div><i className="fi fi-rr-lock"/></div>
                                                                        <div>
                                                                            {da.title}
                                                                            <div style={{marginRight: 0}}>
                                                                                ویدیوی آموزشی {numberToPersian(da.duration)}
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                }
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                :
                                                null
                                            }
                                        </li>

                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                :
                <VideoLoader/>
            }

            <Footer/>
        </>
    )
}
export default Video
