import React, {useContext, useEffect, useState} from "react";
import Header from "../header/main";
import Footer from "../footer/main";
import {globalContext} from "../../App";
import {alert, numberToPersian, request} from "../../functions/global";
import {Link} from "react-router-dom";
import Loading from "../misc/loading";
import {Helmet} from "react-helmet";

const Cart = () => {
    const {global, setGlobal} = useContext(globalContext);
    const [load, setLoad] = useState(false);
    const [data, setData] = useState({});
    const [discountCode, setDiscountCode] = useState('');

    useEffect(() => {
        setLoad(true);
        request('user/cart/', 'POST', {}, global.jwt).then(response => {
            setLoad(false);
            setDiscountCode(response.discount_code);
            let cartsData = Array.isArray(response.carts) ? response.carts : Object.values(response.carts);
            let cartsID = Array.isArray(response.carts) ? response.carts : Object.keys(response.carts);
            response.carts = cartsData;
            setData(response);
            setGlobal({...global, carts: cartsID, cartsData})
        })
    }, []);

    const remove_cart = (postID) => {
        setLoad(true);
        request('user/cart/remove/', 'POST', {postID}, global.jwt).then(response => {
            setLoad(false);
            let cartsData = Array.isArray(response.carts) ? response.carts : Object.values(response.carts);
            let cartsID = Array.isArray(response.carts) ? response.carts : Object.keys(response.carts);
            response.carts = cartsData;
            setData(response);
            setGlobal({...global, carts: cartsID, cartsData})
        })
    }

    const submit_code = () => {
        setLoad(true);
        request('user/cart/discount/add/', 'POST', {code: discountCode}, global.jwt).then(response => {
            setLoad(false);
            if (response.status) {
                let cartsData = Array.isArray(response.cart.carts) ? response.cart.carts : Object.values(response.cart.carts);
                let cartsID = Array.isArray(response.cart.carts) ? response.cart.carts : Object.keys(response.cart.carts);
                response.cart.carts = cartsData;
                setData(response.cart);
                setGlobal({...global, carts: cartsID, cartsData})
                alert(response.message, 'success');
            } else {
                alert(response.message);
            }
        })
    }

    const remove_code = () => {
        setLoad(true);
        request('user/cart/discount/remove/', 'POST', {code: discountCode}, global.jwt).then(response => {
            setLoad(false);
            setDiscountCode('');
            let cartsData = Array.isArray(response.carts) ? response.carts : Object.values(response.carts);
            let cartsID = Array.isArray(response.carts) ? response.carts : Object.keys(response.carts);
            response.carts = cartsData;
            setData(response);
            setGlobal({...global, carts: cartsID, cartsData})
            alert('کد تخفیف با موفقیت حذف شد', 'success');
        })
    }

    const buy = () => {
        setLoad(true);
        request('user/cart/buy/', 'POST', {}, global.jwt).then(response => {
            setLoad(false);
            if (response.status) {
                window.location.assign(response.url);
            } else {
                alert(response.message, 'warning')
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - سبد خرید</title>
            </Helmet>
            <Header/>
            <Loading show={load}/>
            <div className="cart-page">
                <h3>
                    سبد خرید شما
                    <span>( {numberToPersian('carts' in data ? data.carts.length : 0)} دوره )</span>
                </h3>
                <span>در این قسمت لیست محصولاتی که به کارت شما جهت خرید اضافه شده نمایش داده می شود</span>
                <div>
                    <div className='carts'>
                        <ul>
                            {'carts' in data && data.carts.length ?
                                data.carts.map((cart, key) => {
                                    return (
                                        <li key={key}>
                                            <div>
                                                <div>
                                                    <div className='cover'>
                                                        <img src={cart.thumbnail}/>
                                                    </div>
                                                    <div className='mb-action-cart' style={{width: '58%', margin: '9px auto 0'}}>
                                                        <span className='actions'>
                                                            <a onClick={() => remove_cart(cart.ID)}>
                                                                <i className="fas fa-trash"/>
                                                                حذف
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <Link to={`/course/${cart.slug}`}>
                                                            <h4>{cart.title}</h4>
                                                        </Link>
                                                        <div className='sub-header'>
                                                            {numberToPersian(cart.price.toLocaleString())} تومان | <span>{numberToPersian(cart.course_count)} قسمت</span>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li>
                                                            <div>
                                                                دسته : {cart.cat[0].name}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <span className='price'>
                                                    <span>{numberToPersian(cart.sale_price.toLocaleString())}</span>
                                                    تومان
                                                </span>
                                                <span className='actions'>
                                                    <a onClick={() => remove_cart(cart.ID)}>
                                                        <i className="fas fa-trash"/>
                                                        حذف
                                                    </a>
                                                </span>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <p className='no-cart'>
                                    <i className="fal fa-shopping-cart"/>
                                    محصولی در سبد خرید شما موجود نیست
                                </p>
                            }
                        </ul>
                    </div>
                    <div className='results'>
                        <ul>
                            <li>
                                <span>جمع سبد خرید</span>
                                <span>{numberToPersian('price' in data ? data.price.toLocaleString() : 0)} تومان</span>
                            </li>
                            <li>
                                <span>
                                    تخفیفات
                                    <i style={{marginRight: '4px', color: '#5b5b5b'}} className="fas fa-info-circle"/>
                                </span>
                                <span>{numberToPersian('discount' in data ? data.discount.toLocaleString() + '-' : 0)} تومان</span>
                            </li>
                            <label className='discount-code'>
                                <input type='text' disabled={discountCode.length && 'discount_code' in data && data.discount_code === discountCode} onChange={event => setDiscountCode(event.target.value)} value={discountCode} placeholder='کد تخفیف وارد کنید'/>
                                {'discount_percent' in data && data.discount_percent.length ?
                                    <span className='discount-percent'>{numberToPersian(data.discount_percent)} درصد</span> : null}
                                {discountCode ? (data.discount_code === discountCode ?
                                    <a onClick={remove_code} className='submit-code'>حذف</a> :
                                    <a onClick={submit_code} className='submit-code'>ثبت</a>) : null}
                                <span>مثال AMOOZESHD</span>
                            </label>
                            <li>
                                <span>
                                    کسر از کیف پول
                                </span>
                                <span>{numberToPersian('wallet' in data ? data.wallet.toLocaleString() + '-' : 0)} تومان</span>
                            </li>
                            <li className='line'/>
                            <li>
                                <span>مبلغ قابل پرداخت</span>
                                <span>{numberToPersian('sale_price' in data ? data.pay_price.toLocaleString() : 0)} تومان</span>
                            </li>
                        </ul>
                        <a onClick={buy} className='cart-btn'>پرداخت وجه</a>
                        <Link to={'/search/'} className='cart-btn-continue'>ادامه خرید</Link>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Cart;
