import React from "react";
import {Link} from "react-router-dom";

const Teacher = ({data}) => {
    return (
        <>
            {data.teacher &&'term_id' in data.teacher ?
                <div className="single-section description teacher">
                    <div className="title-section">
                        <i className="fa-light fa-head-side-medical"/>
                        <div>
                            <h3>مدرس دوره</h3>
                            <h4>در این بخش توضیحات مربوط به مدرس دوره نمایش داده می شود</h4>
                        </div>
                    </div>
                    <div className='teacher'>
                        <div>
                            <img src={data.teacher_thumbnail} alt={data.teacher.name}/>
                        </div>
                        <div>
                            <div className='desc'>
                                <h4>
                                    <Link to={`/teacher/${data.teacher.slug}`} state={{txt: '', type: 'course'}}>
                                        {data.teacher.name}
                                    </Link>
                                </h4>
                                {data.teacher.description}
                            </div>
                        </div>
                    </div>

                </div>
                :
                null
            }
        </>
    )
}
export default Teacher;
