import React from "react";
import ContentLoader from "react-content-loader";

const DescriptionLoader = () => {
    return (
        <div className="single-section description">
            <div className="title-section">
                <i className="fal fa-headphones-alt"/>
                <div>
                    <h3>تـوضیحات دوره</h3>
                    <h4>در این بخش توضیحات خلاصه دوره نوشته شده است</h4>
                </div>
            </div>
            <p style={{marginTop:'20px'}}>
                <ContentLoader
                    speed={2}
                    width='100%'
                    height={59}
                    backgroundColor="#f3f3f3"
                    foregroundColor="#cfcfcf">
                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="13"/>
                    <rect x="0" y="23" rx="5" ry="5" width="100%" height="13"/>
                    <rect x="0" y="46" rx="5" ry="5" width="100%" height="13"/>
                </ContentLoader>
            </p>
        </div>
    )
}
export default DescriptionLoader;
