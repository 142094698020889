import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Sidebar from "./sidebar";
import HeadSingle from "./head";
import Header from "../header/main";
import Share from "./share";
import Description from "./description";
import Curriculum from "./curriculum";
import Comment from "./comment";
import {useParams} from 'react-router-dom'
import {request} from "../../functions/global";
import {globalContext} from "../../App";
import {Helmet} from "react-helmet-async";
import SidebarLoader from "../loading/single/sidebar";
import HeadSingleLoader from "../loading/single/header";
import ShareLoader from "../loading/single/share";
import DescriptionLoader from "../loading/single/description";
import CurriculumLoader from "../loading/single/curriculum";
import CommentLoader from "../loading/single/comment";
import Teacher from "./teacher";
import TeacherLoader from "../loading/single/teacher";

const Course = () => {
    let google = '';
    const {global} = useContext(globalContext);
    const [data, setData] = useState(null);
    const {slug} = useParams();
    useEffect(() => {
        request('post/single/', 'POST', {slug}, global.jwt).then(response => {
            setData(response);
        })
    }, []);

    if (data) {
        google = JSON.stringify({
            "@context": "http://schema.org",
            "@graph": [
                {
                    "@type": "Organization",
                    "@id": "https://amoozesh.tube/#organization",
                    "name": "وبسایت آموزشی آموزش تیوب",
                    "url": "https://amoozesh.tube/",
                    "sameAs": [
                        "https://t.me/amoozesh_tube",
                        "https://www.instagram.com/amoozesh.tube/"
                    ],
                    "logo": {
                        "@type": "ImageObject",
                        "@id": "https://amoozesh.tube/#logo",
                        "url": "https://amoozesh.tube/images/logo-.png",
                        "width": 861,
                        "height": 269,
                        "caption": "آموزش تیوب"
                    },
                    "image": {
                        "@id": "https://amoozesh.tube/#logo"
                    }
                },
                {
                    "@type": "WebSite",
                    "@id": "https://amoozesh.tube#website",
                    "url": "https://amoozesh.tube",
                    "name": "وبسایت آموزشی آموزش تیوب",
                    "description": "آموزش تیوب وبسایت آموزش برنامه نویسی وب و موبایل ، جاوااسکریپت ، لاراول ، آموزش پیشرفته react native و reactjs ، آموزش node js ، آموزش پیشرفته node و انواع زبان های دیگر که همواره سعی کرده است بروزترین باشد",
                    "publisher": {
                        "@id": "https://amoozesh.tube/#organization"
                    },
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://amoozesh.tube/search/?txt={search_term_string}",
                        "query-input": "required name=search_term_string"
                    }
                }
                , {
                    "@type": "ImageObject",
                    "@id": "https://amoozesh.tube/course/" + data.slug + "#primaryimage",
                    "url": data.thumbnail_small,
                    "width": 480,
                    "height": 270
                }
                , {
                    "@type": "WebPage",
                    "@id": "https://amoozesh.tube/course/" + data.slug + "#webpage",
                    "url": "https://amoozesh.tube/course/" + data.slug,
                    "inLanguage": "fa-IR",
                    "name": data.title,
                    "isPartOf": {
                        "@id": "https://amoozesh.tube#website"
                    },
                    "primaryImageOfPage": {
                        "@id": "https://amoozesh.tube/course/" + data.slug + "#primaryimage"
                    },
                    "datePublished": 'post_date_en' in data ? data.post_date_en : data.post_date,
                    "dateModified": 'post_date_en' in data ? data.post_date_en : data.post_date,
                    "description": 'excerpt' in data ? data.excerpt : data.description,
                    "breadcrumb": {
                        "@id": "https://amoozesh.tube/course/" + data.slug + "#breadcrumb"
                    }
                }
                , {
                    "@type": "BreadcrumbList",
                    "@id": "https://amoozesh.tube/course/" + data.slug + "#breadcrumb",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": 1,
                            "item": {
                                "@type": "WebPage",
                                "@id": "https://amoozesh.tube",
                                "url": "https://amoozesh.tube",
                                "name": "صفحه‌  اصلی"
                            }
                        }, {
                            "@type": "ListItem",
                            "position": 2,
                            "item": {
                                "@type": "WebPage",
                                "@id": data && data.cat.length ? "https://amoozesh.tube/category/" + data.cat[0].slug : "https://amoozesh.tube/search/",
                                "url": data && data.cat.length ? "https://amoozesh.tube/category/" + data.cat[0].slug : "https://amoozesh.tube/search/",
                                "name": data && data.cat.length ? data.cat[0].name : "آموزش برنامه نویسی"
                            }
                        }, {
                            "@type": "ListItem",
                            "position": 3,
                            "item": {
                                "@type": "WebPage",
                                "@id": "https://amoozesh.tube/course/" + data.slug,
                                "url": "https://amoozesh.tube/course/" + data.slug,
                                "name": data.title
                            }
                        }]
                }
            ]
        })
    }


    return (
        <>
            <Helmet>
                <title>آموزش تیوب - {data ? data.title : 'دوره'}</title>
                <meta name="description" content={data && 'excerpt' in data ? data.excerpt : ''}/>
                <link rel="canonical" href={data ? "https://amoozesh.tube/course/" + data.slug : ''}/>
                <meta property="og:title" content={data ? data.title : 'آموزش تیوب'}/>
                <meta property="og:description" content={data && 'excerpt' in data ? data.excerpt : ''}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={data ? "https://amoozesh.tube/course/" + data.slug : ''}/>
                <meta property="og:site_name" content="وبسایت آموزشی آموزش تیوب"/>
                <meta property="og:brand" content="آموزش تیوب"/>
                <meta property="og:type" content="article"/>
                <meta property="og:locale" content="fa"/>
                <meta property="og:image" content={data ? data.thumbnail_small : ''}/>
                <script type="application/ld+json">{google}</script>
            </Helmet>
            <Header/>
            <div className="content">
                <section className="single">
                    {
                        data ?
                            <>
                                <Sidebar data={data} setData={setData}/>
                                <article className="content-wrapper">
                                    <HeadSingle data={data} setData={setData}/>
                                    <section className="body">
                                        <div className="inner">
                                            <Share data={data}/>
                                            <div>
                                                <Description data={data}/>
                                                <Teacher data={data}/>
                                                <Curriculum data={data}/>
                                                <Comment data={data}/>
                                            </div>
                                        </div>
                                    </section>
                                </article>
                            </>
                            :
                            <>
                                <SidebarLoader/>
                                <div className="content-wrapper">
                                    <HeadSingleLoader/>
                                    <section className="body">
                                        <div className="inner">
                                            <ShareLoader/>
                                            <div>
                                                <DescriptionLoader/>
                                                <TeacherLoader/>
                                                <CurriculumLoader/>
                                                <CommentLoader/>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </>
                    }
                </section>
            </div>
            <Footer/>
        </>
    )
}
export default Course;
