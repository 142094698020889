import React, {useEffect, useState} from "react";
import Header from "../header/main";
import Footer from "../footer/main";
import {Link} from 'react-router-dom';
import {request} from "../../functions/global";
import Loading from "../misc/loading";
import Pagination from "../misc/pagination";
import {Helmet} from "react-helmet";

const Teachers = () => {
    const [tax, setTax] = useState(null);
    const [searching, setSearching] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        request('post/teachers/', 'POST', {page: 1}, null).then(response => {
            setTax(response)
        });
    }, []);


    const search_page = (pg) => {
        setSearching(true);
        setPage(pg);
        request('post/teachers/', 'POST', {page: pg}, null).then(response => {
            setSearching(false);
            setTax(response);
        })
    }

    return (
        <>
            <Helmet>
                <title>آموزش تیوب - مدرسین</title>
                <meta name="description" content='مدرسین آموزش تیوب'/>
                <meta property="og:title" content='مدرسین'/>
                <meta property="og:description" content='مدرسین آموزش تیوب'/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="وبسایت آموزشی آموزش تیوب"/>
                <meta property="og:brand" content="آموزش تیوب"/>
                <meta property="og:locale" content="fa"/>
            </Helmet>
            <Loading show={searching}/>
            <Header/>
            <div className='page-head'>
                <div>
                    <h3>
                        آرشیو مدرسین
                    </h3>
                    <span>در این قسمت لیست مدرسین آموزش تیوب قرار دارد</span>
                </div>
            </div>
            <div className='content'>
            </div>
            <div className="archive teachers">
                <div className="content">
                    <div className="recommend search-lists">
                        <ul>
                            {
                                tax !== null ?
                                    tax.map((t, k) => {
                                        return (
                                            <li key={k}>
                                                <Link to={`/teacher/${t.slug}`}>
                                                    <img src={t.thumbnail} alt={t.name}/>
                                                </Link>
                                                <Link to={`/teacher/${t.slug}`}>{t.name}</Link>
                                            </li>
                                        )
                                    })
                                    :
                                    null
                            }
                        </ul>
                        {tax !== null ?
                            <Pagination page={page} count={tax[0].pages} callback={(page) => search_page(page)}/>
                            :
                            null
                        }

                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Teachers;
