import React, {useContext, useState} from "react";
import {mobile_validate, numberToEnglish, request} from "../../functions/global";
import {setCookie} from "nookies";
import jwt_decode from "jwt-decode";
import {globalContext} from "../../App";
import {Link, useNavigate} from "react-router-dom";
import Counter from "../misc/counter";
import ReCAPTCHA from "react-google-recaptcha";

const Login = ({tab, errors, setErrors, setShowLoading, options}) => {
    const {global, setGlobal} = useContext(globalContext);
    const navigate = useNavigate();
    const codeCaptcha = window.grecaptcha
    const passCaptcha = window.grecaptcha
    const [token, setToken] = useState('');
    const [phone, setPhone] = useState('');
    const [timer, setTimer] = useState(null);
    const [showPass, setShowPass] = useState(null);
    const [code, setCode] = useState('');
    const [googleCaptcha, setGoogleCaptcha] = useState('');
    const [password, setPassword] = useState('');
    const [loginType, setLoginType] = useState(null);

    const login = (e, type) => {
        e.preventDefault();
        setErrors([]);
        if (phone.length === 11 && mobile_validate(numberToEnglish(phone))) {
            setShowLoading(true);
            request('user/auth/phone/', 'POST', {phone: numberToEnglish(phone)}, null).then(response => {
                setShowLoading(false);
                if (response.status) {
                    setLoginType(type);
                    setTimer(response.time);
                } else {
                    setTimer(response.time);
                    setErrors([response.message]);
                }
            })
        } else {
            setErrors(['شماره موبایل ۱۱ رقمی را با دقت پر کنید'])
        }
    }

    const handleSocialLogin = (user) => {
        setToken(user._token.idToken);
        request('user/auth/google/', 'POST', {token: user._token.idToken}, null).then(response => {
            if (response.status) {
                if (typeof response == 'object' && "jwt" in response) {
                    setCookie(null, 'jwt', response.jwt, {
                        path: '/',
                        maxAge: 30 * 24 * 60 * 60
                    });
                    let carts = Array.isArray(response.carts) ? response.carts : Object.values(response.carts)
                    setGlobal({
                        ...global,
                        jwt: response.jwt,
                        jwtDecode: jwt_decode(response.jwt),
                        carts
                    });
                    navigate('/');
                } else {
                    setErrors(['تداخلی در برنامه بوجود آمده است دوباره تلاش کنید'])
                }
            } else {
                setErrors([response.message]);
            }
        })
    };

    const handleSocialVerifyCode = (e) => {
        e.preventDefault();
        setShowLoading(true);
        if (loginType === 1) {
            if (code.length === 5) {
                request('user/phone/verify/', 'POST', {code: numberToEnglish(code), phone: numberToEnglish(phone), token, googleCaptcha}, null).then(response => {
                    if (response.status) {
                        setShowLoading(false);
                        if (typeof response.data == 'object' && "jwt" in response.data) {
                            setCookie(null, 'jwt', response.data.jwt, {
                                path: '/',
                                maxAge: 30 * 24 * 60 * 60
                            });
                            let carts = Array.isArray(response.data.carts) ? response.data.carts : Object.values(response.data.carts)
                            setGlobal({
                                ...global,
                                jwt: response.data.jwt,
                                jwtDecode: jwt_decode(response.data.jwt),
                                carts
                            });
                            navigate('/');
                        } else {
                            setErrors(['تداخلی در برنامه بوجود آمده است دوباره تلاش کنید'])
                        }
                    } else {
                        codeCaptcha.reset();
                        setShowLoading(false);
                        setErrors([response.message]);
                    }
                })
            } else {
                setShowLoading(false);
                setErrors(['کد وارد شده اشتباه می باشد']);
            }
        } else {
            if (password.length) {
                request('user/login/with-pass/', 'POST', {password: numberToEnglish(password), phone: numberToEnglish(phone), googleCaptcha}, null).then(response => {
                    setShowLoading(false);
                    if (typeof response == 'object' && "jwt" in response) {
                        setCookie(null, 'jwt', response.jwt, {
                            path: '/',
                            maxAge: 30 * 24 * 60 * 60
                        });
                        let carts = Array.isArray(response.carts) ? response.carts : Object.values(response.carts)
                        setGlobal({
                            ...global,
                            jwt: response.jwt,
                            jwtDecode: jwt_decode(response.jwt),
                            carts
                        });
                        navigate('/');
                    } else {
                        passCaptcha.reset();
                        setErrors([typeof response == 'object' && 'error' in response ? response.error : 'رمز عبور وارد شده با شماره موبایل مطابقت ندارد']);
                    }
                });
            } else {
                setShowLoading(false);
                setErrors(['رمز عبور را وارد کنید']);
            }
        }
    }

    const handleSocialLoginFailure = (err) => {
    };

    const onChange = (value) => {
        setGoogleCaptcha(value);
    }

    return (
        <>
            <div className={`login-block-wrapper ${tab === 'login' ? 'active' : ''}`}>
                <div className='description'>
                    <p>سلام رفیق</p>
                    <span>به خانه خوش آمدی اگه عضو آموزش تیوب هستی وارد شو</span>
                </div>

                {'enable_login' in options
                    ?
                    options['enable_login'] ?
                        <>
                            <div className='fields'>
                                <label>
                                    {errors.map((error, key) => {
                                        return (
                                            <p className='error' key={key}>{error}</p>
                                        )
                                    })}
                                </label>
                                {loginType === null
                                    ?
                                    <label>
                                        شماره موبایل
                                        <input name='phone' type='text' value={phone} onChange={event => setPhone(event.target.value)} placeholder='موبایل را وارد کنید'/>
                                    </label>
                                    :
                                    loginType === 1 || loginType === 4 ?
                                        <>
                                            <label>
                                                کد تایید
                                                <input type='text' value={code} onChange={event => setCode(event.target.value)} placeholder='کد ۵ رقمی پیامکی را وارد کنید'/>
                                                {
                                                    loginType === 1 && timer !== null ?
                                                        <Counter classes='login-timer' callback={() => {
                                                            setLoginType(4)
                                                        }} sec={timer}/>
                                                        :
                                                        null
                                                }
                                            </label>
                                            <div className='google-captcha'>
                                                <ReCAPTCHA
                                                    grecaptcha={codeCaptcha}
                                                    sitekey="6Ldjbt4ZAAAAAHNB2Yg_SzDcKJBbIXOhRnoGry-Y"
                                                    onChange={onChange}
                                                    asyncScriptOnLoad={() => setShowLoading(false)}
                                                />
                                            </div>
                                        </>
                                        :
                                        <>
                                            <label>
                                                پسورد
                                                <input type={showPass === 1 ? 'text' : 'password'} value={password} onChange={event => setPassword(event.target.value)} placeholder='پسورد خود را وارد کنید'/>
                                                <i onClick={() => {
                                                    if (showPass === 1) {
                                                        setShowPass(null)
                                                    } else {
                                                        setShowPass(1)
                                                    }
                                                }} className={`fa-solid  ${showPass === 1 ? 'fa-sharp fa-eye-slash' : 'fa-eye'} `}/>
                                            </label>
                                            <div className='google-captcha'>
                                                <ReCAPTCHA
                                                    grecaptcha={passCaptcha}
                                                    sitekey="6Ldjbt4ZAAAAAHNB2Yg_SzDcKJBbIXOhRnoGry-Y"
                                                    onChange={onChange}
                                                    asyncScriptOnLoad={() => setShowLoading(false)}
                                                />
                                            </div>
                                        </>
                                }
                                <label>
                                    <p>
                                        با عضویت در سایت، تمام <Link to='/law/' style={{fontWeight: 'bold', color: '#000'}}>قوانین و شرایط</Link> استفاده از خدمات
                                        آموزش تیوب را پذیرفته اید.
                                    </p>
                                </label>
                                {loginType === null ?
                                    <div className='bottom-btn-login'>
                                        <a className='submit' onClick={event => {
                                            login(event, 1)
                                        }}>ورود با رمز یکبار مصرف</a>
                                        <a className='submit' onClick={() => {
                                            setErrors([]);
                                            if (phone.length === 11 && mobile_validate(numberToEnglish(phone))) {
                                                setShowLoading(true)
                                                setLoginType(2)
                                            } else {
                                                setErrors(['شماره موبایل ۱۱ رقمی را با دقت پر کنید'])
                                            }
                                        }}>ورود با پسورد</a>
                                    </div>
                                    :
                                    loginType === 4 ?
                                        <a className='submit' onClick={event => {
                                            login(event, 2)
                                        }}>ارسال دوباره رمز یکبار مصرف</a>
                                        :
                                        <div className='bottom-btn-login'>
                                            <a className='submit' onClick={event => handleSocialVerifyCode(event)}>ورود به سایت</a>
                                            <a className='submit' style={{background: '#757575'}} onClick={() => setLoginType(null)}>برگشت</a>
                                        </div>
                                }

                            </div>
                        </>
                        :
                        <p className='warning'>ورود به سایت توسط مدیریت غیرفعال شده است</p>
                    :
                    null
                }
            </div>
        </>
    )
}

export default Login;
