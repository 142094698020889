import React from "react";
import ContentLoader from "react-content-loader";

const CommentLoader = () => {
    return (
        <>
            <div>
                <div className="comments-wrap">
                    <section className="comment-section more">
                        <div className='comment-form'>
                            <form style={{width: '100%'}}>
                                <div>
                                    <span style={{display: 'flex'}}>
                                        <ContentLoader
                                            speed={2}
                                            width='40'
                                            height={33}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="33"/>
                                        </ContentLoader>
                                    </span>
                                    <textarea id="comment" placeholder="متن دیدگاه" name="comment"
                                              className="autogrow comment-body"/>
                                    <button type="submit" className="comment__write-btn js-comment-post-btn js-save">
                                        ارسال نظر
                                    </button>
                                </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}
export default CommentLoader;
