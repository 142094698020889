import React from "react";

const Loading = ({show, opacity = .96}) => {
    return (
        <div className={`loading ${show ? '' : 'hidden'}`} style={{opacity}}>
            <div>
                <div id="loading-indicator" className="yellow nm">
                    <div/>
                    <div/>
                    <div/>
                </div>
                <p>در حال دریافت اطلاعات</p>
                <p>لطفا کمی صبر کنید</p>
            </div>
        </div>
    )
}
export default Loading;
