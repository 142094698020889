import React, {useContext, useEffect, useState} from "react";
import Footer from "../footer/main";
import Header from "../header/main";
import Sidebar from "./sidebar";
import {globalContext} from "../../App";
import {useNavigate} from 'react-router-dom';
import {alert, numberToPersian, permission, request} from "../../functions/global";
import Modal from "../misc/modal";
import Loading from "../misc/loading";
import ContentLoader from "react-content-loader";
import TableCustom from "../misc/table";
import TableCustomLoader from "../loading/table";

const Request = () => {
    let request_table = {
        head: ['صاحب کارت', 'شماره کارت', 'تاریخ', 'وضعیت', 'مبلغ']
    };
    const {global} = useContext(globalContext);
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [lists, setLists] = useState(null);
    const [price, setPrice] = useState(50000);
    const [show, setShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    useEffect(() => {
        if (!global.jwt || !permission(global, 'wallet_request')) {
            navigate('/login/');
        } else {
            request('user/request/', 'POST', {}, global.jwt).then(response => {
                setData(response.wallet);
                setLists(response.requests);
            })
        }
    }, []);

    const add_request = () => {
        setError('')
        if (price >= 50000) {
            setShow(false);
            setLoad(true);
            request('user/request/add/', 'POST', {price}, global.jwt).then(response => {
                setLoad(false);
                setLists(response.lists);
                alert(response.message, response.status ? 'success' : 'warning', () => {
                }, 6000)
            })
        } else {
            setError('حداقل مبلغ انتخابی باید ۵۰,۰۰۰ تومان باشد');
        }
    }

    if (lists !== null) {
        request_table = {
            head: ['صاحب کارت', 'شماره کارت', 'تاریخ', 'وضعیت', 'مبلغ'],
            body: lists.map((me, key) => {
                return (
                    <tr key={key}>
                        <th className='eng'>
                            <span>
                                {me.card_name}
                            </span>
                        </th>
                        <th className='eng'>
                            <span>{numberToPersian(me.card_number)}</span>
                        </th>
                        <th className='eng'>
                            <span>{numberToPersian(me.date_send)}</span>
                        </th>
                        <th className='eng'>
                            <span>{me.state}</span>
                        </th>
                        <th className='eng'>
                            {numberToPersian(Number(me.price).toLocaleString())}
                        </th>
                    </tr>
                )
            }),
            str: lists.map(me => `${me.card_name} - ${me.card_number} - ${me.price} - ${me.date_send}`)
        }
    }

    return (
        <>
            <Loading show={load}/>
            <Modal show={show} setShow={setShow} classes='price-wrapper'>
                <p>برای تسویه اکانت یکی از مبالغ را انتخاب یا مبلغ اختیاری خود را وارد کنید</p>
                {error.length ? <p className='error'>{error}</p> : null}
                <div>
                    <div className='your-price'>
                        <p>مبلغ انتخابی شما</p>
                        <p className='p'>{Number(price).toLocaleString()}</p>
                    </div>
                </div>
                <ul className='price-choice'>
                    <li className={price === 50000 ? 'active' : ''} onClick={() => setPrice(50000)}>۵۰,۰۰۰ تومان</li>
                    <li className={price === 100000 ? 'active' : ''} onClick={() => setPrice(100000)}>۱۰۰,۰۰۰ تومان</li>
                    <li className={price === 200000 ? 'active' : ''} onClick={() => setPrice(200000)}>۲۰۰,۰۰۰ تومان</li>
                    <li className={[100000, 50000, 200000].indexOf(price) === -1 ? 'active' : ''} onClick={() => setPrice('')}>اختیاری</li>
                </ul>
                <div className={`price-input ${[100000, 50000, 200000].indexOf(price) === -1 ? 'active' : ''}`}>
                    <p>مبلغ انتخابی خود را وارد کنید</p>
                    <input type='number' value={price} onChange={event => setPrice(event.target.value)}/>
                </div>
                <a className='wallet-btn' onClick={add_request}>
                    تسویه
                </a>
                <div className='clearfix'/>
            </Modal>
            <Header/>
            <div className="profile">
                <div className="content">
                    <Sidebar active='request'/>
                    <div className="content-wrapper">
                        <div className="title-section">
                            <i className="far fa-wallet"/>
                            <div>
                                <h3>درخواست های شما</h3>
                                <h4>در این قسمت سه دوره مشابه با دوره مورد نظر قرار داده شده است</h4>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <div className='wallet-wrapper request-wallet'>
                                <div>
                                    <div className='box-wallet'>
                                        <p>موجودی کیف پول</p>
                                        <p>
                                            {data !== null ?
                                                data.toLocaleString()
                                                :
                                                <ContentLoader
                                                    speed={2}
                                                    width='100%'
                                                    height={30}
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#cfcfcf">
                                                    <rect x="30%" y="0" rx="5" ry="5" width="40%" height="30"/>
                                                </ContentLoader>
                                            }
                                        </p>
                                    </div>
                                </div>
                                <ul className='wallet-info'>
                                    <li>
                                        مبلغ کیف پول به تومان می باشد
                                    </li>
                                    <li>
                                        حداقل درخواست تسویه ۵۰ هزار تومان می باشد
                                    </li>
                                </ul>
                                <a className='wallet-btn' onClick={() => setShow(true)}>
                                    درخواست تسویه
                                </a>
                            </div>
                            <div className='wallet-wrapper request-wrapper'>
                                {
                                    lists !== null ?
                                        lists.length ?
                                            <>
                                                <br/>
                                                <TableCustom body={request_table.body} heads={request_table.head} count={lists.length} show={20} data={request_table.str}/>
                                            </>
                                            :
                                            <p className='no-item-b'>
                                                <i className="fa-solid fa-file-slash"/>
                                                تسویه ای جهت نمایش وجود ندارد
                                            </p>
                                        :
                                        <>
                                            <br/>
                                            <TableCustomLoader heads={request_table.head}/>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default Request;
