import React from "react";
import {Link} from "react-router-dom";
import ContentLoader from "react-content-loader";

const ArchiveLoader = () => {
    return (
        <ul className="content-lists">
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div>
                    <div className="cover">
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={125}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                            </ContentLoader>
                        </a>
                    </div>
                    <h3>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={10}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                            </ContentLoader>
                        </a>
                    </h3>
                    <p>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                            <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                        </ContentLoader>
                    </p>
                    <div className="ft">
                        <div style={{textAlign: 'right'}} className="flex items-center">
                            <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={10}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                        <div style={{textAlign: 'left'}} className="flex items-center">
                            <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={20}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    )
}

export default ArchiveLoader;
