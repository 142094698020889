import React from "react";

const Tabs = ({tab, setTab, setErrors}) => {
    return (
        <ul className='tabs'>
            <li className={tab === 'login' ? 'active' : ''}>
                <a onClick={() => {
                    setTab('login')
                    setErrors([]);
                }}>
                    <i className="fa-duotone fa-arrow-right-to-arc"/>
                    ورود
                </a>
            </li>
            <li className={tab === 'register' ? 'active' : ''}>
                <a onClick={() => {
                    setTab('register')
                    setErrors([])
                }}>
                    <i className="fa-duotone fa-user-plus"/>
                    ثـبت نـام
                </a>
            </li>
        </ul>
    )
}

export default Tabs;
