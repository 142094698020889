export const NextArrow = (props) => {
    const {className, style, onClick} = props;
    return (
        <a className="nav-btn js-nav-btn next dark" onClick={onClick}>
            <i className="fal fa-angle-right"/>
        </a>
    );
}

export const PrevArrow = (props) => {
    const {className, style, onClick} = props;
    return (
        <a className="nav-btn js-nav-btn prev dark" onClick={onClick}>
            <i className="fal fa-angle-left"/>
        </a>
    );
}
