import React, {useCallback, useEffect, useState} from "react";
import ContentLoader from 'react-content-loader'
import useEmblaCarousel from "embla-carousel-react";

const OfferLoaders = () => {
    const [active, setActive] = useState([1, 2, 3, 4]);
    const [viewportRef, embla] = useEmblaCarousel({
        slidesToScroll: 1,
        inViewThreshold: 0.5,
        skipSnaps: false,
        containScroll: "trimSnaps",
        direction: 'rtl',
        speed: 10,
        align: 'start'
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);
    const onScroll = useCallback(() => {
        if (!embla) return;
        let ar = embla.slidesInView();
        if (ar.length) {
            setActive(ar)
        }
    }, [embla]);
    useEffect(() => {
        if (!embla) return;
        onScroll();
        embla.on("select", onSelect);
        embla.on("scroll", onScroll);
        onSelect();
    }, [embla, onSelect, onScroll]);
    return (
        <ul className='content-lists'>
            <div className="embla" ref={viewportRef}>
                <div className="content-lists embla__container">
                    <li className={`embla__slide ${active.indexOf(0) !== -1 ? '' : 'in-visible'}`}>
                        <div className="cover">
                            <a>
                                <ContentLoader
                                    speed={2}
                                    width='100%'
                                    height={125}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                                </ContentLoader>
                            </a>
                        </div>
                        <h3>
                            <a>
                                <ContentLoader
                                    speed={2}
                                    width='100%'
                                    height={10}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                </ContentLoader>
                            </a>
                        </h3>
                        <p style={{marginBottom: 0}}>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={35}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                            </ContentLoader>
                        </p>
                        <div className="ft">
                            <div className="flex items-center time" style={{textAlign: 'right'}}>
                                <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                    <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                        <ContentLoader
                                            speed={2}
                                            width='50%'
                                            height={10}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                        </ContentLoader>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center price-wrapper" style={{textAlign: 'left'}}>
                                <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                    <del>
                                        <ContentLoader
                                            speed={2}
                                            width='40%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </del>
                                    <div>
                                        <ContentLoader
                                            speed={2}
                                            width='40%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li className={`embla__slide ${active.indexOf(1) !== -1 ? '' : 'in-visible'}`}>
                        <div className="cover">
                            <a>
                                <ContentLoader
                                    speed={2}
                                    width='100%'
                                    height={125}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                                </ContentLoader>
                            </a>
                        </div>
                        <h3>
                            <a>
                                <ContentLoader
                                    speed={2}
                                    width='100%'
                                    height={10}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                </ContentLoader>
                            </a>
                        </h3>
                        <p style={{marginBottom: 0}}>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={35}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                            </ContentLoader>
                        </p>
                        <div className="ft">
                            <div className="flex items-center time" style={{textAlign: 'right'}}>
                                <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                    <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                        <ContentLoader
                                            speed={2}
                                            width='50%'
                                            height={10}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                        </ContentLoader>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center price-wrapper" style={{textAlign: 'left'}}>
                                <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                    <del>
                                        <ContentLoader
                                            speed={2}
                                            width='40%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </del>
                                    <div>
                                        <ContentLoader
                                            speed={2}
                                            width='40%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </li>
                    <li className={`embla__slide ${active.indexOf(2) !== -1 ? '' : 'in-visible'}`}>
                        <div className="cover">
                            <a>
                                <ContentLoader
                                    speed={2}
                                    width='100%'
                                    height={125}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="21" ry="21" width="100%" height="125"/>
                                </ContentLoader>
                            </a>
                        </div>
                        <h3>
                            <a>
                                <ContentLoader
                                    speed={2}
                                    width='100%'
                                    height={10}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="25%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                </ContentLoader>
                            </a>
                        </h3>
                        <p style={{marginBottom: 0}}>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={35}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="5%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                <rect x="5%" y="20" rx="5" ry="5" width="90%" height="10"/>
                            </ContentLoader>
                        </p>
                        <div className="ft">
                            <div className="flex items-center time" style={{textAlign: 'right'}}>
                                <div className="flex items-center bg-gray-100 dark:bg-gray-960 px-2 h-6 rounded ml-2">
                                    <span className="text-gray-500 dark:text-gray-950 font-normal text-xs">
                                        <ContentLoader
                                            speed={2}
                                            width='50%'
                                            height={10}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                        </ContentLoader>
                                    </span>
                                </div>
                            </div>
                            <div className="flex items-center price-wrapper" style={{textAlign: 'left'}}>
                                <span className="text-biscay-700 dark:text-white font-bold lg:text-3xl text-xl price">
                                    <del>
                                        <ContentLoader
                                            speed={2}
                                            width='40%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </del>
                                    <div>
                                        <ContentLoader
                                            speed={2}
                                            width='40%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </li>
                </div>
            </div>
        </ul>
    )
}

export default OfferLoaders;
