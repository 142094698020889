import React from "react";
import ContentLoader from "react-content-loader";

const CurriculumLoader = () => {
    return (
        <>
            <div className="single-section course-curriculum">
                <div className="title-section">
                    <i className="fal fa-chart-pie"/>
                    <div>
                        <h3>
                            سـرفصل های دوره
                        </h3>
                        <h4>در این قسمت سه دوره مشابه با دوره مورد نظر قرار داده شده است</h4>
                    </div>
                </div>
                <span style={{textAlign: 'left'}}>
                    <ContentLoader
                        speed={2}
                        width='80%'
                        height={10}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#cfcfcf">
                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="10"/>
                    </ContentLoader>
                </span>
                <ul className="course-curriculum-lists" style={{paddingTop: '5px'}}>
                    <React.Fragment>
                        <li>
                            <div className="head">
                                <div>
                                    <h4>
                                        <ContentLoader
                                            speed={2}
                                            width='70%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </h4>
                                </div>
                                <span style={{textAlign: 'left'}}>
                                    <span style={{width: '15%'}}>
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                    <span style={{width: '15%'}}>
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                </span>
                            </div>
                            <ul>

                            </ul>
                        </li>
                        <li>
                            <div className="head">
                                <div>
                                    <h4>
                                        <ContentLoader
                                            speed={2}
                                            width='70%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </h4>
                                </div>
                                <span style={{textAlign: 'left'}}>
                                    <span style={{width: '15%'}}>
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                    <span style={{width: '15%'}}>
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                </span>
                            </div>
                            <ul>

                            </ul>
                        </li>
                        <li>
                            <div className="head">
                                <div>
                                    <h4>
                                        <ContentLoader
                                            speed={2}
                                            width='70%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </h4>
                                </div>
                                <span style={{textAlign: 'left'}}>
                                    <span style={{width: '15%'}}>
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                    <span style={{width: '15%'}}>
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                </span>
                            </div>
                            <ul>

                            </ul>
                        </li>
                    </React.Fragment>
                </ul>
            </div>
        </>
    )
}
export default CurriculumLoader;
