import React from "react";
import ContentLoader from "react-content-loader";

const TeacherLoader = () => {
    return (
        <div className="single-section description">
            <div className="title-section">
                <i className="fa-light fa-head-side-medical"/>
                <div>
                    <h3>مدرس دوره</h3>
                    <h4>در این بخش توضیحات خلاصه دوره نوشته شده است</h4>
                </div>
            </div>
            <div className='teacher'>
                <div>
                    <ContentLoader
                        speed={2}
                        width='100'
                        height={100}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#cfcfcf">
                        <rect x="0" y="0" rx="100" ry="100" width="100" height="100"/>
                    </ContentLoader>
                </div>
                <div style={{width: 'calc(100% - 110px)', marginRight: '13px'}}>
                    <p style={{width: '100%', textAlign: 'left'}}>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={84}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="13"/>
                            <rect x="0" y="23" rx="5" ry="5" width="100%" height="13"/>
                            <rect x="0" y="46" rx="5" ry="5" width="100%" height="13"/>
                            <rect x="0" y="69" rx="5" ry="5" width="100%" height="13"/>
                        </ContentLoader>
                    </p>
                </div>
            </div>

        </div>
    )
}
export default TeacherLoader;
