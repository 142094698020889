import React from "react";
import {Helmet} from "react-helmet";
import Header from "../header/main";
import Footer from "../footer/main";
import animation from "../../public/404.json";
import Lottie from "react-lottie-player";

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>آموزش تیوب - 404</title>
                <meta name="description" content='صفحه مورد نظر یافت نشد'/>
            </Helmet>
            <Header/>
            <div>
                <Lottie
                    loop animationData={animation} play={true} background="transparent" style={{height: '255px', marginTop: '100px'}}
                />
                <p style={{textAlign: 'center', fontFamily: 'IRANYEKANX', fontWeight: '1000', fontSize: '20px', color: '#545454'}}>صفحه مورد نظر شما یافت نشد</p>
                <p style={{textAlign: 'center', fontFamily: 'IRANYEKANX', fontWeight: '300', fontSize: '13px', color: '#545454'}}>در صورت اطمینان از صحت آدرس صفحه مورد نظر از طریق پشتیبانی این مشکل را اطلاع دهید</p>
            </div>
            <Footer/>
        </>


    )
}

export default NotFound;
