import React, {useState} from "react";
import animation from '../../public/D4hPcbbAJo.json'
import Lottie from 'react-lottie-player'
import {Link, useNavigate} from "react-router-dom";

const Search = ({data}) => {
    const navigate = useNavigate();
    const [txt, setTxt] = useState('');
    const [term, setTerm] = useState(null);
    const [searchBTN, setSearchBTN] = useState(false);
    const searchRequest = () => {
        navigate('/search/', {state: {txt: txt, term}})
    }
    return (
        <>
            <section className="search">
                <div className='right-side'>
                    <div>

                        <h2>
                            <span>{`</>`} آمـوزش تیــوب</span> مرجع تخصصی دوره های برنامه نویسی
                        </h2>
                        <h3>
                            یادگیری برنامه‌نویسی آرزو نیست، فقط نیاز هست، تلاش و تمرین داشته باشید، بقیه‌اش با آموزش تیوب
                            از همین امروز برنامه نویس بشو
                        </h3>
                    </div>
                    <div>
                        <Link to='/search/' className='all-course' style={{color: '#fff'}}>همه دوره ها</Link>
                        <Link to='/tops/' className='all-course' style={{background: 'transparent', border: '1px solid'}}>محبوب ترین ها</Link>
                    </div>
                    <section className="search search-box">
                        <form action='/search/' method='get'>
                            <label>
                                <i className={`fi fi-br-search ${searchBTN ? 'hidden' : ''}`}/>
                                <input type="text" name='txt' onChange={event => {
                                    setTxt(event.target.value);
                                    setSearchBTN(event.target.value.length > 0);
                                }} placeholder="جستجــــوی دوره مورد نظر ..."/>
                            </label>
                            <select name='cat' onChange={event => setTerm(event.target.value)}>
                                <option value={''}>همه دسته ها</option>
                                {'cat' in data ?
                                    data.cat.map((term, key) => {
                                        return (
                                            <option key={key} value={term.term_id}>{term.name}</option>
                                        )
                                    }) : null
                                }
                            </select>
                            <a className={`search-sub ${searchBTN ? '' : 'hidden'}`} style={{lineHeight: '2'}} onClick={searchRequest}><i className="fi fi-br-search"/></a>
                        </form>
                        <div className="search-ex">
                            مثل <a>پایتون</a> , <a>هوش مصنوعی</a> , <a>جاوا اسکریپت</a> یا <a>دوره electronjs</a>
                        </div>
                    </section>
                </div>
                <div className="relative character ">
                    <div className='back-character'/>
                    <Lottie
                        loop animationData={animation} play={true} background="transparent" style={{width: '400px', height: '440px', marginLeft: '-35px'}}
                    />
                </div>
                <div className='b3-head'>
                    <section className="search search-box">
                        <h2>نام دوره مورد نظرت را بنویس . . .</h2>
                        <p>
                            با کلیک روی
                            <i className="fa-duotone fa-magnifying-glass"/>
                            جستجو کن
                        </p>
                        <form action='/search/' method='get'>
                            <label>
                                <i className={`fi fi-br-search ${searchBTN ? 'hidden' : ''}`}/>
                                <input type="text" name='txt' onChange={event => {
                                    setTxt(event.target.value);
                                    setSearchBTN(event.target.value.length > 0);
                                }} placeholder="جستجــــوی دوره مورد نظر ..."/>
                            </label>
                            <select name='cat' onChange={event => setTerm(event.target.value)}>
                                <option value={''}>همه دسته ها</option>
                                {'cat' in data ?
                                    data.cat.map((term, key) => {
                                        return (
                                            <option key={key} value={term.term_id}>{term.name}</option>
                                        )
                                    }) : null
                                }
                            </select>
                            <a style={{lineHeight: 2}} className={`search-sub ${searchBTN ? '' : 'hidden'}`} onClick={searchRequest}><i className="fi fi-br-search"/></a>
                        </form>
                        <div className="search-ex">
                            مثل <a>پایتون</a> , <a>هوش مصنوعی</a> , <a>جاوا اسکریپت</a> یا <a>دوره electronjs</a>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}

export default Search;
