import React from "react";
import {Link} from "react-router-dom";
import {numberToPersian} from "../../functions/global";
import Share from "./share";

const Extra = ({data}) => {
    return (
        <div className='extra-info'>

            <div className="single-section extra">
                <div className='header'>
                    <div className="title-section">
                        <div>
                            <h3>اطلاعات دوره</h3>
                            <h4>در این بخش اطلاعات دوره نوشته شده است</h4>
                        </div>
                    </div>
                    <Share data={data} />
                </div>
                <div className="other-info">
                    <ul>
                        <li>
                            <div>
                                <i className="fi fi-rr-list"/>
                                <span>دسته بندی</span>
                            </div>
                            <Link style={{fontSize: '12px', color: '#000'}} to={'/search/'} state={{term: data.cat[0].term_id, txt: ''}}>{data.cat[0].name}</Link>
                        </li>
                        <li style={{marginBottom: '8px'}}>
                            <div>
                                <i style={{verticalAlign: 'middle'}} className="fa-regular fa-closed-captioning"/>
                                <span>وضعیت</span>
                            </div>
                            <span>{Array.isArray(data.condition) && data.condition.length ? data.condition[0].name : '-'}</span>
                        </li>
                        <li>
                            <div>
                                <i className="fi fi-br-play-alt"/>
                                <span>مدت زمان</span>
                            </div>
                            <span> {numberToPersian(data.course_time_str)}</span>
                        </li>
                        <li>
                            <div>
                                <i className="fi fi-rr-apps"/>
                                <span>تعداد</span>
                            </div>
                            <span> {numberToPersian(data.course_count)} قسمت</span>
                        </li>
                        <li>
                            <div>
                                <i className="fi fi-rr-calendar"/>
                                <span>انتشار</span>
                            </div>
                            <span>{data.date}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default Extra;
