import React, {useEffect, useState} from "react";
import {numberToPersian} from "../../functions/global";

const Counter = ({sec, stop = false, callback = null, classes = ''}) => {
    const [state, setState] = useState({time: {}, seconds: stop ? 0 : sec});

    useEffect(() => {
        let timeLeftVar = secondsToTime(state.seconds);
        setState({...state, time: timeLeftVar});
        countDown(stop ? 0 : sec);

    }, [])

    const secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        return {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
    }

    const countDown = (sec) => {
        let seconds = sec - 1;
        setState({
            time: secondsToTime(seconds),
            seconds: seconds,
        });

        if (seconds != 0) {
            setTimeout(() => countDown(seconds), 1000);
        } else {
            callback();
        }
    }

    return (
        <div className={`counter ${classes}`}>
            {numberToPersian(state.time.m)} : {numberToPersian(state.time.s)}
        </div>
    );
}

export default Counter;
