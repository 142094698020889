import React, {useCallback, useEffect, useState} from "react";
import ContentLoader from "react-content-loader";
import useEmblaCarousel from "embla-carousel-react";

const FreeLoader = () => {
    const [active, setActive] = useState([1, 2, 3, 4, 5, 6]);
    const [viewportRef, embla] = useEmblaCarousel({
        slidesToScroll: 1,
        inViewThreshold: 0.5,
        skipSnaps: false,
        containScroll: "trimSnaps",
        direction: 'rtl',
        speed: 10,
        align: 'start'
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);
    const onScroll = useCallback(() => {
        if (!embla) return;
        let ar = embla.slidesInView();
        if (ar.length) {
            setActive(ar)
        }
    }, [embla]);
    useEffect(() => {
        if (!embla) return;
        onScroll();
        embla.on("select", onSelect);
        embla.on("scroll", onScroll);
        onSelect();
    }, [embla, onSelect, onScroll]);
    return (
        <ul className='content-lists'>
            <div className="embla" ref={viewportRef}>
                <div className="content-lists embla__container">
                    <div className={`embla__slide ${active.indexOf(0) !== -1 ? '' : 'in-visible'}`}>
                        <div className="home-elements_items_block_component__item">
                            <div className="home-elements_style_item_card_component__root">
                                <div className="home-elements_style_item_card_component__preview">
                                    <a className="shared-item_preview_component__root">
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={125}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="125"/>
                                        </ContentLoader>
                                    </a>
                                </div>
                                <div className="home-elements_style_item_card_component__details">
                                    <div className="home-elements_style_item_card_component__content">
                                        <a className="home-elements_style_item_card_component__name" style={{textAlign: 'right'}}>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={10}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="50%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                            </ContentLoader>
                                        </a>
                                        <p>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={35}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                                <rect x="10%" y="20" rx="5" ry="5" width="90%" height="10"/>
                                            </ContentLoader>
                                        </p>
                                        <footer className="post__article-footer d-flex align-items-center">
                                            <div className="post__five-read d-flex align-items-center">
                                                <span className="post__five">
                                                    <ContentLoader
                                                        speed={2}
                                                        width='40%'
                                                        height={20}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#cfcfcf">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                    </ContentLoader>
                                                </span>
                                            </div>
                                            <div className="post__fav d-flex align-items-center">
                                                <div className='footer-box' style={{flexDirection: 'row-reverse', padding: '0'}}>
                                                    <div style={{alignItems: 'flex-end'}}>
                                                        <span>رایگان</span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`embla__slide ${active.indexOf(1) !== -1 ? '' : 'in-visible'}`}>
                        <div className="home-elements_items_block_component__item">
                            <div className="home-elements_style_item_card_component__root">
                                <div className="home-elements_style_item_card_component__preview">
                                    <a className="shared-item_preview_component__root">
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={125}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="125"/>
                                        </ContentLoader>
                                    </a>
                                </div>
                                <div className="home-elements_style_item_card_component__details">
                                    <div className="home-elements_style_item_card_component__content">
                                        <a className="home-elements_style_item_card_component__name" style={{textAlign: 'right'}}>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={10}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="50%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                            </ContentLoader>
                                        </a>
                                        <p>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={35}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                                <rect x="10%" y="20" rx="5" ry="5" width="90%" height="10"/>
                                            </ContentLoader>
                                        </p>
                                        <footer className="post__article-footer d-flex align-items-center">
                                            <div className="post__five-read d-flex align-items-center">
                                                <span className="post__five">
                                                    <ContentLoader
                                                        speed={2}
                                                        width='40%'
                                                        height={20}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#cfcfcf">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                    </ContentLoader>
                                                </span>
                                            </div>
                                            <div className="post__fav d-flex align-items-center">
                                                <div className='footer-box' style={{flexDirection: 'row-reverse', padding: '0'}}>
                                                    <div style={{alignItems: 'flex-end'}}>
                                                        <span>رایگان</span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`embla__slide ${active.indexOf(2) !== -1 ? '' : 'in-visible'}`}>
                        <div className="home-elements_items_block_component__item">
                            <div className="home-elements_style_item_card_component__root">
                                <div className="home-elements_style_item_card_component__preview">
                                    <a className="shared-item_preview_component__root">
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={125}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="125"/>
                                        </ContentLoader>
                                    </a>
                                </div>
                                <div className="home-elements_style_item_card_component__details">
                                    <div className="home-elements_style_item_card_component__content">
                                        <a className="home-elements_style_item_card_component__name" style={{textAlign: 'right'}}>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={10}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="50%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                            </ContentLoader>
                                        </a>
                                        <p>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={35}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                                <rect x="10%" y="20" rx="5" ry="5" width="90%" height="10"/>
                                            </ContentLoader>
                                        </p>
                                        <footer className="post__article-footer d-flex align-items-center">
                                            <div className="post__five-read d-flex align-items-center">
                                                <span className="post__five">
                                                    <ContentLoader
                                                        speed={2}
                                                        width='40%'
                                                        height={20}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#cfcfcf">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                    </ContentLoader>
                                                </span>
                                            </div>
                                            <div className="post__fav d-flex align-items-center">
                                                <div className='footer-box' style={{flexDirection: 'row-reverse', padding: '0'}}>
                                                    <div style={{alignItems: 'flex-end'}}>
                                                        <span>رایگان</span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`embla__slide ${active.indexOf(3) !== -1 ? '' : 'in-visible'}`}>
                        <div className="home-elements_items_block_component__item">
                            <div className="home-elements_style_item_card_component__root">
                                <div className="home-elements_style_item_card_component__preview">
                                    <a className="shared-item_preview_component__root">
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={125}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="125"/>
                                        </ContentLoader>
                                    </a>
                                </div>
                                <div className="home-elements_style_item_card_component__details">
                                    <div className="home-elements_style_item_card_component__content">
                                        <a className="home-elements_style_item_card_component__name" style={{textAlign: 'right'}}>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={10}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="50%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                            </ContentLoader>
                                        </a>
                                        <p>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={35}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                                <rect x="10%" y="20" rx="5" ry="5" width="90%" height="10"/>
                                            </ContentLoader>
                                        </p>
                                        <footer className="post__article-footer d-flex align-items-center">
                                            <div className="post__five-read d-flex align-items-center">
                                                <span className="post__five">
                                                    <ContentLoader
                                                        speed={2}
                                                        width='40%'
                                                        height={20}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#cfcfcf">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                    </ContentLoader>
                                                </span>
                                            </div>
                                            <div className="post__fav d-flex align-items-center">
                                                <div className='footer-box' style={{flexDirection: 'row-reverse', padding: '0'}}>
                                                    <div style={{alignItems: 'flex-end'}}>
                                                        <span>رایگان</span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={`embla__slide ${active.indexOf(4) !== -1 ? '' : 'in-visible'}`}>
                        <div className="home-elements_items_block_component__item">
                            <div className="home-elements_style_item_card_component__root">
                                <div className="home-elements_style_item_card_component__preview">
                                    <a className="shared-item_preview_component__root">
                                        <ContentLoader
                                            speed={2}
                                            width='100%'
                                            height={125}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="125"/>
                                        </ContentLoader>
                                    </a>
                                </div>
                                <div className="home-elements_style_item_card_component__details">
                                    <div className="home-elements_style_item_card_component__content">
                                        <a className="home-elements_style_item_card_component__name" style={{textAlign: 'right'}}>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={10}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="50%" y="0" rx="9" ry="9" width="50%" height="10"/>
                                            </ContentLoader>
                                        </a>
                                        <p>
                                            <ContentLoader
                                                speed={2}
                                                width='100%'
                                                height={35}
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#cfcfcf">
                                                <rect x="10%" y="0" rx="5" ry="5" width="90%" height="10"/>
                                                <rect x="10%" y="20" rx="5" ry="5" width="90%" height="10"/>
                                            </ContentLoader>
                                        </p>
                                        <footer className="post__article-footer d-flex align-items-center">
                                            <div className="post__five-read d-flex align-items-center">
                                                <span className="post__five">
                                                    <ContentLoader
                                                        speed={2}
                                                        width='40%'
                                                        height={20}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#cfcfcf">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                    </ContentLoader>
                                                </span>
                                            </div>
                                            <div className="post__fav d-flex align-items-center">
                                                <div className='footer-box' style={{flexDirection: 'row-reverse', padding: '0'}}>
                                                    <div style={{alignItems: 'flex-end'}}>
                                                        <span>رایگان</span>
                                                        <span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </ul>

    )
}

export default FreeLoader;
