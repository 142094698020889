import React from "react";
import ContentLoader from "react-content-loader";

const HeadLoader = () => {
    return (
        <div>
            <div>
                <div className='cover'>
                    <ContentLoader
                        speed={2}
                        width='100%'
                        height={170}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#cfcfcf">
                        <rect x="0" y="0" rx="25" ry="25" width="100%" height="170"/>
                    </ContentLoader>
                </div>
            </div>
            <div>
                <h2>
                    <ContentLoader
                        speed={2}
                        width='30%'
                        height={15}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#cfcfcf">
                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="15"/>
                    </ContentLoader>
                </h2>
                <div className='author'>
                    <div>
                        <ContentLoader
                            speed={2}
                            width='40'
                            height={40}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="0" y="0" rx="40" ry="40" width="100%" height="40"/>
                        </ContentLoader>
                        <div style={{marginRight: '10px', marginTop: '5px'}}>
                            <a>
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={15}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="15"/>
                                </ContentLoader>
                            </a>
                            <span>
                                <ContentLoader
                                    speed={2}
                                    width='40%'
                                    height={15}
                                    backgroundColor="#f3f3f3"
                                    foregroundColor="#cfcfcf">
                                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="15"/>
                                </ContentLoader>
                            </span>
                        </div>
                    </div>

                </div>
                <div className='bt'>
                    <div className='cat' style={{marginBottom: '12px'}}>
                        <span></span>
                        <a>
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height={25}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="5" ry="5" width="100%" height="25"/>
                            </ContentLoader>
                        </a>
                    </div>

                    <div className='star-article'>
                        <span className='star-wrapper'>
                            <i className={`fas fa-star`}/>
                            <i className={`fas fa-star`}/>
                            <i className={`fas fa-star`}/>
                            <i className={`fas fa-star`}/>
                            <i className={`fas fa-star`}/>
                        </span>
                        <strong className='article-star'>/
                            <span>5</span>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeadLoader;
