import React, {useContext, useEffect, useState} from "react";
import Login from "./login";
import Register from "./register";
import {Helmet} from "react-helmet";
import Loading from "../misc/loading";
import {globalContext} from "../../App";
import {request} from "../../functions/global";
import Tabs from "./tabs";
import {Link, useNavigate} from "react-router-dom";

const MainLogin = () => {
    const {global} = useContext(globalContext);
    const navigate = useNavigate();

    const [tab, setTab] = useState('login');
    const [errors, setErrors] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (global.jwt) {
            navigate('/');
        }
        setShowLoading(true);
        request('user/options/', 'POST', {}).then(response => {
            setShowLoading(false);
            setOptions(response);
        })
    }, []);


    return (
        <>
            <Helmet>
                <title>آموزش تیوب - بزرگترین مرجع ویدیوهای آموزشی</title>
            </Helmet>
            <Loading show={showLoading}/>
            <div className='login-wrapper'>
                <Link to={'/'}>
                    <img className="logo" src={`/images/logo-${global.theme}.png`}/>
                </Link>
                <div className='login'>
                    <Tabs tab={tab} setErrors={setErrors} setTab={setTab}/>
                    <Login tab={tab} errors={errors} setErrors={setErrors} setShowLoading={setShowLoading} options={options}/>
                    <Register tab={tab} errors={errors} setErrors={setErrors} setShowLoading={setShowLoading} options={options}/>
                </div>
            </div>
        </>
    )
}

export default MainLogin;
