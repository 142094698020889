import React, {useState} from "react";
import VideoJS from "../misc/videojs";

const VideoArticle = ({data}) => {
    const playerRef = React.useRef(null);
    const [videoJsOptions, setVideoJsOptions] = useState({
        html5: {
            hls: {
                enableLowInitialPlaylist: true,
                smoothQualityChange: true,
                overrideNative: true,
            },
        },
        autoplay: false,
        controls: true,
        responsive: false,
        fluid: true,
        liveui: true,
        playbackRates: [0.5, 1, 1.5, 2],
        poster: data.thumbnail,
        token: data.token,
        sources: [{
            src: data.video,
            type: "application/x-mpegURL",
            withCredentials: true
        }]
    });
    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('waiting', () => {
        });
        player.on('dispose', () => {
        });
    };

    return (
        <>
            <div>
                <VideoJS options={videoJsOptions} onReady={handlePlayerReady}/>
            </div>
        </>
    )
}
export default VideoArticle
