import React from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ContentLoader from "react-content-loader";

const TicketLoader = () => {
    return (
        <div className="content-wrapper ticket-wrapper">
            <div className='ticket-head'>
                <div>
                    <div>
                        <ContentLoader
                            speed={2}
                            width='90'
                            height={90}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="0" y="0" rx="100" ry="100" width="100%" height="90"/>
                        </ContentLoader>
                        <ul>
                            <li>
                                <span>
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={15}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="15"/>
                                    </ContentLoader>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <ContentLoader
                                        speed={2}
                                        width='100%'
                                        height={15}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="40%" height="15"/>
                                        <rect x="55%" y="0" rx="5" ry="5" width="45%" height="15"/>
                                    </ContentLoader>
                                </span>
                            </li>
                            <li>
                                <span/>
                                <span>
                                    <ContentLoader
                                        speed={2}
                                        width='50%'
                                        height={15}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="10%" y="0" rx="5" ry="5" width="90%" height="15"/>
                                    </ContentLoader>
                                </span>
                            </li>

                        </ul>
                    </div>
                    <div>
                        <a className='return-ticket' style={{textAlign: 'left', border: 'none'}}>
                            <ContentLoader
                                speed={2}
                                width='40%'
                                height={40}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="5" ry="5" width="90%" height="40"/>
                            </ContentLoader>
                        </a>
                    </div>
                </div>
            </div>
            <form className='ticket-form-reply'>
                <h3>پاسخ تیکت</h3>
                <CKEditor
                    config={{
                        language: 'fa',
                        placeholder: "متن تیکت خود را تایپ کنید"
                    }}
                    editor={ClassicEditor}
                    onReady={editor => {
                    }}
                    onChange={(event, editor) => {
                    }}
                />
                <a className='comment__write-btn js-comment-post-btn js-save'>ارسال</a>
                <a className='return-ticket mob' style={{float: 'left', marginTop: '13px', marginLeft: '12px'}}>
                    بستن تیکت
                </a>
                <br/>
                <br/>
            </form>
        </div>
    )
}

export default TicketLoader;
