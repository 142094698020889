import React from "react";
import ContentLoader from "react-content-loader";

const TableCustomLoader = ({heads, showSearch = true, showHeader = true}) => {
    return (
        <div className='table-wrapper-custom'>
            {showSearch ?
                <input type='text' placeholder='جستجو ...'/>
                :
                null
            }
            <div className='table-responsive'>
                <table>
                    {showHeader ?
                        <thead>
                        <tr className='head'>
                            {heads.map((head, key) => {
                                return (
                                    <th key={key}>{head}</th>
                                )
                            })}
                        </tr>
                        </thead>
                        :
                        null
                    }
                    <tbody>
                    <tr style={{backgroundColor: 'transparent'}}>
                        {heads.map((head, key) => {
                            return (
                                <th key={key}>
                                    <ContentLoader
                                        speed={2}
                                        width='100%'
                                        height={30}
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#cfcfcf">
                                        <rect x="0" y="0" rx="5" ry="5" width="100%" height="30"/>
                                    </ContentLoader>
                                </th>
                            )
                        })}
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default TableCustomLoader;
