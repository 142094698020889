import React from "react";

const Modal = ({show, children, setShow, classes = ''}) => {
    return (
        <div className={`modal-backdrop ${classes}`} style={show ? {display: 'block'} : null}>
            <div className='back-close-handle' onClick={() => setShow(false)} />
            <div className="popup-unlock">
                <div className="unlock__header">
                    <a className="img-btn medium close-btn js-close-btn">
                        <i onClick={() => setShow(false)} className="fas fa-times-circle"/>
                    </a>
                </div>
                <div className="unlock__body">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default Modal;
