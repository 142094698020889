import React from "react";
import ContentLoader from "react-content-loader";

const VideoLoader = () => {
    return (
        <>
            <div className='course-video'>
                <div>
                    <div className='video-header'>
                        <div>
                            <ContentLoader
                                speed={2}
                                width='120'
                                height={36}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="4" ry="4" width="100%" height="36"/>
                            </ContentLoader>
                        </div>
                        <div>
                            <ContentLoader
                                speed={2}
                                width='120'
                                height={36}
                                backgroundColor="#f3f3f3"
                                foregroundColor="#cfcfcf">
                                <rect x="0" y="0" rx="4" ry="4" width="100%" height="36"/>
                            </ContentLoader>
                        </div>
                    </div>
                    <div>
                        <ContentLoader
                            speed={2}
                            width='100%'
                            height={500}
                            backgroundColor="#f3f3f3"
                            foregroundColor="#cfcfcf">
                            <rect x="0" y="0" rx="5" ry="5" width="100%" height="500"/>
                        </ContentLoader>
                    </div>
                </div>
                <div className='single'>
                    <div className="sidebar c-l">
                        <ul className='course-lists'>
                            <li>
                                <div>
                                    <span>
                                        <ContentLoader
                                            speed={2}
                                            width='50%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                    <i className="fal fa-angle-down"/>
                                </div>
                                <ul>
                                    <li>
                                        <a>
                                            <div><i style={{verticalAlign: 'unset'}} className="fi fi-rr-play-alt"/></div>
                                            <div>
                                                <ContentLoader
                                                    speed={2}
                                                    width='70%'
                                                    height={20}
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#cfcfcf">
                                                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                </ContentLoader>
                                                <div style={{marginRight: 0}}>
                                                    <ContentLoader
                                                        speed={2}
                                                        width='70%'
                                                        height={20}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#cfcfcf">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                    </ContentLoader>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                            <div><i style={{verticalAlign: 'unset'}} className="fi fi-rr-play-alt"/></div>
                                            <div>
                                                <ContentLoader
                                                    speed={2}
                                                    width='70%'
                                                    height={20}
                                                    backgroundColor="#f3f3f3"
                                                    foregroundColor="#cfcfcf">
                                                    <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                </ContentLoader>
                                                <div style={{marginRight: 0}}>
                                                    <ContentLoader
                                                        speed={2}
                                                        width='70%'
                                                        height={20}
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#cfcfcf">
                                                        <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                                    </ContentLoader>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div>
                                    <span>
                                        <ContentLoader
                                            speed={2}
                                            width='50%'
                                            height={20}
                                            backgroundColor="#f3f3f3"
                                            foregroundColor="#cfcfcf">
                                            <rect x="0" y="0" rx="4" ry="4" width="100%" height="20"/>
                                        </ContentLoader>
                                    </span>
                                    <i className="fal fa-angle-down"/>

                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default VideoLoader
