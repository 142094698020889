import React, {useContext, useState} from "react";
import {alert, request} from "../../functions/global";
import {globalContext} from "../../App";
import Pagination from "../misc/pagination";

const Comment = ({data}) => {
    const {global} = useContext(globalContext);
    let p = {};
    const [page, setPage] = useState(1);
    const [reply, setReply] = useState(0);
    const [commentData, setCommentData] = useState('');
    const [commentLoading, setCommentLoading] = useState(false);
    const [comments, setComments] = useState(data.comments);

    const commentParent = (id, parent) => {
        if (parent != 0 && parent in p) {
            p[id] = p[parent] + 1;
        } else {
            p[id] = 0;
        }
        return p[id];
    };

    const loadComment = (page) => {
        setCommentLoading(true);
        request('post/comments', 'POST', {ID: data.ID, page}).then(response => {
                if (response.length) {
                    setComments([...response]);
                    setPage(page);
                }
                setCommentLoading(false);
            },
        );
    };
    const send = (e) => {
        e.preventDefault();
        if (global.jwt) {
            setCommentLoading(true);
            request('post/comments/send/', 'POST', {content: commentData, ID: data.ID}, global.jwt).then(response => {
                setCommentLoading(false);
                setCommentData('');
                if (response) {
                    setComments(response);
                }
            });
        } else {
            alert('برای ارسال نظر ابتدا وارد حساب کاربری خود شوید');
        }
    };
    const reply_comment = (e) => {
        e.preventDefault();
        if (global.jwt) {
            setCommentLoading(true);
            request('post/comments/reply/', 'POST', {content: commentData, ID: data.ID, parent: reply}, global.jwt).then(response => {
                setCommentData('');
                setCommentLoading(false);
                setReply(0);
                if (response) {
                    setComments(response);
                }
            });
        } else {
            alert('برای ارسال نظر ابتدا وارد حساب کاربری خود شوید');
        }
    };
    return (
        <div className="comment-course">
            <div className="comments-wrap">
                <section className="comment-section more">
                    <div className="comment-form">
                        <p style={{opacity: '.7', fontFamily: 'IRANYEKANX', fontSize: '17px', textAlign: 'center', color: '#000000', fontWeight: '900'}}>نظرت در مورد مقاله ؟</p>
                        {reply === 0 ?
                            <form>
                                <div>
                                    <img alt='avatar' src={global.jwt ? global.jwtDecode.data.avatar : '/images/defaultuser.png'} className="avatar avatar-50 photo circle" height="50" width="50"/>
                                    <textarea onChange={event => setCommentData(event.target.value)} value={commentData} id="comment" placeholder="متن دیدگاه" name="comment"
                                              className="autogrow comment-body"/>
                                    <button onClick={event => send(event)} type="submit" className="comment__write-btn js-comment-post-btn js-save">
                                        ارسال نظر
                                    </button>
                                </div>

                            </form>
                            :
                            null
                        }
                    </div>
                    {comments.length ?
                        <div className="comment-streams" style={{width: '90%'}}>
                            {comments.map((item, key) => {
                                let margin = commentParent(item.ID, item.comment_parent);
                                return (
                                    <div key={key} className={`comment-stream stream mm${margin}`}>
                                        <div className="body-wrap">
                                            <div className="parent-body-wrap">
                                                <header className="body-header">
                                                    <div className="avatar-wrap">
                                                        <a className="avatar has-link">
                                                            <img alt='avatar' src={item.avatar} className="avatar avatar-200 photo circle" height="50" width="50"/>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a href="" className="commenter has-link">{item.username}</a>
                                                        <div>
                                                            <span className="date">{item.comment_date}</span>
                                                            {
                                                                reply !== item.ID ?
                                                                    <a onClick={() => setReply(item.ID)} rel="nofollow" className="comment-reply-link">
                                                                        <span className="reply">ارسال پاسخ</span>
                                                                    </a>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </header>
                                                <div className="comment-txt" id="comment-135787">
                                                    <p>{item.comment_content}</p>
                                                </div>
                                            </div>
                                            {
                                                reply === item.ID ?
                                                    (
                                                        <>
                                                            <div className='comment-form'>
                                                                <form style={{width: '100%'}}>
                                                                    <div>
                                                                        <img alt='avatar' src={global.jwt ? global.jwtDecode.data.avatar : '/images/defaultuser.png'} className="avatar avatar-50 photo circle" height="50" width="50"/>
                                                                        <textarea onChange={event => setCommentData(event.target.value)} value={commentData} id="comment" placeholder="متن پاسخ" name="comment"
                                                                                  className="autogrow comment-body"/>
                                                                        <button onClick={event => reply_comment(event)} type="submit" className="comment__write-btn js-comment-post-btn js-save">
                                                                            ارسال پاسخ
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </>
                                                    )
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        :
                        null
                    }
                    <Pagination count={data.comments.length ? data.comments[0].page : 1} callback={loadComment} page={page} setPage={setPage}/>
                </section>
            </div>

        </div>
    )
}
export default Comment;
